import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getTranslationFromStream } from 'app/utils/operator/get-translation-from-stream';
import { combineLatest, Observable, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../../app.state';
import { AuthenticationManagerService } from '../../../../main/authentication/authentication-manager.service';
import { SetupGuideService } from '../../../../main/setup-guide/service/setup-guide.service';
import { RetailerNavigation } from '../../../../navigation/navigation-routes/retailer/retailer.navigation';
import { SupplierNavigation } from '../../../../navigation/navigation-routes/supplier/supplier.navigation';
import { AuthorizationEcomService } from '../../../../service/authorization-ecom/authorization-ecom.service';
import { isAuthenticatedSelector } from '../../../../store/authentication/authentication.selector';
import { hasAnyPlanSelector } from '../../../../store/subscription/subscription.selector';
import { getUserRolesSelector, isEmailVerified } from '../../../../store/user/user.selector';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';
import { RolesEnum } from '../../../../vo/roles/roles';
import { ToolbarCallToActionContentService } from '../components/toolbar-call-to-action/toolbar-call-to-action-content.service';
import { ToolbarCallToActionService } from '../components/toolbar-call-to-action/toolbar-call-to-action.service';
import { objectInterpolation } from '../../../../utils/operator/object-interpolation';
import { FREE_TRIAL_DAYS } from '../../../../main/billing-new/billing.constants';
import { MarketplaceEcomService } from '../../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { selectedRetailerEcomSelector } from '../../../../store/ecom/ecom.selector';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class ToolbarService implements OnDestroy {
  private unsubscribeAll = new Subject<void>();
  private role: Observable<RolesEnum>;
  constructor(
    private toolbarCallToActionService: ToolbarCallToActionService,
    private translateService: TranslateService,
    private store: Store<AppState>,
    private authenticationManagerService: AuthenticationManagerService,
    private authorizationEcomService: AuthorizationEcomService,
    private setupGuideService: SetupGuideService,
    private toolbarCallToActionContentService: ToolbarCallToActionContentService,
    private marketplaceEcomService: MarketplaceEcomService
  ) {
    this.role = this.store
      .select(getUserRolesSelector)
      .pipe(map((roles) => (roles && roles.includes(RolesEnum.SUPPLIER) ? RolesEnum.SUPPLIER : RolesEnum.RETAILER)));
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  initCallToAction(): void {
    this.toolbarCallToActionService.clearContent();
    combineLatest([
      this.store.select(isAuthenticatedSelector).pipe(omitNullOrUndefined(), distinctUntilChanged()),
      this.store.select(hasAnyPlanSelector).pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(([isAuthenticated, hasAnyPlan]) => {
        if (isAuthenticated && hasAnyPlan === false) {
          this.handleAuthenticatedWithoutPlan();
        }
        if (isAuthenticated && hasAnyPlan) {
          this.handleAuthenticatedWithPlan();
        } else if (!isAuthenticated) {
          this.handleNonAuthenticated();
        }
      });
    this.handleEarnCreditCallToAction();
    this.handleSetupGuideCta();
    this.handleVerifyEmailCallToAction();
  }

  private handleAuthenticatedWithPlan(): void {
    this.toolbarCallToActionService.removeContent('newHereLogin');
    this.toolbarCallToActionService.removeContent('newHere');
  }

  private handleAuthenticatedWithoutPlan(): void {
    this.store
      .select(selectedRetailerEcomSelector)
      .pipe(
        takeUntil(this.unsubscribeAll),
        map((ecom) => this.marketplaceEcomService.isFreeTrialAvailable(ecom)),
        switchMap((isFreeTrialAvailable) =>
          isFreeTrialAvailable
            ? this.translateService
                .stream('BANNERS.NEW_HERE.WITH_TRIAL')
                .pipe(getTranslationFromStream('BANNERS.NEW_HERE.WITH_TRIAL'))
            : this.translateService
                .stream('BANNERS.NEW_HERE.WITHOUT_TRIAL')
                .pipe(getTranslationFromStream('BANNERS.NEW_HERE.WITHOUT_TRIAL'))
        ),
        objectInterpolation({ trialDays: FREE_TRIAL_DAYS })
      )
      .subscribe({
        next: (translations) => {
          this.toolbarCallToActionService.addActionContent(
            {
              type: 'newHere',
              title: translations?.['TITLE_1'],
              priority: 1,
              theme: 'info',
              action: {
                type: 'callback',
                callback: () => this.authorizationEcomService.openSubscribeDialog(),
              },
              position: 'top',
            },
            {
              type: 'newHere',
              title: translations?.['TITLE_2'],
              priority: 1,
              theme: 'warning',
              action: {
                type: 'callback',
                callback: () => this.authorizationEcomService.openSubscribeDialog(),
              },
              position: 'below-header',
            }
          );
        },
      });
  }

  private handleNonAuthenticated(): void {
    const key = 'BANNERS.NEW_HERE.GUEST';
    this.translateService
      .stream(key)
      .pipe(getTranslationFromStream(key), objectInterpolation({ trialDays: FREE_TRIAL_DAYS }))
      .subscribe({
        next: (translations) => {
          this.toolbarCallToActionService.setActionContent([
            {
              type: 'newHereLogin',
              title: translations?.['TITLE_1'],
              priority: 1,
              theme: 'info',
              action: {
                type: 'callback',
                callback: () => this.authenticationManagerService.emitOpenRegistrationDialog(),
              },
              position: 'top',
            },
          ]);
        },
      });
  }

  private get isEmailVerified(): Observable<boolean> {
    return this.store.select(isEmailVerified);
  }

  private setVerifyEmailCallToAction(title: string, role: RolesEnum): void {
    this.toolbarCallToActionService.addActionContent({
      type: 'verifyEmail',
      theme: 'warning',
      title: title,
      priority: 0,
      action: {
        type: 'inner-navigation',
        url: role === RolesEnum.SUPPLIER ? SupplierNavigation.PROFILE.SETTINGS : RetailerNavigation.SETTINGS.SETTINGS,
      },
      position: 'top',
    });
  }

  private handleEarnCreditCallToAction(): void {
    this.setupGuideService
      .observeIfNeedsEarnMoreCallToAction()
      .pipe(takeUntil(this.unsubscribeAll), distinctUntilChanged())
      .subscribe((needsCallToAction) => {
        if (!!needsCallToAction) {
          this.toolbarCallToActionContentService.addEarnCreditCallToAction();
        } else {
          this.toolbarCallToActionService.removeContent('earnCredit');
        }
      });
  }

  private handleSetupGuideCta(): void {
    this.setupGuideService
      .observeIfNeedsSetupGuideCta()
      .pipe(takeUntil(this.unsubscribeAll), distinctUntilChanged())
      .subscribe((needsCallToAction) => {
        if (!!needsCallToAction) {
          this.toolbarCallToActionContentService.addSetupGuideCallToAction();
        } else {
          this.toolbarCallToActionService.removeContent('setupGuide');
        }
      });
  }

  public handleVerifyEmailCallToAction(): void {
    combineLatest([
      this.isEmailVerified,
      this.translateService.get('EMAIL_VERIFICATION.NOT_VERIFIED_BAR') as Observable<string>,
      this.role,
    ])
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(([isVerified, title, role]) => {
        if (isVerified === false) {
          this.setVerifyEmailCallToAction(title, role);
        } else {
          this.toolbarCallToActionService.removeContent('verifyEmail');
        }
      });
  }
}
