import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function objectInterpolation<T>(interpolateObject: {
  [key: string]: unknown;
}): (source: Observable<T>) => Observable<T> {
  const objectInterpolate = (obj: any, params: { [k: string]: any }) => {
    return JSON.parse(JSON.stringify(obj).replace(/{{\s*([^}\s]+)\s*}}/gm, (_, group) => params[group] ?? ''));
  };

  return (source: Observable<T>): Observable<T> =>
    source.pipe(map((value) => objectInterpolate(value, interpolateObject)));
}
