import { ProductSearchResponse as ProtobufProductSearchResponse } from '../../../pb/responses/v1/product_search_response_pb';
import { ProductSearchResponse } from 'app/service/product-search/product-search.service';
import { ProductMapper } from './product.mapper';
import { CommonMapper } from './common.mapper';

export class ProductSearchResponseMapper {
  public static mapIntoProductSearchResponse(response: ProtobufProductSearchResponse): ProductSearchResponse {
    return {
      took: CommonMapper.mapBigintIntoNumber(response.took),
      total: CommonMapper.mapBigintIntoNumber(response.total),
      result: response.result.map(ProductMapper.mapIntoSearchProductVO),
    };
  }
}
