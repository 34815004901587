import { Component, Input, OnChanges } from '@angular/core';
import { PlanVO } from '../subscription.component';
import { isEmpty } from 'lodash';
import { Utils } from 'app/utils/utils';
import { environment } from '../../../../../environments/environment';
import { MARKETPLACE_PLAN } from '../../../../utils/plans';

@Component({
  selector: 'app-subscription-plan-box',
  templateUrl: './subscription-plan-box.component.html',
  styleUrls: ['./subscription-plan-box.component.scss'],
})
export class SubscriptionPlanBoxComponent implements OnChanges {
  @Input() plan: PlanVO;
  @Input() freeTrialIsAvailable: boolean;
  @Input() selectedCycle: number;
  dataToShow: SubscriptionPlanBoxData;
  description: string;
  list: SubscriptionPageBoxList[];
  isLoading = true;

  private MARKETING_TEXTS_FOR_PLANS: SubscriptionPlanBoxData[] = [
    {
      id: MARKETPLACE_PLAN.STARTER,
      type: 'rmp',
      title: 'Free',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_AVAILABLE.DISADVANTAGES.8',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_AVAILABLE.DISADVANTAGES.1',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_AVAILABLE.DISADVANTAGES.2',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_AVAILABLE.DISADVANTAGES.3',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_NOT_AVAILABLE.DISADVANTAGES.1',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_NOT_AVAILABLE.DISADVANTAGES.2',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.STARTER.FREE_TRIAL_NOT_AVAILABLE.DISADVANTAGES.3',
            },
          ],
        },
        annual: null,
      },
    },
    {
      id: MARKETPLACE_PLAN.BASIC,
      type: 'rmp',
      title: 'Basic',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.6',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.ANNUAL.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BASIC.ANNUAL.ADVANTAGES.4',
            },
          ],
        },
      },
    },
    {
      id: MARKETPLACE_PLAN.PRO,
      type: 'rmp',
      title: 'Pro',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.6',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.ANNUAL.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PRO.ANNUAL.ADVANTAGES.4',
            },
          ],
        },
      },
    },
    {
      id: MARKETPLACE_PLAN.BUSINESS,
      type: 'rmp',
      title: 'Business',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.6',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.ANNUAL.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.BUSINESS.ANNUAL.ADVANTAGES.4',
            },
          ],
        },
      },
    },
    {
      id: MARKETPLACE_PLAN.PLUS,
      type: 'rmp',
      title: 'Plus',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.6',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.ANNUAL.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.RMP.PLUS.ANNUAL.ADVANTAGES.4',
            },
          ],
        },
      },
    },
    {
      id: 100,
      type: 'df',
      title: 'Free',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_AVAILABLE.DISADVANTAGES.1',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_AVAILABLE.DISADVANTAGES.2',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_AVAILABLE.DISADVANTAGES.3',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_AVAILABLE.DISADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_NOT_AVAILABLE.DISADVANTAGES.1',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_NOT_AVAILABLE.DISADVANTAGES.2',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_NOT_AVAILABLE.DISADVANTAGES.3',
            },
            {
              type: 'disadvantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.STARTER.FREE_TRIAL_NOT_AVAILABLE.DISADVANTAGES.4',
            },
          ],
        },
        annual: null,
      },
    },
    {
      id: 101,
      type: 'df',
      title: 'Nano',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.NANO.ANNUAL.ADVANTAGES.3',
            },
          ],
        },
      },
    },
    {
      id: 102,
      type: 'df',
      title: 'Micro',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MICRO.ANNUAL.ADVANTAGES.3',
            },
          ],
        },
      },
    },
    {
      id: 103,
      type: 'df',
      title: 'Mini',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.MINI.ANNUAL.ADVANTAGES.3',
            },
          ],
        },
      },
    },
    {
      id: 104,
      type: 'df',
      title: 'Basic',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BASIC.ANNUAL.ADVANTAGES.3',
            },
          ],
        },
      },
    },
    {
      id: 105,
      type: 'df',
      title: 'Business',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.BUSINESS.ANNUAL.ADVANTAGES.3',
            },
          ],
        },
      },
    },
    {
      id: 106,
      type: 'df',
      title: 'Plus',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PLUS.ANNUAL.ADVANTAGES.3',
            },
          ],
        },
      },
    },
    {
      id: 107,
      type: 'df',
      title: 'Professional',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.PROFESSIONAL.ANNUAL.ADVANTAGES.3',
            },
          ],
        },
      },
    },
    {
      id: 108,
      type: 'df',
      title: 'Enterprise',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE.ANNUAL.ADVANTAGES.3',
            },
          ],
        },
      },
    },
    {
      id: 109,
      type: 'df',
      title: 'Enterprise Plus',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_PLUS.ANNUAL.ADVANTAGES.3',
            },
          ],
        },
      },
    },
    {
      id: 110,
      type: 'df',
      title: 'Enterprise Extra',
      subscriptionPageBox: {
        freeTrialAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_AVAILABLE.ADVANTAGES.4',
            },
          ],
        },
        freeTrialNotAvailable: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_NOT_AVAILABLE.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.3',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.4',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.FREE_TRIAL_NOT_AVAILABLE.ADVANTAGES.5',
            },
          ],
        },
        annual: {
          description: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.ANNUAL.DESCRIPTION',
          list: [
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.ANNUAL.ADVANTAGES.1',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.ANNUAL.ADVANTAGES.2',
            },
            {
              type: 'advantage',
              text: 'SUBSCRIPTION_PAGE_PLAN_BOXES.DF.ENTERPRISE_EXTRA.ANNUAL.ADVANTAGES.3',
            },
          ],
        },
      },
    },
  ];

  ngOnChanges(): void {
    if (
      Utils.isNullOrUndefined(this.plan) ||
      isEmpty(this.plan) ||
      Utils.isNullOrUndefined(this.freeTrialIsAvailable)
    ) {
      return;
    }
    this.dataToShow = this.MARKETING_TEXTS_FOR_PLANS.find((plan) => plan.id === this.plan.productId);
    let boxKey = '';
    if (this.selectedCycle === 1) {
      boxKey = 'annual';
    } else {
      boxKey = environment.hasFreeTrial && this.freeTrialIsAvailable ? 'freeTrialAvailable' : 'freeTrialNotAvailable';
    }
    this.list =
      this.dataToShow && this.dataToShow.subscriptionPageBox[boxKey]
        ? this.dataToShow.subscriptionPageBox[boxKey].list
        : null;
    this.description =
      this.dataToShow && this.dataToShow.subscriptionPageBox[boxKey]
        ? this.dataToShow.subscriptionPageBox[boxKey].description
        : null;
    this.isLoading = false;
  }
}

export interface SubscriptionPlanBoxData {
  id: number;
  type: 'rmp' | 'df';
  title: string;
  subscriptionPageBox: SubscriptionPageBox;
}

interface SubscriptionPageBox {
  freeTrialAvailable: SubscriptionPageBoxData;
  freeTrialNotAvailable: SubscriptionPageBoxData;
  annual: SubscriptionPageBoxData;
}

interface SubscriptionPageBoxData {
  description: string;
  list: SubscriptionPageBoxList[];
}

interface SubscriptionPageBoxList {
  type: 'advantage' | 'disadvantage';
  text: string;
}
