import { EventEmitter, Inject, Injectable } from '@angular/core';
import { RestService } from '../../rest/rest.service';
import { ActivatedRoute } from '@angular/router';
import { OnboardService } from '../../../layout/components/onboard/onboard.service';
import { EcomService, EcomVO } from '../../ecom/ecom.service';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, combineLatest, interval, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { retailerEcomsSelector, selectedRetailerEcomSelector } from 'app/store/ecom/ecom.selector';
import { subscriptionsSelector } from 'app/store/subscription/subscription.selector';
import { CurrencyService } from '../../currency-service/currency.service';
import { UserEcomChainToSubscriptionChanged } from '../../../store/ecom/ecom.actions';
import { getPlatformByType } from '../../../utils/platforms/platform-config';
import { EcomTypeEnum } from '../../../vo/enums/ecom-type-enum';
import { Utils } from 'app/utils/utils';
import { DATAFEED_STARTER_PLAN_ID, MARKETPLACE_STARTER_PLAN_ID } from '../../../utils/Constants';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceEcomService extends EcomService {
  private static PERIOD_PLAN_FETCH_TIME = 60000 * 10;

  public isSettedEcomPLan = false;
  public isInitialized = false;
  public _selectedEcom: EcomVO;
  public _ecomList: EcomVO[] = [];

  private _unsubscribeEcomList: Subject<void>;
  private _unsubscribeEcomGet: Subject<void>;
  private _periodPlanFetchSub = null;

  onDomainChange: EventEmitter<EcomVO> = new EventEmitter<EcomVO>();
  onPlansLoaded: EventEmitter<EcomVO> = new EventEmitter<EcomVO>();
  initialized: EventEmitter<boolean> = new EventEmitter<boolean>(null);
  ecomListUpdated: EventEmitter<EcomVO[]> = new EventEmitter<EcomVO[]>();
  reloadUsage: EventEmitter<any> = new EventEmitter<any>();
  _planReceived: BehaviorSubject<EcomVO> = new BehaviorSubject<EcomVO>(null);

  get onPlanReceived(): Observable<EcomVO> {
    return this._planReceived.asObservable();
  }

  get selectedEcom(): EcomVO {
    return this._selectedEcom;
  }

  set selectedEcom(ecom: EcomVO) {
    this._selectedEcom = ecom;
  }

  constructor(
    protected restService: RestService,
    private route: ActivatedRoute,
    private onboardService: OnboardService,
    private store: Store<AppState>,
    @Inject(DOCUMENT) protected document: any
  ) {
    super(restService, document);
    this._unsubscribeEcomList = new Subject();
    this._unsubscribeEcomGet = new Subject();

    this.initSelectedEcom();
  }

  private initSelectedEcom(): void {
    this.store
      .select(selectedRetailerEcomSelector)
      // .pipe(takeUntil(this._unsubscribeEcomList))
      .subscribe((selectedEcom) => {
        this._selectedEcom = selectedEcom;
        if (selectedEcom) {
          this.initDomainList();
          this.isInitialized = true;
        }
      });
  }

  public getEcomWithSubscriptionFromStore(): Observable<EcomVO> {
    return combineLatest([
      this.store.select(selectedRetailerEcomSelector),
      this.store.select(subscriptionsSelector),
    ]).pipe(
      filter(([ecom, subscription]) => !ecom || ecom?.id === subscription?.ecomId),
      map(([ecomVO, subscriptionVO]) => {
        if (!ecomVO) {
          return null;
        }
        ecomVO = { ...subscriptionVO, ...ecomVO };
        return ecomVO;
      })
    );
  }

  public adminStopEcomGet(): void {
    this._unsubscribeEcomList.next();
    this._unsubscribeEcomList.complete();
    this._unsubscribeEcomList = new Subject();
    this.remove();
  }

  get ecomList(): any[] {
    return this._ecomList;
  }

  set ecomList(ecomList: EcomVO[]) {}

  setEcomList(value): void {
    this._ecomList = value;
    this.ecomListUpdated.emit(this._ecomList);
  }

  public initDomainList(): void {
    this.isInitialized = false;
    this.store
      .select(retailerEcomsSelector)
      .pipe(takeUntil(this._unsubscribeEcomList))
      .subscribe((ecomList) => {
        this.setEcomList(ecomList);
        if (!Utils.isNullOrUndefined(this.ecomList) && this.ecomList.length > 0) {
          // this.selectedEcom.ecomId = this.selectedEcom.id;
          this.onboardService.removeOnboardPanel = false;
        } else {
          this.selectedEcom = null;
          this.onboardService.removeOnboardPanel = true;
        }
        this.isInitialized = true;
        this.initialized.next(true);
      });
  }

  public setPlansForSelectedEcom(): void {
    this.isSettedEcomPLan = true;
    if (this.selectedEcom) {
      this.store
        .select(subscriptionsSelector)
        .pipe(takeUntil(this._unsubscribeEcomGet))
        .subscribe(
          (subscription) => {
            if (subscription) {
              Object.assign(this._selectedEcom, subscription);
              const index = this._ecomList.findIndex((elem) => elem.id == this.selectedEcom.id);
              this._planReceived.next(this._selectedEcom);
              if (index > -1) {
                Object.assign(this.ecomList[index], subscription);
              }
              this.onPlansLoaded.emit(this._selectedEcom);
            }
          },
          () => {
            this.onPlansLoaded.emit(null);
          }
        );
    }
  }

  public getCurrency(ecomId: number = null): string {
    if (ecomId) {
      const ecom = this.ecomList.find((item) => item.id === ecomId);
      return CurrencyService.getCurrency(ecom);
    } else {
      return CurrencyService.getCurrency();
    }
  }

  public handleEcomChange(ecomId): void {
    this.store.dispatch(new UserEcomChainToSubscriptionChanged({ ecomId }));
  }

  public periodicalPlanFetch(): void {
    if (!Utils.isNullOrUndefined(this._periodPlanFetchSub)) {
      return;
    }

    this._periodPlanFetchSub = interval(MarketplaceEcomService.PERIOD_PLAN_FETCH_TIME).subscribe((t) => {
      this.setPlansForSelectedEcom();
    });
  }

  public remove(): void {
    this.setEcomList([]);
    this.selectedEcom = null;
    localStorage.removeItem('selectedEcomId');
  }

  public checkForStarterPlan(): boolean {
    return this.selectedEcom.subscriptions.rmp.planId === 120 && !this.selectedEcom.subscriptions.rmp.hasCustomPlan;
  }

  public isDataFeedStarterPlan(): boolean {
    return this.selectedEcom.subscriptions.df.planId === 100 && !this.selectedEcom.subscriptions.df.hasCustomPlan;
  }

  public hasSubscription(): boolean {
    return (
      !Utils.isNullOrUndefined(this.selectedEcom) &&
      !Utils.isNullOrUndefined(this.selectedEcom.subscriptions) &&
      (!this.checkForStarterPlan() || !this.isDataFeedStarterPlan())
    );
  }

  public isFreeTrialAvailable(ecom: EcomVO): boolean {
    if (!environment.hasFreeTrial) {
      return false;
    }
    if (Utils.isNullOrUndefined(ecom) || Utils.isNullOrUndefined(ecom.subscriptions)) {
      return false;
    }
    const isEcomValid = getPlatformByType(ecom.ecomType as EcomTypeEnum).options.hasFreeTrial;
    return (
      environment.hasFreeTrial &&
      isEcomValid &&
      (Utils.isNullOrUndefined(ecom.subscriptions.rmp.freeTrialUntil) ||
        Date.parse(ecom.subscriptions.rmp.freeTrialUntil) > Date.now()) &&
      ecom.subscriptions.rmp.planId === MARKETPLACE_STARTER_PLAN_ID &&
      ecom.subscriptions.df.planId === DATAFEED_STARTER_PLAN_ID
    );
  }
}
