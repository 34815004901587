<div class="image-selector-container" (click)="navigate()">
  <img
    defaultImage="assets/images/transparent-400x400.png"
    [lazyLoad]="images?.[0] | productImageThumbnailCdn"
    [errorImage]="images?.[0]"
    productImageErrorHandler
    [checkedOriginal]="true"
    [alt]="productName"
    width="200"
    height="200"
    oncontextmenu="return false"
  />
</div>
