import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDetailsPageComponent } from './product-details-page.component';
import { CustomThumbnailComponent } from 'app/shared/components/custom-thumbnail/custom-thumbnail.component';
import { ProductImageSelectorComponent } from 'app/shared/components/product-image-selector/product-image-selector.component';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { ImageSelectorProductPageArrowButtonComponent } from 'app/shared/components/image-selector-product-page-arrow-button/image-selector-product-page-arrow-button.component';
import { ProductPageImageViewerComponent } from './product-page-image-viewer/product-page-image-viewer.component';
import { ProductPageVariantSelectorComponent } from './product-page-variant-selector/product-page-variant-selector.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { QuantitySelectorComponent } from './product-page-variant-selector/quantity-selector/quantity-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { SupplierRatingDisplayComponent } from './product-page-variant-selector/supplier-rating-display/supplier-rating-display.component';
import { SupplierIconComponent } from '../../shared/components/supplier-icon/supplier-icon.component';
import { ReviewComponent } from '../../shared/components/review/review.component';
import { ProductPagePriceDisplayComponent } from './product-page-price-display/product-page-price-display.component';
import { MarketplaceBadgesComponent } from '../../shared/components/marketplace-badges/marketplace-badges.component';
import { ProductPageDetailsAccordionComponent } from './product-page-details-accordion/product-page-details-accordion.component';
import { AccordionProductDetailsComponent } from './product-page-details-accordion/product-details/accordion-product-details.component';
import { MatDividerModule } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { SanitizeHtmlPipe } from '../../shared/pipes/sanitize-html.pipe';
import { SupplierOrderInfoComponent } from '../../shared/components/supplier-order-info/supplier-order-info.component';
import { MoreFromSupplierComponent } from './more-from-supplier/more-from-supplier.component';
import { ReportSupplierComponent } from '../../shared/components/report-supplier/report-supplier.component';
import { ProductCardModule } from '../product-card/product-card.module';
import { SimilarProductsComponent } from './similiar-products/similar-products.component';
import { RecentlyViewedProductsComponent } from '../../shared/components/recently-viewed-products/recently-viewed-products.component';
import { ProductImageListPipe } from '../../shared/pipes/product-image-list.pipe';
import { NormalizeProductDescriptionPipe } from '../../shared/pipes/normalize-product-description.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorMessageComponent } from '../../shared/components/error-message/error-message.component';
import { AuthenticationDirective } from 'app/shared/directives/authentication.directive';
import { ProductPagePriceDisplayGuestComponent } from './product-page-price-display-guest/product-page-price-display-guest.component';
import { OnlyLoggedInDirective } from 'app/shared/directives/only-logged-in.directive';
import { OnlyGuestDirective } from 'app/shared/directives/only-guest.directive';
import { FollowSupplierButtonComponent } from '../../shared/components/follow-supplier-button/follow-supplier-button.component';
import { ProductPageAddToImportListComponent } from './product-page-add-to-import-list/product-page-add-to-import-list.component';
import { AlertMultiComponent } from 'app/shared/components/alert-multi/alert-multi.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContactSupplierButtonComponent } from '../../shared/components/contact-supplier-button/contact-supplier-button.component';
import { ReturnAndRefundPolicyComponent } from './return-and-refund-policy/return-and-refund-policy.component';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { GuestOpenRegistrationDirective } from 'app/shared/directives/guest-open-registration.directive';
import { CurrencyTransformPipe } from 'app/shared/pipes/currency-transform-pipe.pipe';
import { ProductsListSliderBoxComponent } from 'app/shared/components/products-list-slider-box/products-list-slider-box.component';
import { SanitizeJsonSafeHtmlPipe } from 'app/shared/pipes/sanitize-json-safehtml.pipe';
import { GetCategoryByLanguage } from 'app/shared/pipes/get-category-by-language.pipe';
import { CreateSupplierFullUrlPipe } from 'app/shared/pipes/create-supplier-url.pipe';
import { SupplierLogoFromUseridPipe } from 'app/shared/pipes/supplier-logo-from-userid.pipe';
import { CurrencyExchangeToPipe } from 'app/shared/pipes/currency-exchange-to.pipe';
import { SkeletonModule } from 'app/shared/components/skeleton/skeleton.module';
import { OverwriteHrefsPipe } from '../../shared/pipes/text-transformation/overwrite-hrefs.pipe';
import { ConditionLoadingDirective } from '../../shared/directives/condition-loading.directive';
import { OverwriteHTagsPipe } from '../../shared/pipes/text-transformation/overwrite-h-tags.pipe';
import { FavouriteHeartComponent } from '../../shared/components/favourite-heart/favourite-heart.component';
import { MinimumOrderDisplayWithDialogComponent } from '../../shared/components/minimum-order-display-with-dialog/minimum-order-display-with-dialog.component';
import { HasPermissionDirective } from '../../shared/directives/has-permission.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { ConvertToUserWeightUnitPipe } from '../../shared/pipes/convert-to-user-weight-unit.pipe';
import { SingleAlertWithIconComponent } from '../../shared/components/single-alert-with-icon/single-alert-with-icon.component';
import { IsProductInImportListByProductIdPipe } from '../../shared/pipes/is-product-in-import-list-by-product-id.pipe';
import { CatalogStatusCheckPipe } from 'app/shared/pipes/catalog-status-check.pipe';

@NgModule({
  declarations: [
    ProductDetailsPageComponent,
    ProductPageImageViewerComponent,
    ProductPageVariantSelectorComponent,
    SupplierRatingDisplayComponent,
    ProductPagePriceDisplayComponent,
    ProductPageDetailsAccordionComponent,
    AccordionProductDetailsComponent,
    MoreFromSupplierComponent,
    SimilarProductsComponent,
    ProductPagePriceDisplayGuestComponent,
    ProductPageAddToImportListComponent,
    ReturnAndRefundPolicyComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    CustomThumbnailComponent,
    ProductImageSelectorComponent,
    ImageSelectorProductPageArrowButtonComponent,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    SupplierIconComponent,
    ReviewComponent,
    MarketplaceBadgesComponent,
    MatDividerModule,
    RouterLink,
    SanitizeHtmlPipe,
    SupplierOrderInfoComponent,
    ReportSupplierComponent,
    ProductCardModule,
    RecentlyViewedProductsComponent,
    ProductImageListPipe,
    NormalizeProductDescriptionPipe,
    MatProgressSpinnerModule,
    ErrorMessageComponent,
    AuthenticationDirective,
    OnlyLoggedInDirective,
    OnlyGuestDirective,
    FollowSupplierButtonComponent,
    QuantitySelectorComponent,
    ReactiveFormsModule,
    AlertMultiComponent,
    ContactSupplierButtonComponent,
    NgxJsonLdModule,
    GuestOpenRegistrationDirective,
    CurrencyTransformPipe,
    ProductsListSliderBoxComponent,
    SanitizeJsonSafeHtmlPipe,
    GetCategoryByLanguage,
    CreateSupplierFullUrlPipe,
    SupplierLogoFromUseridPipe,
    ProductImageListPipe,
    CurrencyExchangeToPipe,
    SkeletonModule,
    ExtendedModule,
    OverwriteHrefsPipe,
    ConditionLoadingDirective,
    OverwriteHTagsPipe,
    FavouriteHeartComponent,
    MinimumOrderDisplayWithDialogComponent,
    HasPermissionDirective,
    TranslateModule,
    MatExpansionModule,
    ConvertToUserWeightUnitPipe,
    SingleAlertWithIconComponent,
    IsProductInImportListByProductIdPipe,
    CatalogStatusCheckPipe,
  ],
})
export class ProductDetailsPageModule {}
