import { Badge, FeedSettings, Product, Settings, Shipping, Supplier, Variant } from '../../../pb/product/v1/product_pb';
import { BadgesVO, FeedSettingsVo, SearchProductVO, SettingsVo, SupplierVo, VariantVO } from '../vo/search-product-vo';
import { Shipping as ShippingVo } from '../vo/shipping/shipping';
import { Utils } from 'app/utils/utils';
import { CommonMapper } from './common.mapper';

export class ProductMapper {
  public static mapIntoSearchProductVO(product: Product): SearchProductVO {
    return {
      ID: product.synceeId,
      TASK_ID: product.taskId,
      USER_ID: CommonMapper.mapBigintIntoNumber(product.userId),
      DESCRIPTION: product.description,
      IMAGES: product.images,
      // ASK ABOUT SYNCEE_IMAGES
      TAGS: product.tags,
      SHOPIFY_OPTION_NAME_1: product.shopifyOptionName1,
      SHOPIFY_OPTION_NAME_2: product.shopifyOptionName2,
      SHOPIFY_OPTION_NAME_3: product.shopifyOptionName3,
      BRAND: product.brand,
      CATEGORY: product.category,
      TITLE: product.title,
      IMAGES_ALTS: product.imagesAlts,
      VARIANTS: product.variants?.map?.(ProductMapper.mapIntoVariantVO),
      HASH: CommonMapper.mapBigintIntoString(product.hash),
      LAST_MODIFIED: product.lastModified,
      CREATED: product.created,
      PUBLISHED: product.published,
      // ASK ABOUT VERSION
      SUPPLIER: ProductMapper.mapIntoSupplierVO(product.supplier),
      SETTINGS: ProductMapper.mapIntoSettingsVO(product.settings),
      SHOP_ID: null,
      // ASK ABOUT WEIGHT_UNIT
      SEO_URL: product.seoUrl,
      BADGES: product.badges?.map?.(ProductMapper.mapIntoBadgesVO),
      IS_HIDDEN: product.isHidden,
      SHIPPING: product.shipping?.map?.(ProductMapper.mapIntoShippingVO),
      // ASK ABOUT DETAIL_URL
      // ASK ABOUT LADDER_PERIOD
      // ASK ABOUT SYNCEE_CATEGORY
      SYNCEE_CATEGORY: product.synceeCategory,
    };
  }

  public static mapIntoVariantVO(variant: Variant): VariantVO {
    if (Utils.isNullOrUndefinedOrLengthZero(variant)) {
      return null;
    }

    return {
      SHOPIFY_OPTION_VALUE_1: variant.shopifyOptionValue1,
      SHOPIFY_OPTION_VALUE_2: variant.shopifyOptionValue2,
      SHOPIFY_OPTION_VALUE_3: variant.shopifyOptionValue3,
      V_IMAGES: variant.vImages,
      UPC: variant.upc,
      WEIGHT: variant.weight,
      SKU: variant.sku,
      QTY: CommonMapper.mapBigintIntoNumber(variant.qty),
      PRICE: variant.price,
      RETAIL_PRICE: variant.retailPrice,
      DISCOUNTED_PRICE: variant.discountedPrice,
      ORIGINAL_PRICE: variant.originalPrice,
      STORE_PRICE: variant.storePrice,
      STORE_CURRENCY: variant.storeCurrency,
      DEFAULT_CURRENCY_PRICE: variant.defaultCurrencyPrice,
      VARIANT_ID: variant.variantId,
    };
  }

  public static mapIntoSupplierVO(supplier: Supplier): SupplierVo {
    if (Utils.isNullOrUndefinedOrLengthZero(supplier)) {
      return null;
    }

    return {
      // ASK ABOUT id
      // ASK ABOUT userId
      contactEmail: supplier.contactEmail,
      // ASK ABOUT userEmail
      website: supplier.website,
      // ASK ABOUT description
      // ASK ABOUT categories
      // ASK ABOUT worldwideShipping
      // ASK ABOUT europeanCountries
      // ASK ABOUT europeanShippingTime
      // ASK ABOUT asianCountries
      // ASK ABOUT asianShippingTime
      // ASK ABOUT americanCountries
      // ASK ABOUT americanShippingTime
      // ASK ABOUT africanCountries
      // ASK ABOUT africanShippingTime
      // ASK ABOUT australianCountries
      // ASK ABOUT australianShippingTime
      companyName: supplier.companyName,
      handle: supplier.handle,
      // ASK ABOUT shippingDetails
      // ASK ABOUT settings
      shipsTo: supplier.shipsTo,
      // ASK ABOUT logoLink
      // ASK ABOUT isAutomate
      isAutomated: supplier.isAutomated,
      // ASK ABOUT seoUrl
      // ASK ABOUT publicPhoneCode
      // ASK ABOUT publicPhoneNumber
      paymentCurrency: supplier.paymentCurrency,
    };
  }

  public static mapIntoSettingsVO(settings: Settings): SettingsVo {
    if (Utils.isNullOrUndefinedOrLengthZero(settings)) {
      return null;
    }

    return {
      possibleMargin: settings.possibleMargin,
      language: settings.language,
      shipping_type: settings.shippingType,
      approveNeeded: settings.approveNeeded,
      approveDescription: settings.approveDescription,
      needResponse: settings.needResponse,
      showPrices: settings.showPrices,
      sendEmail: settings.sendEmail,
      warehouseLocation: settings.warehouseLocation,
      mainCategories: settings.mainCategories,
      feedSettings: ProductMapper.mapIntoFeedSettingsVO(settings.feedSettings),
      premium: settings.premium,
    };
  }

  public static mapIntoFeedSettingsVO(feedSettings: FeedSettings): FeedSettingsVo {
    if (Utils.isNullOrUndefinedOrLengthZero(feedSettings)) {
      return null;
    }

    return {
      backorder: feedSettings.backorder,
      shipping: feedSettings.shipping,
    };
  }

  public static mapIntoBadgesVO(badge: Badge): BadgesVO {
    if (Utils.isNullOrUndefinedOrLengthZero(badge)) {
      return null;
    }

    return {
      ID: badge.id,
      NAME: badge.name,
      SOURCE: badge.source,
      ORDER: badge.order,
      POSITION: badge.position,
      BADGE_TYPE: badge.badgeType,
    };
  }

  public static mapIntoShippingVO(shipping: Shipping): ShippingVo {
    if (Utils.isNullOrUndefinedOrLengthZero(shipping)) {
      return null;
    }

    return {
      locations: shipping.locations,
      shippingCost: shipping.shippingCost,
      additionalCost: shipping.additionalCost,
      minShippingDays: shipping.minShippingDays,
      maxShippingDays: shipping.maxShippingDays,
    };
  }
}
