<ng-container *ngIf="!isLoading; else skeleton">
  <ng-container *ngIf="!noSearchHistory">
    <div [ngClass]="wrapperClass">
      <!--  [isProductUsed]="isProductUsed" -->
      <app-products-list-slider-box
        [title]="'PRODUCT_PAGE.RECENTLY_VIEWED_PRODUCTS' | translate"
        [productlist]="productHistory"
        [ecomCurrency]="ecomCurrency | async"
        [generateMicrodata]="generateMicrodata"
      ></app-products-list-slider-box>
    </div>
  </ng-container>
</ng-container>

<ng-template #skeleton>
  <div [class]="wrapperClass">
    <app-skeleton-products-list-slider-box></app-skeleton-products-list-slider-box>
  </div>
</ng-template>
