import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'app/utils/utils';
import { EcomTypeEnum } from 'app/vo/enums/ecom-type-enum';
import { take } from 'rxjs/operators';
import { EcomVO } from '../../service/ecom/ecom.service';
import { RestService } from '../../service/rest/rest.service';
import { SubscriptionService } from '../billing-new/service/subscription.service';
import { SynceeReviewDialogService } from '../syncee-review/service/syncee-review-dialog.service';

@Component({
  selector: 'app-thank-you-page-shopify',
  templateUrl: './thank-you-page-shopify.component.html',
  styleUrls: ['./thank-you-page-shopify.component.scss'],
})
export class ThankYouPageShopifyComponent implements OnInit {
  public isLoaded = false;
  public errorMessage = '';
  public ecom: EcomVO;
  public ecomType: string;
  public ecomId: string;

  protected readonly EcomTypeEnum = EcomTypeEnum;

  public sharesale: any = null;

  constructor(
    private route: ActivatedRoute,
    private restService: RestService,
    private synceeReviewDialogService: SynceeReviewDialogService
  ) {
    this.activateSubscription();
  }

  ngOnInit(): void {}

  setEcom(param): void {
    this.ecomId = param.ecomId;
    this.ecomType = param.type;
    this.isLoaded = true;
  }

  activateSubscription(): void {
    this.route.queryParams.pipe(take(1)).subscribe((param) => {
      if (!Utils.isNullOrUndefined(param.subscriptionData)) {
        this.route.queryParams.subscribe((params) => {
          if (!Utils.isNullOrUndefined(params.charge_id)) {
            this.restService
              .post('ShopifyPlanService/activateSubscription', {
                subscriptionData: param.subscriptionData,
                charge_id: params.charge_id,
              })
              .subscribe(
                (plan) => {
                  this.setEcom(plan.getFirstData());
                  this.openReviewDialog();
                },
                (error) => {
                  this.isLoaded = true;
                  this.errorMessage = 'BILLING.SUBSCRIPTION.SHOPIFY_THANK_YOU_PAGE.' + error.errorCode;
                }
              );
          } else {
            this.isLoaded = true;
            this.errorMessage = 'BILLING.SUBSCRIPTION.SHOPIFY_THANK_YOU_PAGE.ERROR';
          }
        });
      } else if (!Utils.isNullOrUndefined(param.token) && param.token == SubscriptionService.fakeToken) {
        if (param.type && param.type === 'wix') {
          setTimeout(() => {
            this.setEcom(param);
          }, 10000);
        } else {
          this.setEcom(param);
        }
      } else {
        this.errorMessage = 'BILLING.SUBSCRIPTION.SHOPIFY_THANK_YOU_PAGE.ERROR';
        this.isLoaded = true;
      }
      // this.saveSnippets();
    });
  }

  openReviewDialog(): void {
    this.synceeReviewDialogService.open();
  }
}
