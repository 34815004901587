import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RcatalogsState } from './rcatalogs.reducer';
import { Catalog, CatalogFilter } from '../../vo/catalog';
import { Utils } from 'app/utils/utils';

const getRcCatalogState = createFeatureSelector<RcatalogsState>('rcatalogs');

export const rcatalogsSelector = createSelector(
  getRcCatalogState,
  (state: RcatalogsState) => !!state && !!state.catalogs && state.catalogs
);
export const nonAlibabaCatalogsSelector = createSelector(
  rcatalogsSelector,
  (catalogs) => !!catalogs && catalogs.filter((catalog) => !catalog.alibabaCatalog)
);

export const marketplaceCatalogsSelector = createSelector(
  nonAlibabaCatalogsSelector,
  (catalogs) => !!catalogs && catalogs.filter((catalog) => !catalog.filter.some((filter) => filter.key === 'TASK_ID'))
);
export const selectedCatalogSelector = createSelector(getRcCatalogState, (state) => !!state && state.selectedCatalog);

export const recentlyAddedProductsSelector = createSelector(getRcCatalogState, (state) => state?.recentlyAddedProducts);

export const usedMarketplaceProductNumberSelector = createSelector(
  marketplaceCatalogsSelector,
  (catalogs, ecomId: number) => {
    const selectedEcomCatalogs = catalogs.filter((elem) => elem.ecomId == ecomId);
    const numbers = selectedEcomCatalogs.map((importList) => {
      return importList.filter.find((importListFilter: CatalogFilter): boolean => importListFilter.key === 'ID')?.values
        ?.length;
    });
    return numbers?.reduce((prev, curr) => prev + curr, 0);
  }
);

export const usedSupplierUserIdsByEcomIdSelector = (ecomId: number) =>
  createSelector(getRcCatalogState, (state) => state?.usedSupplierUserIds && state.usedSupplierUserIds[ecomId]);

export const selectedCatalogProductNumberSelector = createSelector(
  getRcCatalogState,
  (state) => state?.selectedCatalogProductNumber
);

export const isProductInImportListByProductIdSelector = (synceeId: string) =>
  createSelector(getRcCatalogState, (state) => {
    return state?.catalogs?.some?.((catalog: Catalog): boolean => {
      const idFilter = catalog.filter.find((filter: CatalogFilter) => filter.key === 'ID');

      if (Utils.isNullOrUndefinedOrLengthZero(idFilter?.values)) {
        return false;
      }

      return idFilter.values.includes(synceeId);
    });
  });

export const firstImportListByProductIdSelector = (synceeId: string) =>
  createSelector(getRcCatalogState, (state) => {
    return state?.catalogs?.find?.((catalog: Catalog) => {
      const idFilter = catalog.filter.find((filter: CatalogFilter) => filter.key === 'ID');
      return idFilter?.values.includes(synceeId);
    });
  });

export const importListsByProductIdSelector = (synceeId: string) =>
  createSelector(getRcCatalogState, (state) => {
    return state?.catalogs?.filter?.((catalog: Catalog) => {
      const idFilter = catalog.filter.find((filter: CatalogFilter) => filter.key === 'ID');
      return idFilter?.values.includes(synceeId);
    });
  });

export const productIdsInImportListsSelector = createSelector(getRcCatalogState, (state) => {
  return state?.catalogs
    ?.map?.((catalog: Catalog) => {
      const idFilter = catalog.filter.find((filter: CatalogFilter) => filter.key === 'ID');

      if (idFilter) {
        return idFilter.values;
      }

      return [];
    })
    ?.flat?.();
});
