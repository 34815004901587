import { Injectable } from '@angular/core';
import {
  ProductListBaseFilterEditStatus,
  ProductListBaseFilterInStoreStatus,
  ProductListBaseFilterPermissionStatus,
} from 'app/main/retailer-import-list/tab-items/retailer-import-list-products/model/product-list-base-filter';
import { Utils } from 'app/utils/utils';
import { isNil, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcomService } from '../../../../../service/ecom/ecom.service';
import { RestService } from '../../../../../service/rest/rest.service';
import { SearchProductVO } from '../../../../../vo/search-product-vo';
import { NotificationService } from '../../../../notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class RetailerProductsService {
  constructor(
    private restService: RestService,
    private notificationService: NotificationService,
    private ecomService: EcomService
  ) {}

  public getProducts(catalogId: number, sortSettings?: ProductSortSettings): Observable<CatalogProducts> {
    const queryParams = `?catalogId=${catalogId}${this.getOrder(sortSettings)}`;
    return this.restService
      .get(`RetailerCatalogService/getProductsOfCatalog${queryParams}`)
      .pipe(map((response) => response.getFirstData()));
  }

  public getProductsOfCatalog(queryParams: ProductsOfCatalogQueryParams): Observable<CatalogProducts> {
    return this.restService
      .get(
        'RetailerCatalogService/getProductsOfCatalog',
        omitBy(
          {
            ...queryParams,
            order: queryParams?.order
              ? `${queryParams?.order?.field}:${queryParams?.order?.order?.toUpperCase()}`
              : undefined,
          },
          isNil
        )
      )
      .pipe(map((res) => res.getFirstData()));
  }

  private getOrder(sortSettings: ProductSortSettings): string {
    if (Utils.isNullOrUndefined(sortSettings)) {
      return '';
    } else {
      return `&order=${sortSettings.field}:${sortSettings.order}`;
    }
  }

  public copyProductURL(ecom, productId): void {
    if (!Utils.isNullOrUndefined(ecom) && !Utils.isNullOrUndefined(productId)) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = this.ecomService.getProductLinkByEcomType(ecom, productId);
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.notificationService.success('Copied to Clipboard');
    } else {
      this.notificationService.error('Missing parameter: ecom or productId');
    }
  }

  getTotalImportListByCatalogId(catalogId: number): Observable<number> {
    return this.restService
      .get('RetailerCatalogService/getTotalImportListByCatalogId', { catalogId })
      .pipe(map((res) => res.getFirstData()));
  }

  getImportedToStoreByCatalogId(catalogId: number): Observable<number> {
    return this.restService
      .get('RetailerCatalogService/getImportedToStoreByCatalogId', { catalogId })
      .pipe(map((res) => res.getFirstData()));
  }

  getAwaitingForApprovalByCatalogId(catalogId: number): Observable<number> {
    return this.restService
      .get('RetailerCatalogService/getAwaitingForApprovalByCatalogId', { catalogId })
      .pipe(map((res) => res.getFirstData()));
  }
}

export class ProductSortSettings {
  field: string;
  order: string;
}

interface CatalogProducts {
  aggregations: any[];
  matches: number;
  nextPage: string;
  products: SearchProductVO[];
  query: CatalogProductsQuery;
  result: number;
  tasks: number[];
  took: number;
  totalRows: number;
  users: number[];
}

interface CatalogProductsQuery {
  from: number;
  size: number;
}

export interface ProductsOfCatalogQueryParams {
  catalogId: number;
  from: number;
  size: number;
  status?: ProductListBaseFilterPermissionStatus;
  inStore?: ProductListBaseFilterInStoreStatus;
  pimEdited?: ProductListBaseFilterEditStatus;
  searchString?: string;
  applyPim?: boolean;
  order?: ProductSortSettings;
}
