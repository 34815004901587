// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file app_type.proto (syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file app_type.proto.
 */
export const file_app_type: GenFile = /*@__PURE__*/
  fileDesc("Cg5hcHBfdHlwZS5wcm90byolCgdBcHBUeXBlEgwKCFJFVEFJTEVSEAASDAoIU1VQUExJRVIQAUIxCgxjby5zeW5jZWUucGJCDEFwcFR5cGVQcm90b1ABWgQuL3BiygIKUHBtc1xQcm90b2IGcHJvdG8z");

/**
 * @generated from enum AppType
 */
export enum AppType {
  /**
   * @generated from enum value: RETAILER = 0;
   */
  RETAILER = 0,

  /**
   * @generated from enum value: SUPPLIER = 1;
   */
  SUPPLIER = 1,
}

/**
 * Describes the enum AppType.
 */
export const AppTypeSchema: GenEnum<AppType> = /*@__PURE__*/
  enumDesc(file_app_type, 0);

