import { create, fromJson, JsonValue } from '@bufbuild/protobuf';
import {
  ProductSearchRequest,
  ProductSearchRequestSchema,
  SearchType,
} from '../../../pb/requests/v1/product_search_request_pb';
import { SearchProductType, SearchQueryVO } from 'app/vo/search-product-vo';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { ValueSchema } from '@bufbuild/protobuf/wkt';
import { Ecom, EcomSchema } from '../../../pb/ecom_pb';
import { CommonMapper } from './common.mapper';
import { EcomType } from '../../../pb/ecom_type_pb';
import { EcomTypeEnum } from 'app/vo/enums/ecom-type-enum';
import { Utils } from 'app/utils/utils';

export class ProductSearchRequestMapper {
  public static readonly SEARCH_PRODUCT_TYPE_SEARCH_TYPE_MAPPING: { [key in SearchProductType]: SearchType } = {
    [SearchProductType.HYBRID]: SearchType.HYBRID,
    [SearchProductType.FULL_TEXT]: SearchType.FULL_TEXT,
    [SearchProductType.VECTOR]: SearchType.VECTOR,
  };

  public static readonly ECOM_TYPE_ECOM_TYPE_MAPPING: { [key in EcomTypeEnum]: EcomType } = {
    [EcomTypeEnum.SHOPIFY]: EcomType.SHOPIFY,
    [EcomTypeEnum.WIX]: EcomType.WIX,
    [EcomTypeEnum.WOOCOMMERCE]: EcomType.WOOCOMMERCE,
    [EcomTypeEnum.BIGCOMMERCE]: EcomType.BIGCOMMERCE,
    [EcomTypeEnum.ECWID]: EcomType.ECWID,
    [EcomTypeEnum.KMOSHOPS]: null,
    [EcomTypeEnum.JUMPSELLER]: EcomType.JUMPSELLER,
    [EcomTypeEnum.SHOPRENTER]: EcomType.SHOPRENTER,
    [EcomTypeEnum.SQUARESPACE]: EcomType.SQUARESPACE,
    [EcomTypeEnum.UNSAS]: EcomType.UNAS,
    [EcomTypeEnum.EKM]: EcomType.EKM,
    [EcomTypeEnum.ZYRO]: null,
    [EcomTypeEnum.SHOPLAZZA]: null,
  };

  public static mapIntoProductSearchRequest(
    userId: number,
    ecom: EcomVO,
    searchQuery: SearchQueryVO,
    isHidden: boolean,
    countryCode: string,
    searchType: SearchProductType
  ): ProductSearchRequest {
    return create(ProductSearchRequestSchema, {
      ecom: this.mapEcomVoIntoEcom(ecom),
      search: searchQuery.search,
      userId: CommonMapper.mapNumberIntoBigint(userId),
      category: searchQuery.category,
      from: searchQuery.from,
      size: searchQuery.size,
      isHidden: isHidden,
      searchType: this.mapSearchTypeIntoSearchType(searchType),
      countryCode: countryCode,
      queryFilter: fromJson(ValueSchema, searchQuery.queryFilter as JsonValue),
    });
  }

  public static mapSearchTypeIntoSearchType(searchType: SearchProductType): SearchType {
    return this.SEARCH_PRODUCT_TYPE_SEARCH_TYPE_MAPPING[searchType];
  }

  public static mapEcomTypeEnumIntoEcomType(ecomType: EcomTypeEnum): EcomType {
    return this.ECOM_TYPE_ECOM_TYPE_MAPPING[ecomType];
  }

  public static mapEcomVoIntoEcom(ecom: EcomVO): Ecom {
    if (Utils.isNullOrUndefined(ecom)) {
      return undefined;
    }

    return fromJson(EcomSchema, {
      ecomType: this.mapEcomTypeEnumIntoEcomType(ecom.ecomType as EcomTypeEnum),
      id: ecom.id,
    } as JsonValue);
  }
}
