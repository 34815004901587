import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from 'app/service/rest/microservices/spring-rest.service';
import { Utils } from 'app/utils/utils';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { newCountries } from '../../../utils/Countries';
import { languages } from '../../../utils/Languages';
import { CategoryVo } from '../../../vo/category-vo';
import { createQueryFilter, MarketplaceFilter, SearchProductVO, SearchQueryVO } from '../../../vo/search-product-vo';
import { SupplierDto } from '../../../vo/supplier/supplier-dto';
import { CountriesManagerService } from '../../countries-manager/countries-manager.service';
import { RestService } from '../../rest/rest.service';
import { IsProductUsedResponse } from './is-product-used';
import { ImportListDto } from '../../../main/retailer-import-list/model/import-list.dto';

@Injectable({
  providedIn: 'root',
})
export class ExploreProductsService {
  public sizeByScreenSize;

  constructor(
    private restService: RestService,
    private _cookieService: CookieService,
    private countriesManagerService: CountriesManagerService,
    private springRestService: SpringRestService
  ) {
    this.sizeByScreenSize = window.screen.width >= 1814 || window.screen.width < 1544 ? 48 : 50;
  }

  public getProductsByFilterItems(filterItems, endpoint): Observable<any> {
    const postQuery = new SearchQueryVO();
    const queryFilters = [];
    // const shipsToqueryFilter = [];
    const inStockqueryFilter = [];

    filterItems.getParams().forEach((elem) => {
      if (!Utils.isNullOrUndefined(elem.value)) {
        if (elem.isQueryFilter) {
          switch (elem.name) {
            case 'shipsFrom':
              const allEUCountries = [];
              newCountries.Europe.forEach((country) => allEUCountries.push(country.name));
              if (elem.value === 'Europe') {
                queryFilters.push(createQueryFilter(elem.elasticField, allEUCountries));
                queryFilters.push('and');
              } else {
                queryFilters.push(createQueryFilter(elem.elasticField, elem.value));
                queryFilters.push('and');
              }
              break;
            case 'stock':
              if (elem.value !== 'any') {
                inStockqueryFilter.push(createQueryFilter(elem.elasticField, [0], '>'));
                inStockqueryFilter.push('or');
                inStockqueryFilter.push(createQueryFilter(elem.elasticField, null, 'not_exists'));
              }
              break;
            case 'autoOrder':
              if (elem.value !== 'any') {
                queryFilters.push(createQueryFilter(elem.elasticField, elem.value));
                queryFilters.push('and');
              }
              break;
            default:
              queryFilters.push(createQueryFilter(elem.elasticField, elem.value));
              queryFilters.push('and');
          }
        } else {
          postQuery[elem.elasticField] = elem.value;
        }
      }
    });
    queryFilters.splice(-1, 1);

    postQuery.queryFilter.push(queryFilters);
    // postQuery.queryFilter.push('and');
    // if (shipsToqueryFilter.length === 0){
    //     shipsToqueryFilter.push(createQueryFilter('SUPPLIER.shipsTo', '*'));
    //     shipsToqueryFilter.push('or');
    // tslint:disable-next-line: max-line-length
    //     shipsToqueryFilter.push(createQueryFilter('SUPPLIER.shipsTo', null, 'not_exists'));
    // }
    // postQuery.queryFilter.push(shipsToqueryFilter);
    if (inStockqueryFilter.length > 0) {
      postQuery.queryFilter.push('and');
      postQuery.queryFilter.push(inStockqueryFilter);
    }

    const preferences = this._cookieService.get('preferences');
    if (preferences !== '' && preferences !== '{}') {
      postQuery['preferences'] = JSON.parse(preferences);
    }

    return this.restService.post(endpoint, postQuery).pipe(map((response) => response.getData()));
  }

  /*getIsProductUsed(ecomId: number, productIds: string[]): Observable<IsProductUsedResponse> {
    return this.springRestService.get(
      MicroserviceNames.USER,
      `/Retailer/Catalog/Ecom/${ecomId}/isProductUsed`,
      { productId: productIds },
      true
    );
  }

  modifyIsProductUsed(allProducts: SearchProductVO[], productIds: string[], resp: IsProductUsedResponse): void {
    allProducts
      .filter((product) => productIds?.includes(product?.ID))
      .forEach((product) => {
        const inCatalog = resp?.find((cat) => cat?.productIds?.includes(product?.ID));
        product['CATALOG'] = !!inCatalog
          ? [{ CATALOG_ID: inCatalog.catalogId, CATALOG_NAME: inCatalog.catalogName }]
          : [];
      });
  }*/

  public getAutoCompleteItems(params): Observable<any> {
    return this.restService
      .get(`ElasticsSearch/getAutoCompleteItems`, params)
      .pipe(map((response) => response.getFirstData()));
  }

  public getFilteredItems(params): Observable<any> {
    return this.restService
      .get(`ElasticsSearch/searchCatalogWithFilter`, params)
      .pipe(map((response) => response.getData()));
  }

  public createNewCatalog(name: string, margin: number, ecomId: string, filter: any[]): Observable<ImportListDto> {
    return this.restService
      .post(`RetailerCatalogService/add`, {
        name: name,
        ecomId: ecomId,
        filter: filter,
        margin: margin,
      })
      .pipe(map((response) => response.getFirstData()));
  }

  public getCatalogInformation(catalogID): Observable<any> {
    return this.restService
      .get(`SupplierCatalogService/getCatalogDetails?catalogId=${catalogID}`)
      .pipe(map((response) => response.getData()));
  }

  public isCatalogInApprove(catalogID): Observable<any> {
    return this.restService
      .get(`RetailerToCatalogService/isCatalogInApprove?catalogId=${catalogID}`)
      .pipe(map((response) => response.getData()));
  }

  public isCatalogInUse(catalogID, ecomId): Observable<any> {
    return this.restService
      .get(`RetailerCatalogService/isCatalogInUse?catalogId=${catalogID}&ecomId=${ecomId}`)
      .pipe(map((response) => response.getData()));
  }

  public getProductByID(synceeID: string, ecomId: number): Observable<any> {
    return this.restService
      .get(
        `ElasticsSearch/getProductBySynceeId?id=${synceeID}${
          !Utils.isNullOrUndefined(ecomId) ? '&ecomId=' + ecomId : ''
        }`
      )
      .pipe(map((response) => response.getFirstData()));
  }

  recommendProducts(params): Observable<any> {
    return this.restService
      .post(`ElasticsSearch/recommendProducts`, params)
      .pipe(map((response) => response.getData()));
  }

  getAdminRecommendProducts(params): Observable<any> {
    return this.restService
      .get(`RecommendationFiltersService/recommendProducts`, params)
      .pipe(map((response) => response.getData()));
  }

  getFlag(warehouseLocation): string {
    if (warehouseLocation) {
      const code = this.countriesManagerService.getOnlyCountries().find((elem) => {
        return elem.name === warehouseLocation;
      });

      return code ? code.code : null;
    } else {
      return null;
    }
  }

  getLanguage(language): string {
    if (language) {
      const code = languages.find((elem) => {
        return elem.id === language;
      });

      return code ? code.name.toLowerCase() : null;
    } else {
      return null;
    }
  }

  recommendByCategory(params): Observable<any[]> {
    return this.restService
      .get('ElasticsSearch/recommendByCategory', params)
      .pipe(map((response) => response.getData()));
  }

  productHistory(params): Observable<any[]> {
    return this.restService.post('ElasticsSearch/productHistory', params).pipe(map((response) => response.getData()));
  }

  getCatalogInformationByHandle(seoUrl): Observable<any> {
    return this.restService
      .get(`SupplierCatalogService/getCatalogDetails?seoUrl=${seoUrl}`)
      .pipe(map((response) => response.getFirstData()));
  }
}

export interface ProductSearchTerm {
  label: string;
  filter: MarketplaceFilter;
  helpers?: Partial<ProductSearchTermHelpers>;
}

export interface ProductSearchTermHelpers {
  category: CategoryVo;
  supplier: SupplierDto;
}
