import { Component, Input } from '@angular/core';
import { productCardStatusConfigs } from '../../product-card.config';
import { Catalog } from 'app/vo/catalog';

@Component({
  selector: 'app-product-card-action-in-catalog',
  templateUrl: './product-card-action-in-catalog.component.html',
  styleUrls: ['./product-card-action-in-catalog.component.scss'],
})
export class ProductCardActionInCatalogComponent {
  @Input() catalog: Catalog;

  readonly config = productCardStatusConfigs;
}
