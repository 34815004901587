// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file user_account.proto (syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file user_account.proto.
 */
export const file_user_account: GenFile = /*@__PURE__*/
  fileDesc("ChJ1c2VyX2FjY291bnQucHJvdG8iOgoLVXNlckFjY291bnQSCgoCaWQYASABKAQSEAoIdXNlcm5hbWUYAiABKAkSDQoFZW1haWwYAyABKAlCNQoMY28uc3luY2VlLnBiQhBVc2VyQWNjb3VudFByb3RvUAFaBC4vcGLKAgpQcG1zXFByb3RvYgZwcm90bzM");

/**
 * @generated from message UserAccount
 */
export type UserAccount = Message<"UserAccount"> & {
  /**
   * @generated from field: uint64 id = 1;
   */
  id: bigint;

  /**
   * @generated from field: string username = 2;
   */
  username: string;

  /**
   * @generated from field: string email = 3;
   */
  email: string;
};

/**
 * Describes the message UserAccount.
 * Use `create(UserAccountSchema)` to create a new message.
 */
export const UserAccountSchema: GenMessage<UserAccount> = /*@__PURE__*/
  messageDesc(file_user_account, 0);

