import { HttpHeaders, type HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { create, createRegistry, fromBinary, fromJson, JsonValue, Message, toBinary, toJson } from '@bufbuild/protobuf';
import type { GenMessage } from '@bufbuild/protobuf/codegenv1';
import { anyUnpack } from '@bufbuild/protobuf/wkt';
import type { RequestOptions } from 'app/service/rest/rest.service';
import { Utils } from 'app/utils/utils';
import { environment } from 'environments/environment';
import { iif, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PageResponseSchema } from '../../../../pb/responses/v1/page_response_pb';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Accept } from './types/accept';
import { ContentType } from './types/content-type';
import type { PageResponseWithType } from './types/page-response-with-type';

@Injectable({
  providedIn: 'root',
})
export class ProtobufSpringService {
  private static LOCAL_ENVIRONMENT = environment.localEnvironment;

  constructor(private springRestService: SpringRestService) {}

  private static modifyHttpHeaders(headers: HttpHeaders): HttpHeaders {
    return headers
      .set('Accept', ProtobufSpringService.LOCAL_ENVIRONMENT ? Accept.APPLICATION_JSON : Accept.APPLICATION_X_PROTOBUF)
      .set(
        'Content-Type',
        ProtobufSpringService.LOCAL_ENVIRONMENT ? ContentType.APPLICATION_JSON : ContentType.APPLICATION_X_PROTOBUF
      );
  }

  private static toBinary<M extends Message>(schema: GenMessage<M>, params: object): Uint8Array {
    if (Utils.isNullOrUndefined(schema)) {
      return null;
    }

    return toBinary(schema, create(schema, params));
  }

  private static toJson<T extends Message>(schema: GenMessage<T>, data: T): JsonValue {
    if (Utils.isNullOrUndefined(schema)) {
      return null;
    }

    return toJson(schema, data);
  }

  private static fromJson<T extends Message>(schema: GenMessage<T>, data: JsonValue): T {
    if (Utils.isNullOrUndefined(schema)) {
      return null;
    }

    return fromJson(schema, data);
  }

  private static fromBinary<T extends Message>(schema: GenMessage<T>, data: Iterable<number>): T {
    if (Utils.isNullOrUndefined(schema)) {
      return null;
    }

    return fromBinary(schema, new Uint8Array(data));
  }

  private static pageFromJson<T extends Message>(schema: GenMessage<T>, data: JsonValue): PageResponseWithType<T> {
    const registry = createRegistry(schema);
    const response = fromJson(PageResponseSchema, data, { registry });
    return Object.assign({}, response, { content: response.content?.map?.((item) => anyUnpack(item, schema)) });
  }

  private static pageFromBinary<T extends Message>(
    schema: GenMessage<T>,
    data: Iterable<number>
  ): PageResponseWithType<T> {
    const response = fromBinary(PageResponseSchema, new Uint8Array(data));
    return Object.assign({}, response, { content: response.content?.map?.((item) => anyUnpack(item, schema)) });
  }

  public get<M extends Message>(
    responseSchema: GenMessage<M>,
    microserviceName: string | MicroserviceNames,
    url: string,
    params?: HttpParams | any,
    gateway?: boolean,
    headers: HttpHeaders = new HttpHeaders(),
    skipNotification?: boolean
  ): Observable<M> {
    const _headers = ProtobufSpringService.modifyHttpHeaders(headers);

    const observable = iif(
      () => ProtobufSpringService.LOCAL_ENVIRONMENT,
      this.springRestService
        .get(microserviceName, url, params, gateway, _headers, skipNotification, 'json')
        .pipe(map((response) => ProtobufSpringService.fromJson(responseSchema, response as JsonValue))),
      this.springRestService
        .get(microserviceName, url, params, gateway, _headers, skipNotification, 'arraybuffer')
        .pipe(map((response) => ProtobufSpringService.fromBinary(responseSchema, response as Iterable<number>)))
    );

    return observable;
  }

  public post<R extends Message, M extends Message>(
    requestSchema: GenMessage<R>,
    responseSchema: GenMessage<M>,
    microserviceName: string | MicroserviceNames,
    url: string,
    body: R,
    options: RequestOptions = {},
    gateway?: boolean,
    skipNotification?: boolean
  ): Observable<M> {
    let headers = (options.headers as HttpHeaders) ?? new HttpHeaders();

    headers = ProtobufSpringService.modifyHttpHeaders(headers);

    const _options: RequestOptions = Object.assign({}, options, { headers });

    const observable = iif(
      () => ProtobufSpringService.LOCAL_ENVIRONMENT,
      this.springRestService
        .post(
          microserviceName,
          url,
          ProtobufSpringService.toJson(requestSchema, body),
          _options,
          gateway,
          skipNotification,
          'json'
        )
        .pipe(map((response) => ProtobufSpringService.fromJson(responseSchema, response as JsonValue))),
      this.springRestService
        .post(
          microserviceName,
          url,
          new Blob([ProtobufSpringService.toBinary(requestSchema, body)]),
          _options,
          gateway,
          skipNotification,
          'arraybuffer'
        )
        .pipe(map((response) => ProtobufSpringService.fromBinary(responseSchema, response as Iterable<number>)))
    );

    return observable;
  }

  public delete<M extends Message>(
    responseSchema: GenMessage<M>,
    microserviceName: string | MicroserviceNames,
    url: string,
    params?: HttpParams | any,
    gateway?: boolean,
    headers: HttpHeaders = new HttpHeaders(),
    skipNotification?: boolean
  ): Observable<M> {
    const _headers = ProtobufSpringService.modifyHttpHeaders(headers);

    const observable = iif(
      () => ProtobufSpringService.LOCAL_ENVIRONMENT,
      this.springRestService
        .delete(microserviceName, url, params, gateway, null, skipNotification, _headers, 'json')
        .pipe(map((response) => ProtobufSpringService.fromJson(responseSchema, response as JsonValue))),
      this.springRestService
        .delete(microserviceName, url, params, gateway, null, skipNotification, _headers, 'arraybuffer')
        .pipe(map((response) => ProtobufSpringService.fromBinary(responseSchema, response as Iterable<number>)))
    );

    return observable;
  }

  public getPage<M extends Message>(
    itemSchema: GenMessage<M>,
    microserviceName: string | MicroserviceNames,
    url: string,
    params?: HttpParams | any,
    gateway?: boolean,
    headers: HttpHeaders = new HttpHeaders(),
    skipNotification?: boolean
  ): Observable<PageResponseWithType<M>> {
    const _headers = ProtobufSpringService.modifyHttpHeaders(headers);

    const observable = iif(
      () => ProtobufSpringService.LOCAL_ENVIRONMENT,
      this.springRestService
        .get(microserviceName, url, params, gateway, _headers, skipNotification, 'json')
        .pipe(map((response) => ProtobufSpringService.pageFromJson(itemSchema, response as JsonValue))),
      this.springRestService
        .get(microserviceName, url, params, gateway, _headers, skipNotification, 'arraybuffer')
        .pipe(map((response) => ProtobufSpringService.pageFromBinary(itemSchema, response as Iterable<number>)))
    );

    return observable;
  }

  public postPage<R extends Message, M extends Message>(
    requestSchema: GenMessage<R>,
    itemSchema: GenMessage<M>,
    microserviceName: string | MicroserviceNames,
    url: string,
    body: R,
    options: RequestOptions = {},
    gateway?: boolean,
    skipNotification?: boolean
  ): Observable<PageResponseWithType<M>> {
    let headers = (options.headers as HttpHeaders) ?? new HttpHeaders();

    headers = ProtobufSpringService.modifyHttpHeaders(headers);

    const _options: RequestOptions = Object.assign({}, options, { headers });

    const observable = iif(
      () => ProtobufSpringService.LOCAL_ENVIRONMENT,
      this.springRestService
        .post(
          microserviceName,
          url,
          ProtobufSpringService.toJson(requestSchema, body),
          _options,
          gateway,
          skipNotification,
          'json'
        )
        .pipe(map((response) => ProtobufSpringService.pageFromJson(itemSchema, response as JsonValue))),
      this.springRestService
        .post(
          microserviceName,
          url,
          new Blob([ProtobufSpringService.toBinary(requestSchema, body)]),
          _options,
          gateway,
          skipNotification,
          'arraybuffer'
        )
        .pipe(
          tap((response) => console.log('response', response)),
          map((response) => ProtobufSpringService.pageFromBinary(itemSchema, response as Iterable<number>))
        )
    );

    return observable;
  }
}
