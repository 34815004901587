import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RestService } from '../../../service/rest/rest.service';
import { SubscriptionVO } from 'app/vo/subscription-vo';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { SubscriptionDataVO } from '../model/vo/subscription-data';
import { ROLE_TYPE } from '../../../vo/roles/roles';
import { CouponResponse, CouponResponseStatus } from '../subscription/model/coupon-response';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../notification/notification.service';
import { WixUrlItem } from '../subscription/model/wix-url-item';
import { SubscriptionPlans } from '../subscription/model/subscirption-plan';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  public static fakeToken =
    'ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnpkV0lpT2lJeE1qTTBOVFkzT0Rrd0lpd2libUZ0WlNJNklrcHZhRzRnUkc5bElpd2lhV0YwSWpveE5URTJNak01TURJeWZRLlNmbEt4d1JKU01lS0tGMlFUNGZ3cE1lSmYzNlBPazZ5SlZfYWRRc3N3NWM=';

  public static VALIDATE_COUPON_CODE = 'CouponService/validateCouponCode';
  public static GET_TOKEN_COUPON_CODE = 'CouponService/validateCouponByToken';

  constructor(
    private restService: RestService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  public getSubscriptions(ecomId: number, role?: ROLE_TYPE): Observable<SubscriptionVO> {
    return this.restService
      .get<SubscriptionVO>(`PlanService/getDataForPlan?ecomId=${ecomId}${role ? '&role=' + role : ''}`)
      .pipe(map((response) => response.getFirstData()));
  }

  public getDiscountCouponByToken(ecom: EcomVO | number, token): Observable<CouponResponse> {
    return this.restService.get<CouponResponse>(SubscriptionService.GET_TOKEN_COUPON_CODE, { ecom, token }).pipe(
      map((response) => response.getFirstData()),
      tap((response) => {
        if (!response.coupon) {
          const error = this.getCouponErrorText(response.status);
          if (!!error) {
            this.notificationService.error(error);
          }
        }
      })
    );
  }

  public validateCouponeCode(ecom: number, plan: number, couponCode: string): Observable<CouponResponse> {
    return this.restService
      .post(SubscriptionService.VALIDATE_COUPON_CODE, { ecom, plan, couponCode })
      .pipe(map((response) => response.getFirstData() as CouponResponse));
  }

  public getPlans(): Observable<SubscriptionPlans> {
    return this.restService.get('ShopifyPlanService/getPlans').pipe(
      map((response) => {
        const data = response.getFirstData();
        return data as SubscriptionPlans;
      }),
      map((data) => {
        return {
          ...data,
          marketplace: data.marketplace.sort((a, b) => a.productMonthlyPrice - b.productMonthlyPrice),
        };
      })
    );
  }

  public subscribeToShopify(restData: SubscriptionDataVO): Observable<string> {
    return this.restService
      .post(`ShopifyPlanService/createSubscription`, restData)
      .pipe(map((response) => response.getFirstData()));
  }

  public getWixCheckoutUrls(ecomId): Observable<WixUrlItem[]> {
    return this.restService
      .get(`PlanService/getWixCheckoutUrls?ecomId=${ecomId}`)
      .pipe(map((response) => response.getData()));
  }

  public cancelWoocommerce(restData): Observable<any> {
    return this.restService
      .post(`WoocommercePlanService/cancelSubscription`, restData)
      .pipe(map((response) => response.getFirstData()));
  }

  // public saveNummuspaySubscription(restData): Observable<any> {
  //   return this.restService
  //     .post(`PaymentService/saveNummuspayCharge`, restData)
  //     .pipe(map((response) => response.getFirstData()));
  // }

  public saveBraintreeSubscription(restData): Observable<any> {
    return this.restService
      .post(`PaymentService/saveBraintreeSubscription`, restData)
      .pipe(map((response) => (response.getData() ? response.getFirstData() : null)));
  }

  public getCouponErrorText(status: CouponResponseStatus): string {
    switch (status) {
      case 'UNAVAILABLE_ON_ECOMTYPE':
        return this.translateService.instant('BILLING.SUBSCRIPTION.COUPON.UNAVAILABLE_ON_ECOMTYPE');
      case 'UNAVAILABLE_ON_PLAN':
        return this.translateService.instant('BILLING.SUBSCRIPTION.COUPON.UNAVAILABLE_ON_ECOMTYPE');
      case 'ALREADY_USED':
        return this.translateService.instant('BILLING.SUBSCRIPTION.COUPON.ALREADY_USED');
      case 'CANT_USE_COUPON':
        return this.translateService.instant('BILLING.SUBSCRIPTION.COUPON.CANT_USE_COUPON');
      case 'UNAVAILABLE':
      default:
        return this.translateService.instant('BILLING.SUBSCRIPTION.COUPON.UNAVAILABLE');
    }
  }

  public getPlanDataByDomain(ecomId: number): Observable<any> {
    return this.restService.get(`ShopifyPlanService/getPlanByDomain?ecomId=${ecomId}`).pipe(
      map((response) => response.getFirstData()),
      map((data) => {
        return {
          ...data,
          marketplace: data.marketplace.sort((a, b) => a.productMonthlyPrice - b.productMonthlyPrice),
        };
      })
    );
  }

  public subscribeToWoocommerce(restData: SubscriptionDataVO): Observable<any> {
    return this.restService
      .post(`WoocommercePlanService/createSubscription`, restData)
      .pipe(map((response) => response.getFirstData()));
  }
}
