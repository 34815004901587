import { Injectable } from '@angular/core';
import { EcomService, EcomVO } from './ecom.service';
import { BehaviorSubject, distinctUntilChanged, Observable, take } from 'rxjs';
import { T } from '@angular/cdk/keycodes';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { getCurrentUserIdSelector } from 'app/store/user/user.selector';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';

@Injectable({
  providedIn: 'root',
})
export class AllDomainSubscriptionService {
  private _domains: BehaviorSubject<EcomVO[]> = new BehaviorSubject<EcomVO[]>(null);
  domains = this._domains.asObservable();

  constructor(private ecomService: EcomService, private store: Store<AppState>) {
    this.store
      .select(getCurrentUserIdSelector)
      .pipe(omitNullOrUndefined(), distinctUntilChanged())
      .subscribe((userId) => {
        if (userId) {
          this.updateDomains();
        } else {
          this.clearDomains();
        }
      });
  }

  private clearDomains(): void {
    this._domains.next(null);
  }

  private getDomains(): Observable<EcomVO[]> {
    return this.ecomService.getDomains();
  }

  updateDomains(): void {
    this.getDomains().subscribe((domains) => this._domains.next(domains));
  }
}
