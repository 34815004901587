import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { hasScopeSelector, isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { getCurrentUserSelector } from 'app/store/user/user.selector';
import { switchMapWith } from 'app/utils/operator/switch-map-with';
import { environment } from 'environments/environment';
import posthog from 'posthog-js';
import { SCOPES } from '../permission/scopes';
import { isEqual } from 'lodash';
import { distinctUntilChanged, take } from 'rxjs';
import { AllDomainSubscriptionService } from '../ecom/all-domain-subscription.service';
import { User } from '../user/user';
import { omitNullOrUndefinedOrEmpty } from 'app/utils/operator/omit-null-or-undefined-or-empty';
import { EcomVO } from '../ecom/ecom.service';

@Injectable({
  providedIn: 'root',
})
export class PostHogService {
  constructor(
    private ngZone: NgZone,
    private router: Router,
    private store: Store<AppState>,
    private allDomainSubscriptionService: AllDomainSubscriptionService
  ) {}

  init(): void {
    this.ngZone.runOutsideAngular(() => {
      posthog.init(environment.posthogApiKey, {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
        disable_session_recording: true,
        capture_pageview: false,
        capture_pageleave: true,
      });
      this.subscribeToAuthState();
      this.subscribeToDomains();
    });
  }

  trackPageView(): void {
    posthog.capture('$pageview');
  }

  startSessionRecording(): void {
    posthog.startSessionRecording();
  }

  stopSessionRecording(): void {
    posthog.stopSessionRecording();
  }

  private identifyUser(user: User): void {
    posthog.identify(user.id.toString(), { email: user.email, name: user.userName, user_id: user.id });
  }

  private subscribeToDomains(): void {
    this.allDomainSubscriptionService.domains.pipe(omitNullOrUndefinedOrEmpty()).subscribe((domains) => {
      if (!!domains?.length) {
        this.handleAnySubscriptions(domains);
        this.handleSubscriptions(domains);
        this.removeShopifyProperties();
      }
    });
  }

  private removeShopifyProperties(): void {
    posthog.capture('$unset', {
      $unset: [
        'shopify_has_any_subscription',
        'shopify_datafeed_has_subscription',
        'shopify_datafeed_plan_id',
        'shopify_datafeed_plan_name',
        'shopify_marketplace_has_subscription',
        'shopify_marketplace_plan_id',
        'shopify_marketplace_plan_name',
      ],
    });
  }

  private handleSubscriptions(domains: EcomVO[]): void {
    if (!domains?.length) {
      return;
    }
    const obj: { [key: string]: unknown } = {};
    domains.slice(0, 3).forEach((domain, index) => {
      obj[`Ecom_${index}_type`] = domain.ecomType;
      obj[
        `Ecom_${index}_plan`
      ] = `${domain.subscriptions.df.productDescription} ${domain.subscriptions.rmp.productDescription}`;
      obj[`Ecom_${index}_has_subscription`] =
        domain.subscriptions.rmp.planId === 120 && domain.subscriptions.df.planId === 100;
      obj[`Ecom_${index}_plan_type`] = domain.cycle;
    });
    posthog.setPersonProperties(obj);
  }

  private handleAnySubscriptions(domains: EcomVO[]): void {
    const hasAnySubscription = domains
      .slice(0, 3)
      .some((domain) => domain.subscriptions.rmp.planId === 120 && domain.subscriptions.df.planId === 100);
    posthog.setPersonProperties({
      has_any_subscription: hasAnySubscription,
    });
  }

  private subscribeToAuthState(): void {
    this.store
      .select(getCurrentUserSelector)
      .pipe(
        switchMapWith((user) => this.store.select(hasScopeSelector(SCOPES.ADMIN)).pipe(take(1))),
        distinctUntilChanged(isEqual)
      )
      .subscribe(([user, isAdmin]) => {
        if (user && !isAdmin) {
          this.identifyUser(user);
          this.startSessionRecording();
        } else {
          this.stopSessionRecording();
          posthog.reset();
        }
      });
  }
}
