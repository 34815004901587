import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { User } from 'app/service/user/user';
import { UserService } from 'app/service/user/user.service';
import { delay, mergeMap, Observable, of, retryWhen } from 'rxjs';
import { concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { isAuthenticatedSelector } from '../authentication/authentication.selector';
import { UserEcomsChainToSubscriptionFetch } from '../ecom/ecom.actions';
import { GetPreferencesStartAction, GetPreferenceTypesStartAction } from '../preferences/preferences.action';
import { GetCatalogsStartAction } from '../rcatalogs/rcatalogs.action';
import {
  GetLocationByIp,
  InitUserEffetct,
  SetActualRole,
  UpdateUserSettingsStartAction,
  UpdateUserSettingsSuccessAction,
  UpdateUserStart,
  UpdateUserSuccess,
  UserActionTypes,
  UserDetailsChainedToEcomFetch,
  UserDetailsChainedToEcomFetchSuccess,
  UserDetailsSuccess,
} from './user.actions';
import {
  getCurrentUserIdSelector,
  getCurrentUserSelector,
  getCurrentUserSettingsSelector,
  getUserRolesSelector,
} from './user.selector';
import { GetInstalledSynceeAppsStartAction } from '../syncee-apps/syncee-apps.actions';
import { GetFavoriteProductsIdsStartAction, GetFollowedSupplierIdsStartAction } from '../favorites/favorites.actions';
import {
  GuestLanguageChangeSuccessAction,
  LanguageChangeStart,
  UserLanguageChangeSuccessAction,
} from '../language/language.actions';
import { TranslateService } from '@ngx-translate/core';
import { FetchSelectedCurrenciesWithoutChainStart } from '../currency/currency.action';
import { CurrencyService } from '../../service/currency-service/currency.service';
import { SnippetChainStart } from '../snippet/snippet.actions';
import { GetRetailerToCatalogStartAction } from '../retailer-to-catalog/retailer-to-catalog.actions';
import { GettingStartedChainStartAction } from '../getting-started/getting-started.actions';
import { getRoles } from './user.reducer';
import { uniqBy } from 'lodash';
import { UserGatewayService } from '../../service/user/user-gateway.service';
import { GetTasksStartAction } from '../tasks/tasks.actions';
import { RolesEnum } from '../../vo/roles/roles';
import { ProductSearchService } from '../../service/product-search/product-search.service';
import { IntercomService } from '../../service/intercom/intercom.service';
import { GetUserUsageStartAction } from '../usage/usage.actions';
import * as Sentry from '@sentry/browser';
import { BootstrapService } from '../../service/bootstrap/bootstrap.service';

@Injectable()
export class UserEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private userGatewayService: UserGatewayService,
    private productSearchService: ProductSearchService,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private intercomService: IntercomService,
    private bootstrapService: BootstrapService
  ) {}

  ngrxOnInitEffects(): Action {
    return new InitUserEffetct();
  }

  UserStoreInit: Observable<
    UserDetailsChainedToEcomFetch | FetchSelectedCurrenciesWithoutChainStart | GuestLanguageChangeSuccessAction
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.INIT_USER),
      switchMap(() => {
        return this.store.select(isAuthenticatedSelector).pipe(
          map((isAuthenticated) => {
            if (isAuthenticated) {
              return new UserDetailsChainedToEcomFetch();
            } else {
              this.store.dispatch(
                new FetchSelectedCurrenciesWithoutChainStart({
                  selectedCurrency: CurrencyService.getCurrency(),
                  withNotification: false,
                })
              );
              this.intercomService.initGuestIntercom();
              return new GuestLanguageChangeSuccessAction({ countryCode: this.getLanguage(), withNotification: false });
            }
          })
        );
      })
    )
  );

  GetLocationByIdStart: Observable<GetLocationByIp> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.INIT_USER),
      switchMap(() => this.productSearchService.getUserLocationByIp()),
      map((result) => new GetLocationByIp(result?.countryCode ?? 'US'))
    )
  );

  UserDetails: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.USER_DETAILS),
      switchMap(() => this.getUserDetails().pipe(map((user) => new UserDetailsSuccess(user))))
    )
  );

  UpdateUser: Observable<UpdateUserSuccess> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UPDATE_USER_START),
      switchMap((action: UpdateUserStart) =>
        this.store.select(getCurrentUserIdSelector).pipe(
          filter((userId) => !!userId),
          take(1),
          concatMap((userId) => this.userGatewayService.patchUser(userId, action.payload.user, action.payload.options)),
          concatMap(() => this.getUserDetails())
        )
      ),
      map((user) => new UpdateUserSuccess(user))
    )
  );

  UpdateUserSuccess: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.UPDATE_USER_SUCCESS),
        tap((action: UpdateUserSuccess) => this.userUpdateSuccessSideEffects(action.payload)),
        map(() => {})
      ),
    { dispatch: false }
  );

  UserDetailsChainToEcomFetch: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.USER_DETAILS_CHAIN_TO_ECOM_FETCH),
      switchMap(() => this.getUserDetails().pipe(map((user) => new UserDetailsChainedToEcomFetchSuccess(user))))
    )
  );

  UserDetailsForceChainToEcomFetch: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.USER_DETAILS_FORCE_CHAIN_TO_ECOM_FETCH),
      switchMap(() =>
        this.userService.getUserDetailsForce().pipe(map((user) => new UserDetailsChainedToEcomFetchSuccess(user)))
      )
    )
  );

  UserBootstrapDataByUserRole: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.USER_DETAILS_SUCCESS, UserActionTypes.USER_DETAILS_CHAIN_TO_ECOM_FETCH_SUCCESS),
      map((action: UserDetailsSuccess) => action.payload),
      switchMap((user: User) => this._returnBootstrapActionsByRole(user))
    )
  );

  UserDetailsChainToEcomFetchSuccessEffect: Observable<UserEcomsChainToSubscriptionFetch> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.USER_DETAILS_CHAIN_TO_ECOM_FETCH_SUCCESS),
      switchMap(() =>
        this.store.select(getUserRolesSelector).pipe(
          filter((roles) => roles !== undefined),
          take(1),
          tap((roles) => this.intercomService.initIntercomWithUserData(roles)),
          map((roles) => {
            return new UserEcomsChainToSubscriptionFetch({ roles });
          })
        )
      )
    )
  );

  actualRoleChangeEffect: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.SET_ACTUAL_ROLE),
        tap((value: SetActualRole) => localStorage.setItem('actualRole', JSON.stringify(value.payload))),
        map(() => {})
      ),
    { dispatch: false }
  );

  updateUserSettingsStart = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UPDATE_USER_SETTINGS_START),
      map((action: UpdateUserSettingsStartAction) => action.payload),
      switchMap((payload) =>
        this.store.select(getCurrentUserSettingsSelector).pipe(
          take(1),
          switchMap((currentSettings) => this.userGatewayService.patchUserSettings(currentSettings.id, payload))
        )
      ),
      map((settings) => new UpdateUserSettingsSuccessAction(settings))
    )
  );

  getUserDetails(): Observable<User> {
    return this.store.select(getCurrentUserSelector).pipe(
      take(1),
      switchMap((user) => {
        if (user) {
          return this.userGatewayService.getUser().pipe(
            retryWhen((errors) => {
              return errors.pipe(
                mergeMap((response) => {
                  if (response.errorCode === '404') {
                    return of(response).pipe(delay(2000), take(5));
                  }
                  throw { error: response };
                })
              );
            }),
            map((response) => response),
            tap(() => this.bootstrapService.initAfterUserLoggedIn())
          );
        } else {
          return this.userService.getUserDetailsForce();
        }
      })
      // tap((user) => Sentry.setUser({ email: user.email, id: user.id, username: user.userName }))
    );
  }

  private _returnBootstrapActionsByRole(user: User): Action[] {
    const actions = this.getDefaultActions(user);
    const roles = getRoles();

    if (roles.includes(RolesEnum.ADMIN)) {
      actions.push(...this.getAdminActions());
    } else {
      if (roles.includes(RolesEnum.RETAILER)) {
        actions.push(...this.getRetailerActions());
      }
      if (roles.includes(RolesEnum.SUPPLIER)) {
        actions.push(...this.getSupplierActions());
      }
    }

    return uniqBy(actions, (action) => action.type);
  }

  private getDefaultActions(user: User): Action[] {
    return [
      new GetPreferenceTypesStartAction(),
      new GetPreferencesStartAction(user.id),
      new GetCatalogsStartAction(),
      new GetInstalledSynceeAppsStartAction(),
      new GetFavoriteProductsIdsStartAction(user.id),
      new GetFollowedSupplierIdsStartAction(user.id),
      new UserLanguageChangeSuccessAction({
        countryCode: user.language,
        withNotification: false,
      }),
      new SnippetChainStart(),
      new GetRetailerToCatalogStartAction(),
      new GetTasksStartAction(),
      new GetUserUsageStartAction(),
    ];
  }

  private userUpdateSuccessSideEffects(user: User): void {
    this.store.dispatch(
      new LanguageChangeStart({
        countryCode: user.language,
        withNotification: true,
      })
    );
  }

  private getAdminActions(): Action[] {
    return [];
  }

  private getRetailerActions(): Action[] {
    return [new GettingStartedChainStartAction()];
  }

  private getSupplierActions(): Action[] {
    return [new GettingStartedChainStartAction()];
  }

  private getLanguage(): string {
    return localStorage.getItem('selectedLanguage') || this.translateService.currentLang || 'en';
  }
}
