import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SubscriptionService } from '../../billing-new/service/subscription.service';
import { fuseAnimations } from '../../../../@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Constants, SYNCEE_PRODUCTS, SYNCEE_PRODUCTS_NAMES, COUPON_CODES_STATUS } from '../../../utils/Constants';
import { ConfirmationDialogsService } from '../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.service';
import { UserService } from '../../../service/user/user.service';
import { NotificationService } from '../../notification/notification.service';
import { Utils } from '../../../utils/utils';
import { BillingService } from '../../../service/billing/billing.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { PlanCompareDialogService } from './plan-compare-dialog/plan-compare-dialog.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { AddNewStoreComponent } from '../../../shared/components/add-new-store/add-new-store.component';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { User } from '../../../service/user/user';
import { TranslateService } from '@ngx-translate/core';
import { RestService } from '../../../service/rest/rest.service';
import { BillingInformationChangeDialogComponent } from '../billing-information-change-dialog/billing-information-change-dialog.component';
import { CardInfoVO } from '../../../vo/billing-vo';
import { EcomVO } from 'app/service/ecom/ecom.service';
import { CardFormDialogService } from '../../../service/card-form-dialog/card-form-dialog.service';
import { ConfirmationDialogComponent } from '../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';
import { CouponCodeDialogComponent } from '../../../shared/components/dialogs/coupon-code-dialog/coupon-code-dialog.component';
import { get, merge } from 'lodash';
import { CancelDialogComponent, CancelDialogResult } from '../../cancel-dialog/cancel-dialog.component';
import { UserGatewayService } from '../../../service/user/user-gateway.service';
import { getPlatformByType } from '../../../utils/platforms/platform-config';
import { EcomTypeEnum } from '../../../vo/enums/ecom-type-enum';
import { SubscriptionPlansVO } from '../../billing-new/model/vo/subscription-plans-v-o';
import { FaqItem } from '../../../vo/faq/faq-item';
import { MARKETPLACE_PLAN } from '../../../utils/plans';

@Component({
  selector: 'subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  selectedDomain: EcomVO;
  selectedCycleType = 0;
  currentCycleType = 0;
  plans = {
    df: null,
    rmp: null,
  };
  nextPlan = {
    df: new PlanVO(),
    rmp: new PlanVO(),
  };
  selectedPlans = {
    df: new PlanVO(),
    rmp: new PlanVO(),
  };
  synceeProducts = SYNCEE_PRODUCTS;
  synceeProductNames = SYNCEE_PRODUCTS_NAMES;

  ecomList: EcomVO[] = [];
  hasStore = false;
  isLoaded = false;
  subsLoaded = false;
  hasError = false;
  isAdmin = false;
  constants = Constants;
  isOnMobile: boolean;

  disableButton: boolean;

  // Private
  private _unsubscribeAll: Subject<void>;
  private today = new Date();
  private userDetails: User;

  public paymentProvider;
  wixURLs = [];
  isWixUninstalled = false;

  couponStatus = COUPON_CODES_STATUS;

  discountToken: string = null;
  coupon: any = null;
  private _showErrorTermsMessage = false;

  availabledCouponEcomTypes = ['shopify'];

  private _defaultSubParams: DefaultSubQParams[] = [];
  termsChecked = false;
  public planHasAnnual = {
    df: false,
    rmp: false,
  };

  public needMarketingBox = false;
  public BUSINESS_PLAN_ID = MARKETPLACE_PLAN.BUSINESS;
  faqs: FaqItem[] = [
    {
      title: 'BILLING.SUBSCRIPTION.FAQ.CHANGE_SUBSCRIPTION.QUESTION',
      text: 'BILLING.SUBSCRIPTION.FAQ.CHANGE_SUBSCRIPTION.ANSWER',
    },
    {
      title: 'BILLING.SUBSCRIPTION.FAQ.PAYMENT_METHODS.QUESTION',
      text: 'BILLING.SUBSCRIPTION.FAQ.PAYMENT_METHODS.ANSWER',
    },
    { title: 'BILLING.SUBSCRIPTION.FAQ.BILL.QUESTION', text: 'BILLING.SUBSCRIPTION.FAQ.BILL.ANSWER' },
    { title: 'BILLING.SUBSCRIPTION.FAQ.CANCEL.QUESTION', text: 'BILLING.SUBSCRIPTION.FAQ.CANCEL.ANSWER' },
    { title: 'BILLING.SUBSCRIPTION.FAQ.FREE_TRIAL.QUESTION', text: 'BILLING.SUBSCRIPTION.FAQ.FREE_TRIAL.ANSWER' },
    { title: 'BILLING.SUBSCRIPTION.FAQ.STARTER.QUESTION', text: 'BILLING.SUBSCRIPTION.FAQ.STARTER.ANSWER' },
    { title: 'BILLING.SUBSCRIPTION.FAQ.SECURE.QUESTION', text: 'BILLING.SUBSCRIPTION.FAQ.SECURE.ANSWER' },
    { title: 'BILLING.SUBSCRIPTION.FAQ.COST.QUESTION', text: 'BILLING.SUBSCRIPTION.FAQ.COST.ANSWER' },
    { title: 'BILLING.SUBSCRIPTION.FAQ.PLATFORMS.QUESTION', text: 'BILLING.SUBSCRIPTION.FAQ.PLATFORMS.ANSWER' },
    { title: 'BILLING.SUBSCRIPTION.FAQ.SUPPORT.QUESTION', text: 'BILLING.SUBSCRIPTION.FAQ.SUPPORT.ANSWER' },
    { title: 'BILLING.SUBSCRIPTION.FAQ.DIFFERENCE.QUESTION', text: 'BILLING.SUBSCRIPTION.FAQ.DIFFERENCE.ANSWER' },
  ];
  isTrialAvailable = false;
  isTrialActive = false;
  churnedPlan: string;

  constructor(
    private _subscriptionService: SubscriptionService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cardFormDialogService: CardFormDialogService,
    private router: Router,
    private confirmDialog: ConfirmationDialogsService,
    private userService: UserService,
    private notificationService: NotificationService,
    private billingService: BillingService,
    private planCompareDialogService: PlanCompareDialogService,
    private marketplaceEcomService: MarketplaceEcomService,
    private _translateService: TranslateService,
    private restService: RestService,
    private translateService: TranslateService,
    private notService: NotificationService,
    private datePipe: DatePipe,
    private userGatewayService: UserGatewayService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.isAdmin = !Utils.isNullOrUndefined(localStorage.getItem('userId'));
  }

  ngOnInit(): void {
    this.handleQueryParams();
    // ha épp kérjük lefele a service-ben az ecom listát, akkor ne induljon el mégegyszer ugyanaz a lekérés
    if (this.marketplaceEcomService.isInitialized) {
      // befrissítjük a listát
      this.marketplaceEcomService.getOnlyDomains(2).subscribe((res) => {
        this.ecomList = res;
        this.marketplaceEcomService.ecomList = res;
        this.subscribeToEcomInit();
        this.initSubscriptionData();
      });
    } else {
      this.subscribeToEcomInit();
    }

    this.isOnMobile = window.screen.width <= 1200;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initTokenCoupon(ecomId): void {
    this._subscriptionService.getDiscountCouponByToken(ecomId, this.discountToken).subscribe((resp) => {
      this.coupon = this.handleTokenCouponResp(resp);
    });
  }

  getTokenCouponDiscountStr(): string {
    if (!this.coupon) {
      return;
    }
    const couponData = this.coupon.data[Object.keys(this.coupon.data)[0] || null];
    if (!couponData) {
      return;
    }
    switch (couponData.type) {
      case 'fixed':
        return couponData.amount + ' USD';
      case 'percentage':
        return couponData.amount + '%';
    }
  }

  getTokenCouponUntilDate(): any {
    if (!this.coupon) {
      return;
    }
    const couponData = this.coupon.data[Object.keys(this.coupon.data)[0] || null];
    if (!couponData) {
      return;
    }
    return couponData.availableUntil;
  }

  handleTokenCouponResp(resp): any {
    if (!resp.coupon) {
      switch (resp.status) {
        case 'UNAVAILABLE':
          this.notService.error(this.translateService.instant('BILLING.SUBSCRIPTION.COUPON.UNAVAILABLE'));
          break;
        case 'UNAVAILABLE_ON_ECOMTYPE':
          this.notService.error(this.translateService.instant('BILLING.SUBSCRIPTION.COUPON.UNAVAILABLE_ON_ECOMTYPE'));
          break;
        case 'UNAVAILABLE_ON_PLAN':
          this.notService.error(this.translateService.instant('BILLING.SUBSCRIPTION.COUPON.UNAVAILABLE_ON_ECOMTYPE'));
          break;
        case 'ALREADY_USED':
          this.notService.error(this.translateService.instant('BILLING.SUBSCRIPTION.COUPON.ALREADY_USED'));
          break;
        case 'CANT_USE_COUPON':
          this.notService.error(this.translateService.instant('BILLING.SUBSCRIPTION.COUPON.CANT_USE_COUPON'));
          break;
      }
      return null;
    }
    const ret = { status: resp.status, data: null };
    ret.data = {};
    resp.coupon.plans.forEach((planId) => {
      ret.data[planId] = resp.coupon;
    });
    ret[status] = resp.status;
    return ret;
  }

  private subscribeToEcomInit(): void {
    this.marketplaceEcomService.initialized.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.ecomList = this.marketplaceEcomService.ecomList;
      this.initSubscriptionData();
    });
  }

  public getSubscriptionList(): Observable<SubscriptionPlansVO> {
    return this.restService.get(`ShopifyPlanService/getPlans`).pipe(map((response) => response.getFirstData()));
  }

  private initSubscriptionData(): void {
    this.isLoaded = false;
    forkJoin([this.getUserDetails(), this.getSubscriptionList()]).subscribe(
      (results) => {
        this.userDetails = results[0];
        this.plans.df = results[1].dataFeed.filter((plan) => plan.planStatus === 1);
        this.plans.rmp = results[1].marketplace.filter((plan) => plan.planStatus === 1);
        this.getRetailerStores();
      },
      (error) => {
        this.isLoaded = true;
        this.hasError = true;
      }
    );
  }

  private getNotActivePlans(): { rmp: PlanVO; df: PlanVO } {
    const plans: { rmp: PlanVO; df: PlanVO } = { rmp: null, df: null };
    if (this.selectedDomain.subscriptions.rmp.planStatus === 0) {
      plans.rmp = this.mapSubscriptionPlanToPlanVO(this.selectedDomain.subscriptions.rmp);
    }
    if (this.selectedDomain.subscriptions.df.planStatus === 0) {
      plans.df = this.mapSubscriptionPlanToPlanVO(this.selectedDomain.subscriptions.df);
    }
    return plans;
  }

  private mapSubscriptionPlanToPlanVO(plan: any): PlanVO {
    const {
      planId,
      planName,
      price,
      planStatus,
      planType,
      productDescription,
      productMonthlyPrice,
      productYearlyPrice,
      ...planData
    } = plan;
    return {
      planStatus,
      productId: planId,
      productName: planName,
      planType,
      productDescription,
      productMonthlyPrice,
      productYearlyPrice,
      planData,
    };
  }

  private getRetailerStores(): void {
    if (!Utils.isNullOrUndefined(this.ecomList) && this.ecomList.length > 0) {
      this.hasStore = true;
      if (localStorage.getItem('selectedRetailerEcomId')) {
        const ecom = this.ecomList.find(
          (element) => element.id.toString() === localStorage.getItem('selectedRetailerEcomId')
        );
        if (!Utils.isNullOrUndefined(ecom)) {
          this.selectedDomain = ecom;
        } else {
          this.selectedDomain = this.ecomList[0];
        }
      } else {
        this.selectedDomain = this.ecomList[0];
      }
      this.isLoaded = true;
      if (this.discountToken) {
        this.initTokenCoupon(this.selectedDomain.id);
      }
      this.getSubscriptionsDetails();
    } else {
      this.hasStore = false;
      this.isLoaded = true;
    }
  }
  private getSubscriptionsDetails(): void {
    this.marketplaceEcomService.getDomainByEcomId(this.selectedDomain.id, 2).subscribe(
      (res) => {
        const index = this.ecomList.findIndex((elem) => elem.id == this.selectedDomain.id);
        if (res) {
          Object.assign(this.selectedDomain, res);
          if (index > -1) {
            Object.assign(this.ecomList[index], res);
          }
          const notActivePlans = this.getNotActivePlans();
          if (
            !Utils.isNullOrUndefined(notActivePlans.rmp) &&
            !this.plans.rmp.some((plan) => plan.productId === notActivePlans.rmp.productId)
          ) {
            this.plans.rmp.push(notActivePlans.rmp);
          }
          if (
            !Utils.isNullOrUndefined(notActivePlans.df) &&
            !this.plans.df.some((plan) => plan.productId === notActivePlans.df.productId)
          ) {
            this.plans.df.push(notActivePlans.df);
          }
          this.initPaymentProvider();
          this.setSelectedPlans();
          this.setPlansAvailable();
          this.isTrialAvailable = this.marketplaceEcomService.isFreeTrialAvailable(res);
          this.isTrialActive =
            !Utils.isNullOrUndefined(res.subscriptions.rmp.freeTrialUntil) &&
            Date.parse(res.subscriptions.rmp.freeTrialUntil) > Date.now() &&
            (res.subscriptions.rmp.planId !== 120 || res.subscriptions.df.planId !== 100);
          this.setNeedMarketingBox();
          this.setCycle();
          this.handleDefaultPlans();
          this.getNextPlan();
          this.setChurnedPlans(this.selectedDomain);
          if (this.selectedDomain.ecomType === 'wix') {
            this.marketplaceEcomService.getWixCheckoutUrls(this.selectedDomain.id).subscribe(
              (urls) => {
                if (urls) {
                  this.wixURLs = urls;
                  this.subsLoaded = true;
                } else {
                  this.isLoaded = true;
                  this.hasError = true;
                }
              },
              (error) => {
                this.subsLoaded = true;
                this.isWixUninstalled = true;
              }
            );
          } else {
            this.subsLoaded = true;
          }
        }
      },
      (error) => {
        this.isLoaded = true;
        this.hasError = true;
      }
    );
  }

  private initPaymentProvider(): void {
    switch (this.selectedDomain.paymentProvider) {
      case 'shopify':
        this.paymentProvider = null;
        break;
      default:
        this.paymentProvider = new BraintreeProvider(this.selectedDomain, this.userDetails);
        this.checkForCard();
    }
  }

  private setCycle(): void {
    this.currentCycleType = this.selectedDomain.cycle.toLowerCase() === 'monthly' ? 0 : 1;
    this.selectedCycleType = this.selectedDomain.cycle.toLowerCase() === 'monthly' ? 0 : 1;
  }

  private getUserDetails(): Observable<User> {
    if (Utils.isNullOrUndefined(localStorage.userId)) {
      this.isAdmin = false;
      return new Observable<any>((observe) => {
        this.userService
          .getUserDetails()
          .pipe(take(1))
          .subscribe(
            (user) => {
              observe.next(user);
              observe.complete();
            },
            (error) => {
              this.isLoaded = true;
              this.hasError = true;
              observe.error(error);
            }
          );
      });
    } else {
      this.isAdmin = true;
      return new Observable<any>((observe) => {
        this.userGatewayService
          .getSelectedUser()
          .pipe(take(1))
          .subscribe(
            (response) => {
              observe.next(response);
              observe.complete();
            },
            (error) => {
              this.isLoaded = true;
              this.hasError = true;
              observe.error(error);
            }
          );
      });
    }
  }

  private setSelectedPlans(): void {
    this.selectedPlans.df = this.plans.df.find(
      (elem) => elem.productId === this.selectedDomain.subscriptions.df.planId
    );
    this.selectedPlans.rmp = this.plans.rmp.find(
      (elem) => elem.productId === this.selectedDomain.subscriptions.rmp.planId
    );
  }

  private setPlansAvailable(): void {
    this.planHasAnnual.df = this.selectedPlans.df.planData.hasAnnual;
    this.planHasAnnual.rmp = this.selectedPlans.rmp.planData.hasAnnual;
  }

  private setNeedMarketingBox(): void {
    this.needMarketingBox = this.selectedDomain.subscriptions.rmp.planId < this.BUSINESS_PLAN_ID;
  }

  public handleMarketingBoxClicked(): void {
    this.selectedPlans.rmp = this.plans.rmp.find((elem) => elem.productId === this.BUSINESS_PLAN_ID);
    this.selectedCycleType = 1;
    this.setPlansAvailable();
  }

  public storeSelectionChange(ecomID): void {
    if (this.selectedDomain.id !== ecomID) {
      this.subsLoaded = false;
      this.selectedDomain = this.ecomList.find((element) => element.id === ecomID);
      if (!Utils.isNullOrUndefined(this.discountToken) && this.isCouponAvailableForEcom(this.selectedDomain.ecomType)) {
        this.initTokenCoupon(ecomID);
      } else {
        this.coupon = null;
      }
      if (this.selectedDomain.ecomType === 'wix') {
        this.wixURLs = [];
        this.isWixUninstalled = false;
      }
      this.getSubscriptionsDetails();
    }
  }

  isCouponAvailableForEcom(ecomType): boolean {
    return this.availabledCouponEcomTypes.includes(ecomType);
  }

  public planSelectionChange(selectedPlanId, product): void {
    const plan: PlanVO = this.plans[product].find((element) => element.productId === selectedPlanId);
    this.selectedPlans[product] = plan;
    this.planHasAnnual[product] = plan.planData.hasAnnual;
  }

  public upgradeToPlan(selectedPlan, product): void {
    this.selectedPlans[product] = selectedPlan;
  }

  public countTotalPrice(): string {
    if (!Utils.isNullOrUndefined(this.selectedDomain)) {
      if (this.selectedCycleType === 0) {
        const rmpFinalMPrice = this.getCalculatedPrice(
          'rmp',
          this.selectedPlans.rmp.productId,
          this.selectedPlans.rmp.productMonthlyPrice
        );
        const dfFinalMPrice = this.getCalculatedPrice(
          'df',
          this.selectedPlans.df.productId,
          this.selectedPlans.df.productMonthlyPrice
        );
        return Utils.formatMoney(rmpFinalMPrice + dfFinalMPrice);
      } else {
        const rmpFinalYPrice = this.getCalculatedPrice(
          'rmp',
          this.selectedPlans.rmp.productId,
          this.selectedPlans.rmp.productYearlyPrice
        );
        const dfFinalYPrice = this.getCalculatedPrice(
          'df',
          this.selectedPlans.df.productId,
          this.selectedPlans.df.productYearlyPrice
        );
        return Utils.formatMoney(rmpFinalYPrice + dfFinalYPrice);
      }
    } else {
      return null;
    }
  }

  public cycleChanged(value): void {
    this.selectedCycleType = value.checked ? 1 : 0;
  }

  public getNextPlan(): void {
    this.plans.rmp.forEach((elem, index) => {
      if (elem.productId === this.selectedPlans.rmp.productId) {
        this.nextPlan.rmp = this.plans.rmp[index + 1];
      }
    });
    this.plans.df.forEach((elem, index) => {
      if (elem.productId === this.selectedPlans.df.productId) {
        this.nextPlan.df = this.plans.df[index + 1];
      }
    });
  }

  public formatMoney(x): string {
    return Utils.formatMoney(x);
  }

  public getDate(subDate): any {
    const d = Number(this.datePipe.transform(subDate, 'dd'));
    if (this._translateService.currentLang.toLowerCase() === 'en') {
      if (d > 3 && d < 21) {
        return d + 'th';
      }
      switch (d % 10) {
        case 1:
          return d + 'st';
        case 2:
          return d + 'nd';
        case 3:
          return d + 'rd';
        default:
          return d + 'th';
      }
    } else {
      return d + '.';
    }
  }

  public checkSubscriptionUntil(date: string): boolean {
    if (!Utils.isNullOrUndefined(date)) {
      const convertDate = new Date(date);
      return convertDate > this.today;
    } else {
      return false;
    }
  }

  public openCompareDialog(type): void {
    if (this.selectedDomain.subscriptions[type].planId === this.selectedPlans[type].productId) {
      this.planCompareDialogService
        .openDialog(
          this.selectedPlans[type],
          this.nextPlan[type],
          this.nextPlan[type]
            ? this.selectedPlans[type].planData.managedProductsLimit < this.nextPlan[type].planData.managedProductsLimit
            : false,
          type,
          true
        )
        .subscribe((result) => {
          if (result) {
            this.selectedPlans[type] = result;
          }
        });
    } else {
      const plainPlan = this.plans[type].find(
        (elem) => elem.productId == this.selectedDomain.subscriptions[type].planId
      );
      this.planCompareDialogService
        .openDialog(
          plainPlan,
          this.selectedPlans[type],
          plainPlan.planData.managedProductsLimit < this.selectedPlans[type].planData.managedProductsLimit,
          type,
          false
        )
        .subscribe((result) => {
          if (result) {
            this.selectedPlans[type] = result;
          }
        });
    }
  }

  // Check if plan selection changed
  private checkForSubChange(): boolean {
    return (
      (Utils.isNullOrUndefined(this.selectedPlans.rmp) ||
        this.selectedPlans.rmp.productId === this.selectedDomain.subscriptions.rmp.planId) &&
      (Utils.isNullOrUndefined(this.selectedPlans.df) ||
        this.selectedPlans.df.productId === this.selectedDomain.subscriptions.df.planId) &&
      this.currentCycleType === this.selectedCycleType
    );
  }

  public addnewstore(): void {
    this.dialog.open(AddNewStoreComponent, {
      width: '52vw',
      data: { role: 2 },
    });
  }

  private showErrorMessageForTermsAndConditions(show: boolean): void {
    this._showErrorTermsMessage = show;
  }

  public handleTermsCheckboxChange(value: boolean): void {
    this.termsChecked = value;
    if (value) {
      this.showErrorMessageForTermsAndConditions(false);
    }
  }

  public handleSubscriptionButtonClicked(): void {
    if (this.termsChecked) {
      this.subscribeToSyncee();
    } else {
      this.showErrorMessageForTermsAndConditions(true);
    }
  }

  public subscribeToSyncee(): void {
    if (this.isAdmin) {
      this.confirmDialog
        .confirmWithoutCancel('STAP!', 'You have no permission to change the subscription!')
        .subscribe();
    } else {
      if (
        this.checkForSubChange() &&
        !(this.selectedDomain.subscriptions.df.churnDate || this.selectedDomain.subscriptions.rmp.churnDate) &&
        this.checkSubscriptionUntil(this.selectedDomain.subscribed)
      ) {
        this.showUnchangedDialog();
        return;
      }
      if (
        (this.isOnlyMarketplace(this.selectedDomain.ecomType) ||
          (this.selectedDomain.subscriptions.df.churnDate && this.selectedPlans.df.productId === 100)) &&
        this.selectedDomain.subscriptions.rmp.churnDate &&
        this.selectedPlans.rmp.productId === 120
      ) {
        const title = this._translateService.instant('BILLING.SUBSCRIPTION.ALREADY_CANCEL_TITLE');
        const message = this._translateService.instant('BILLING.SUBSCRIPTION.ALREADY_CANCEL', {
          subUntil: this.datePipe.transform(this.selectedDomain.subscribed, 'short'),
        });
        this.showInfoDialog(title, message);
        return;
      }

      // OLD_TODO: ha lesz datafeed wix-nél akkor bele kell rakni ide is a df downgrade-es dolgokat
      if (this.selectedDomain.ecomType === 'wix') {
        if (this.selectedDomain.subscriptions.rmp.planId !== 120 && this.selectedPlans.rmp.productId === 120) {
          this.confirmDialog
            .confirm(
              this._translateService.instant('BILLING.SUBSCRIPTION.WIX_CANCEL_TITLE'),
              this._translateService.instant('BILLING.SUBSCRIPTION.WIX_CANCEL_CONTENT'),
              this._translateService.instant('BILLING.SUBSCRIPTION.WIX_CANCEL_GO_TO'),
              this._translateService.instant('BILLING.SUBSCRIPTION.WIX_CANCEL_CLOSE')
            )
            .subscribe((result) => {
              if (result) {
                window.location.href = `https://manage.wix.com/account/subscriptions?referralInfo=sidebar`;
              }
            });
        } else {
          if (
            !this.selectedDomain.subscriptions.rmp.churnDate &&
            this.selectedDomain.subscriptions.rmp.planId > this.selectedPlans.rmp.productId
          ) {
            this.showInfoDialog(
              this._translateService.instant('BILLING.SUBSCRIPTION.WIX_CANT_DOWNGRADE_TITLE'),
              this._translateService.instant('BILLING.SUBSCRIPTION.WIX_CANT_DOWNGRADE_CONTENT')
            );
          } else {
            if (this.currentCycleType === 1 && this.selectedCycleType === 0) {
              this.showInfoDialog(
                this._translateService.instant('BILLING.SUBSCRIPTION.WIX_CANT_DOWNGRADE_TITLE'),
                this._translateService.instant('BILLING.SUBSCRIPTION.WIX_CANT_DOWNGRADE_CONTENT')
              );
              return;
            }
            window.location.href = this.wixURLs.find((elem) => elem.planId === this.selectedPlans.rmp.productId)[
              this.selectedCycleType === 0 ? 'monthlyURL' : 'yearlyURL'
            ];
          }
        }
      } else {
        let rmpCoupon = null;
        let dfCoupon = null;

        if (this.isCurrentPlanSelected('rmp')) {
          rmpCoupon = get(this.selectedDomain, `subscriptions.rmp.coupon.id`, null);
        } else {
          rmpCoupon = get(this.coupon, `data.${this.selectedPlans.rmp.productId}.id`, null);
        }

        if (this.isCurrentPlanSelected('df')) {
          dfCoupon = get(this.selectedDomain, `subscriptions.df.coupon.id`, null);
        } else {
          dfCoupon = get(this.coupon, `data.${this.selectedPlans.df.productId}.id`, null);
        }

        const subscriptionData = {
          ecomId: this.selectedDomain.id,
          userDetails: this.userDetails,
          cycle: this.selectedCycleType === 0 ? 'monthly' : 'annual',
          subscribeData: {
            marketplacePlanId: { planId: this.selectedPlans.rmp.productId, couponId: rmpCoupon },
            dataFeedPlanId: { planId: this.selectedPlans.df.productId, couponId: dfCoupon },
            amount:
              this.selectedCycleType === 0
                ? this.selectedPlans.rmp.productMonthlyPrice + this.selectedPlans.df.productMonthlyPrice
                : this.selectedPlans.rmp.productYearlyPrice + this.selectedPlans.df.productYearlyPrice,
          },
        };

        if (this.selectedPlans.rmp.productId === 120 && this.selectedPlans.df.productId === 100) {
          if (this.checkForSubChange()) {
            this.showUnchangedDialog();
            return;
          }
          this.openCancelDialog(subscriptionData);
        } else {
          if (this.selectedDomain.paymentProvider.toLowerCase() === 'shopify') {
            this.subscribeToShopify(subscriptionData);
          } else if (this.selectedDomain.options.fromWoo) {
            this.subscribeToWoocommerce(subscriptionData);
          } else {
            this.checkForBillingAddress().subscribe((res) => {
              if (res) {
                this.checkForPaymentProviderData(subscriptionData);
              } else {
                this.setBillingData(subscriptionData, this.paymentProvider.paymentProviderName);
              }
            });
          }
        }
      }
    }
  }

  isStarterPlan(product): boolean {
    return this.selectedPlans[product].productId === 120 || this.selectedPlans[product].productId === 100;
  }

  openCancelDialog(subscriptionData): void {
    this.dialog
      .open(CancelDialogComponent, {
        width: '1100px',
        data: {
          selectedEcom: this.selectedDomain,
        },
      })
      .afterClosed()
      .subscribe((res: CancelDialogResult) => {
        switch (res.type) {
          case 'withCancellation':
            this.startEcomSubsctriptionCancellation(this.selectedDomain.ecomType, subscriptionData);
            break;
          case 'setPlan':
            this.selectedCycleType = res.cycleToSet;
            this.selectedPlans[res.planTypeToSet] = this.plans[res.planTypeToSet].find(
              (elem) => elem.productId === res.planIdToSet
            );
            break;
        }
      });
  }

  private startEcomSubsctriptionCancellation(ecomType: string, subscriptionData): void {
    switch (ecomType) {
      case 'shopify':
        this.cancelShopifySubscription(subscriptionData);
        break;
      case 'woocommerce':
        if (this.selectedDomain.options.fromWoo) {
          this.cancelWoocommerceSubscription(subscriptionData);
        }

        this.handlePaymentProviderSubscription(subscriptionData);
        break;
      default:
        this.handlePaymentProviderSubscription(subscriptionData);
    }
  }

  //// SHOPIFY ////

  public subscribeToShopify(subscriptionData): void {
    this.disableButton = true;
    this._subscriptionService.subscribeToShopify(subscriptionData).subscribe(
      (rest) => {
        window.location.href = rest;
      },
      (error) => {
        console.log(error);
        this.initSubscriptionData();
        this.disableButton = false;
      }
    );
  }

  public subscribeToWoocommerce(subscriptionData): void {
    this.disableButton = true;
    this._subscriptionService.subscribeToWoocommerce(subscriptionData).subscribe(
      (rest) => {
        window.location.href = rest.confirmation_url;
      },
      (error) => {
        console.log(error);
        this.initSubscriptionData();
        this.disableButton = false;
      }
    );
  }

  cancelShopifySubscription(subscriptionData): void {
    this.isLoaded = false;
    this._subscriptionService.subscribeToShopify(subscriptionData).subscribe(
      (rest) => {
        this.notificationService.success(this._translateService.instant('BILLING.SUBSCRIPTION.SUBSCRIPTION_CANCELED'));
        const title = this._translateService.instant('BILLING.SUBSCRIPTION.SUCCESSFUL_CANCEL_TITLE');
        const message = this._translateService.instant('BILLING.SUBSCRIPTION.SUCCESSFUL_CANCEL', {
          subUntil: this.datePipe.transform(this.selectedDomain.subscribed, 'short'),
        });
        this.showInfoDialog(title, message);
        this.marketplaceEcomService.initDomainList();
      },
      (error) => {
        console.log(error);
        this.initSubscriptionData();
        this.isLoaded = true;
      }
    );
  }

  cancelWoocommerceSubscription(subscriptionData): void {
    this.isLoaded = false;
    this._subscriptionService.cancelWoocommerce(subscriptionData).subscribe(
      (rest) => {
        this.notificationService.success(this._translateService.instant('BILLING.SUBSCRIPTION.SUBSCRIPTION_CANCELED'));
        const title = this._translateService.instant('BILLING.SUBSCRIPTION.SUCCESSFUL_CANCEL_TITLE');
        const message = this._translateService.instant('BILLING.SUBSCRIPTION.SUCCESSFUL_CANCEL', {
          subUntil: this.datePipe.transform(this.selectedDomain.subscribed, 'short'),
        });
        this.showInfoDialog(title, message);
        this.marketplaceEcomService.initDomainList();
      },
      (error) => {
        console.log(error);
        this.initSubscriptionData();
        this.isLoaded = true;
      }
    );
  }

  //// PAYMENT PROVIDERS ////

  private checkForPaymentProviderData(subscriptionData): void {
    if (this.paymentProvider.hasAccount()) {
      this.handlePaymentProviderSubscription(subscriptionData);
    } else {
      this.subscribeToPaymentProvider(subscriptionData, this.paymentProvider.paymentProviderName);
    }
  }

  private subscribeToPaymentProvider(subscriptionData, paymentProvider): void {
    this.cardFormDialogService
      .subscribeToPaymentProvider(
        this._translateService.instant('BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.TITLE'),
        this._translateService.instant('BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.CONTENT'),
        subscriptionData,
        this._translateService.instant('BILLING.SUBSCRIPTION.NUMMUSPAY_CARD_INFORMATION.SAVE'),
        this._translateService.instant('BILLING.SUBSCRIPTION.STRIPE_CARD_CANCEL_BUTTON'),
        paymentProvider
      )
      .subscribe((result) => {
        if (result) {
          this.handlePaymentProviderSubscription(result);
        }
      });
  }

  private handlePaymentProviderSubscription(result): void {
    this.disableButton = true;
    this._subscriptionService[this.paymentProvider.savePaymentProviderRest](result).subscribe(
      (res) => {
        this.handleStoreSubscriptionSuccessful();
      },
      (error) => {
        console.log(error);
        this.initSubscriptionData();
        this.disableButton = false;
      }
    );
  }

  public changeCard(restPost): void {
    this.billingService[this.paymentProvider.changeCardRest](restPost).subscribe(
      (res) => {
        this.notificationService.success(this._translateService.instant('BILLING.SUBSCRIPTION.CHANGE_CARD.SUCCESS'));
        this.getCardInfo();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public changeCardInformationDialog(): void {
    console.log(this.selectedDomain.subscriptions);
    const subscriptionData = {
      userDetails: this.userDetails,
      isUpdate: true,
      subscribeData: {
        amount: this.selectedDomain.subscriptions.rmp.price + this.selectedDomain.subscriptions.df.price,
      },
    };

    this.cardFormDialogService
      .subscribeToPaymentProvider(
        this._translateService.instant('BILLING.SUBSCRIPTION.CHANGE_CARD.TITLE'),
        this._translateService.instant('BILLING.SUBSCRIPTION.CHANGE_CARD.MESSAGE'),
        subscriptionData,
        this._translateService.instant('BILLING.SUBSCRIPTION.CHANGE_CARD.SAVE'),
        this._translateService.instant('BILLING.SUBSCRIPTION.CHANGE_CARD.CANCEL'),
        this.paymentProvider.paymentProviderName
      )
      .subscribe((result) => {
        if (result) {
          const restPost = {
            cardInfo: result.cardInfo,
            ecomId: this.selectedDomain.id,
          };
          this.changeCard(restPost);
        }
      });
  }

  private checkForCard(): void {
    if (this.paymentProvider.hasAccount()) {
      this.getCardInfo();
    } else {
      this.paymentProvider.cardDetails = null;
    }
  }

  private getCardInfo(): void {
    this.billingService
      .getCardInformation(this.selectedDomain.id, this.paymentProvider.paymentProviderName)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.paymentProvider.cardDetails = res;
        },
        (error) => {
          this.paymentProvider.cardDetails = null;
        }
      );
  }

  //// FUNCTIONS ////

  showUnchangedDialog(): void {
    this.confirmDialog
      .confirmWithoutCancel(
        this._translateService.instant('BILLING.SUBSCRIPTION.UNCHANGED_DIALOG_TITLE'),
        this._translateService.instant('BILLING.SUBSCRIPTION.UNCHANGED_DIALOG_CONTENT')
      )
      .subscribe();
  }

  handleStoreSubscriptionSuccessful(): void {
    this.notificationService.success(this._translateService.instant('BILLING.SUBSCRIPTION.SUBSCRIBE_SUCCESSFULLY'));
    this.disableButton = false;
    this.marketplaceEcomService.handleEcomChange(this.selectedDomain.domain);
    if (this.isAdmin) {
      this.userGatewayService
        .getSelectedUser()
        .pipe(take(1))
        .subscribe((response) => {
          this.userDetails = response;
          localStorage.setItem('userDetails', JSON.stringify(response));
        });
    } else {
      this.userService.getUserDetailsForceWithoutNavigate().subscribe((user) => {
        this.userDetails = user;
        this.router.navigate(['/billing/thank-you-page'], {
          queryParams: { ecomId: this.selectedDomain.id, token: SubscriptionService.fakeToken },
        });
      });
    }
  }

  checkForBillingAddress(): Observable<boolean> {
    return this.restService.get(this.billingService.getBillingAddressRest).pipe(
      map((res) => {
        const data = !Utils.isNullOrUndefined(res.getData()) ? res.getFirstData() : null;
        return (
          !Utils.isNullOrUndefinedOrLengthZero(data) &&
          !Utils.isNullOrUndefinedOrLengthZero(data.firstName) &&
          !Utils.isNullOrUndefinedOrLengthZero(data.lastName) &&
          !Utils.isNullOrUndefinedOrLengthZero(data.address1) &&
          !Utils.isNullOrUndefinedOrLengthZero(data.city) &&
          !Utils.isNullOrUndefinedOrLengthZero(data.zip)
        );
      })
    );
  }

  setBillingData(subscriptionData, type): void {
    const dialogRef = this.dialog.open(BillingInformationChangeDialogComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.notificationService.success(this._translateService.instant('BILLING.BILLING_DATA_UPDATED_SUCCESSFULLY'));
        this.subscribeToPaymentProvider(subscriptionData, type);
      }
    });
  }

  private showInfoDialog(title, message): void {
    const config = new MatDialogConfig();
    config.autoFocus = false;
    config.width = '400px';
    config.panelClass = 'custom-modal-container';

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.btnOkText = 'OK';
  }

  isOnlyMarketplace(ecomType): boolean {
    return !getPlatformByType(ecomType as EcomTypeEnum).options.hasDataFeed;
  }

  checkPlanAvailable(ecomType, planType): boolean {
    let allPlans = ['rmp', 'df'];
    if (this.isOnlyMarketplace(ecomType)) {
      allPlans = ['rmp'];
    }
    return allPlans.indexOf(planType) > -1;
  }

  openCouponCodeDialog(product): void {
    const config = new MatDialogConfig();
    config.autoFocus = false;
    config.width = '400px';
    config.panelClass = 'custom-modal-container';
    config.data = {
      ecomId: this.selectedDomain.id,
      planId: this.selectedPlans[product].productId,
    };

    const dialogRef = this.dialog.open(CouponCodeDialogComponent, config);
    dialogRef.afterClosed().subscribe((coupon) => {
      if (!coupon) {
        return;
      }
      if (this.coupon) {
        this.coupon.data = merge({}, this.coupon.data, coupon.data);
      } else {
        this.coupon = coupon;
      }
    });
  }

  getCalculatedPrice(product, planId, originalPrice): number {
    let coupon = null;
    if (this.isCurrentPlanSelected(product)) {
      if (!(coupon = get(this.selectedDomain, `subscriptions.${product}.coupon`))) {
        return originalPrice;
      }
    } else if (!Utils.isNullOrUndefined(this.coupon)) {
      if (!(coupon = get(this.coupon, `data.${planId}`))) {
        return originalPrice;
      }
    } else {
      return originalPrice;
    }

    const calculatedPrice = this.calculatePrice(coupon.type, coupon.amount, originalPrice);
    return calculatedPrice > 0 ? calculatedPrice : 0;
  }

  calculatePrice(type, amount, originalPrice): number {
    switch (type) {
      case 'fixed':
        return originalPrice - amount;
      case 'percentage':
        return Utils.truncateByDecimalPlace(originalPrice - (originalPrice * amount) / 100, 2);
      default:
        return originalPrice;
    }
  }

  isCurrentPlanSelected(product): boolean {
    return (
      this.selectedPlans[product].productId == this.selectedDomain.subscriptions[product].planId &&
      this.currentCycleType === this.selectedCycleType
    );
  }

  isCoupon(product): boolean {
    return this.coupon && this.coupon.data && this.coupon.data[this.selectedPlans[product].productId];
  }

  handleQueryParams(): void {
    this.discountToken = this.route.snapshot.queryParams['subDiscount'] || null;
    if (!this.route.snapshot.queryParams['default']) {
      return;
    }

    let defaultParams = this.route.snapshot.queryParams['default'];
    defaultParams = defaultParams instanceof Array ? defaultParams : [defaultParams];

    defaultParams.forEach((planStr: string) => {
      const [planType, planId] = planStr.split('-');
      this._defaultSubParams.push({
        planType: planType,
        planId: parseInt(planId, 10),
        cycle: this.route.snapshot.queryParams['cycle'],
      });
    });
  }

  handleDefaultPlans(): void {
    if (Utils.isNullOrUndefined(this._defaultSubParams) || this._defaultSubParams.length < 1) {
      return;
    }
    this._defaultSubParams.forEach((dPlan) => {
      if (
        dPlan.planType !== 'df' ||
        (dPlan.planType === 'df' && !this.isOnlyMarketplace(this.selectedDomain.ecomType))
      ) {
        this.selectedPlans[dPlan.planType] = this.plans[dPlan.planType].find((elem) => elem.productId === dPlan.planId);
        this.selectedCycleType = dPlan.cycle === 'annual' ? 1 : 0;
      }
    });
  }

  removeCoupon(planId): void {
    this.coupon = null;
  }

  private setChurnedPlans(ecom: EcomVO): void {
    const plans: string[] = [];
    this.churnedPlan = '';
    if (Utils.isNullOrUndefined(ecom.subscriptions)) {
      return;
    }
    if (!Utils.isNullOrUndefined(ecom.subscriptions.rmp.churnDate) && ecom.subscriptions.rmp.planId !== 120) {
      plans.push(ecom.subscriptions.rmp.planName.replace('plan', '').trim());
    }
    if (!Utils.isNullOrUndefined(ecom.subscriptions.df.churnDate) && ecom.subscriptions.df.planId !== 100) {
      plans.push(ecom.subscriptions.df.planName.replace('plan', '').trim());
    }
    this.churnedPlan = plans.join(', ');
  }

  get isAllPlanAvailable(): boolean {
    if (this.selectedCycleType === 0) {
      return true;
    }
    return this.planHasAnnual.rmp && this.planHasAnnual.df;
  }

  get showErrorTermsMessage(): boolean {
    return this._showErrorTermsMessage;
  }
}

interface DefaultSubQParams {
  planType: string;
  planId: number;
  cycle: string;
}

export class PlanVO {
  productId: number;
  productName: string;
  planType: string;
  productDescription: string;
  productMonthlyPrice: number;
  productYearlyPrice: number;
  planData: {
    productsPerDay: number;
    managedProductsLimit: number;
    manualProcessPerDay: number;
    autoProcessPerDay: number;
    variantsPerDay: number;
    trialDays: number;
    catalogsToCreateLimit: number;
    automatedProductUploads: boolean;
    automatedOrderDownload: boolean;
    automatedRunPerDay: boolean;
    accessChat: boolean;
    approveSupplierAccess: boolean;
    autoSyncNum: number;
    canSync: boolean;
    catalogNum: number;
    contactAccess: boolean;
    pim: boolean;
    premiumSupplierAccess: boolean;
    transactionFee: number;
    variantRenaming: boolean;
    vipSupplierAccess: boolean;
    manualDownloadOrder: boolean;
    salesReports: boolean;
    unlimitedOrders: boolean;
    hasAnnual: boolean;
    freeTrialUntil: string;
    annualMonthlyPrice: number;
  };
  planStatus: number;
}

export class BraintreeProvider {
  ecom: EcomVO;
  userDetails: User;
  _cardDetails: CardInfoVO;
  public paymentProviderName = 'braintree';
  public savePaymentProviderRest = 'saveBraintreeSubscription';
  public changeCardRest = 'changeBraintreeCardForCustomer';

  constructor(ecom: EcomVO, userDetails: User) {
    this.ecom = ecom;
    this.userDetails = userDetails;
  }

  set cardDetails(cardDetails) {
    this._cardDetails = cardDetails;
  }

  get cardDetails(): CardInfoVO {
    return this._cardDetails;
  }

  public hasAccount(): boolean {
    return (
      !Utils.isNullOrUndefined(this.userDetails.braintreeCustomerId) && this.userDetails.braintreeCustomerId !== ''
    );
  }
}
