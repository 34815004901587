import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'app/utils/utils';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AppState } from '../../../../app.state';
import { Action, AuthorizationEcomService } from '../../../../service/authorization-ecom/authorization-ecom.service';
import { CatalogSidebarService } from '../../../../service/catalog-sidebar/catalog-sidebar.service';
import { MarketplaceEcomService } from '../../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { RcatalogService } from '../../../../service/rcatalog/rcatalog.service';
import { RestService } from '../../../../service/rest/rest.service';
import { ConfirmationDialogsService } from '../../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.service';
import { CatalogMenuVO } from '../../../../vo/catalog-menu-vo';
import { NotificationService } from '../../../notification/notification.service';
import { SnippetEnum } from '../../../setup-guide/enums/snippet-enums';
import { SetupGuideService } from '../../../setup-guide/service/setup-guide.service';
import { RetailerCatalogType } from '../../../../vo/retailer-catalog/retailer-catalog-type';
import { AddUsedSupplierUserIdAction } from '../../../../store/rcatalogs/rcatalogs.action';

@Component({
  selector: 'app-add-to-import-list-menu',
  templateUrl: './add-to-import-list-menu.component.html',
  styleUrls: ['./add-to-import-list-menu.component.scss'],
})
export class AddToImportListMenuComponent implements OnInit, OnDestroy {
  public catalogMenuVO: CatalogMenuVO = new CatalogMenuVO();
  public isNewCatalogPanelVisible = false;
  public catalogOptions: any;
  public catalogFindSupplier: any;
  public catalogMarketplace: any;
  public selectedDomianName;
  public selectedDomianEcomType;
  public lastAddedCatalog;
  public hasError = false;
  public isLoading = false;
  public excludeUpdateFieldsCheckBox = true;
  hasPermission: Observable<boolean>;

  httpParams = new HttpParams();

  private _unsubscribeAll: Subject<void>;

  @ViewChild('menu', { static: false }) menuRef: MatMenu;

  constructor(
    private restService: RestService,
    @Inject(MAT_DIALOG_DATA) public data: AddToCatalogMenuComponentData,
    private catalogSidebarService: CatalogSidebarService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AddToImportListMenuComponent>,
    public marketplaceEcomService: MarketplaceEcomService,
    private translate: TranslateService,
    private confirmAllSelectDialog: ConfirmationDialogsService,
    private router: Router,
    private authorizationService: AuthorizationEcomService,
    private rcatalogService: RcatalogService,
    private store: Store<AppState>,
    private setupGuideService: SetupGuideService
  ) {
    this._unsubscribeAll = new Subject();
    this.catalogMenuVO.catalog = Number(this.rcatalogService.getSelectedCatalog()?.id);
  }

  ngOnInit(): void {
    this.excludeUpdateFieldsCheckBox = true;
    this.initSelectedEcom();
    if (this.data.createEmptyCatalog) {
      this.handleCreateNewCatalog();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initSelectedEcom(): void {
    this.marketplaceEcomService.getEcomWithSubscriptionFromStore().subscribe((ecomVO) => {
      this.catalogMenuVO.ecomId = ecomVO?.id;
      this.selectedDomianName = ecomVO?.domain;
      this.selectedDomianEcomType = ecomVO?.ecomType;
      this.loadCatalogOptions();
    });
  }

  public addToCatalog(): void {
    this.isLoading = true;
    this.addProductToCatalog();
  }

  private handleCreateNewCatalog(): void {
    this.isNewCatalogPanelVisible = true;
  }

  private handleUsedSuppliers(): void {
    if (!!this.data.supplierUserId) {
      this.store.dispatch(
        new AddUsedSupplierUserIdAction({
          supplierUserId: this.data.supplierUserId,
          ecomId: this.catalogMenuVO.ecomId,
        })
      );
    }
  }

  private addProductToCatalog(): void {
    if (Array.isArray(this.data.id)) {
      this.catalogSidebarService
        .addBulkProductsToCatalog(this.data.id, this.catalogMenuVO.catalog)
        .subscribe((response) => {
          this.setupGuideService.setCompletedStep(SnippetEnum.RETAILER_ADD_IMPORT_LIST);
          this.isLoading = false;
          this.dialogRef.close(response.getFirstData().id);
          this.handleUsedSuppliers();
        });
      return;
    }
    this.catalogSidebarService.addProductToCatalog(this.data.id, this.catalogMenuVO.catalog).subscribe(
      (response) => {
        if (!this.data.isAlibaba) {
          this.lastAddedCatalog = this.catalogMenuVO.catalog;
          this.catalogSidebarService.newProductAdded.emit({
            productId: this.data.id,
            catalogId: response.getFirstData().id,
            catalogName: response.getFirstData().name,
          });
          this.handleUsedSuppliers();
        }
        this.setupGuideService.setCompletedStep(SnippetEnum.RETAILER_ADD_IMPORT_LIST);
        this.isLoading = false;
        this.dialogRef.close(response.getFirstData().id);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public getUpdateException(): void {
    const ecomType = this.selectedDomianEcomType;
    this.catalogMenuVO.excludeUpdateFields = [];
    if (!this.excludeUpdateFieldsCheckBox) {
      switch (ecomType) {
        case 'shopify': {
          this.catalogMenuVO.excludeUpdateFields.push('variants.price');
          break;
        }
        case 'wix': {
          this.catalogMenuVO.excludeUpdateFields.push('variants.variant.priceData.price');
          break;
        }
        case 'woo': {
          this.catalogMenuVO.excludeUpdateFields.push('variations.regular_price');
          break;
        }
        case 'shoprenter': {
          this.catalogMenuVO.excludeUpdateFields.push('variants.price');
          break;
        }
        case 'bigcommerce': {
          this.catalogMenuVO.excludeUpdateFields.push('variants.price');
          break;
        }
        case 'jumpseller': {
          this.catalogMenuVO.excludeUpdateFields.push('variants.price');
          break;
        }
        case 'ecwid': {
          this.catalogMenuVO.excludeUpdateFields.push('variants.price');
          break;
        }
        default:
          console.log('Unknown ecom type');
      }
    }
  }

  public createNewCatalog(): void {
    this.getUpdateException();

    this.isLoading = true;
    this.hasError = false;

    if (
      ((this.catalogMenuVO.name && this.catalogMenuVO.name.length > 0) || this.data.createEmptyCatalog) &&
      this.catalogMenuVO.margin &&
      this.catalogMenuVO.margin > 0 &&
      this.catalogMenuVO.margin <= 1000
    ) {
      const _filter = [
        {
          key: 'ID',
          condition: 'equals',
          values: Array.isArray(this.data.id) ? this.data.id : [this.data.id],
        },
      ];

      if (this.data.isAlibaba) {
        this.catalogSidebarService
          .createCatalog(
            this.catalogMenuVO.name,
            this.catalogMenuVO.ecomId,
            this.catalogMenuVO.margin,
            _filter,
            this.catalogMenuVO.excludeUpdateFields,
            this.data.isAlibaba
          )
          .subscribe(
            (response) => {
              this.catalogSidebarService.newProductAdded.emit({
                productId: this.data.id,
                catalogId: response.id,
                catalogName: response.name,
              });
              this.isLoading = false;
              this.dialogRef.close(response.id);
            },
            (error) => {
              this.isLoading = false;
              console.log(error);
            }
          );
        return;
      }
      if (this.data.createEmptyCatalog && Utils.isNullOrUndefined(this.data.id)) {
        this.createEmptyCatalog();
      } else {
        this.catalogSidebarService.addRetailerToCatalogCheck(this.data.supplierCatalogID.toString()).subscribe(
          (elem) => {
            this.createCatalog(_filter, elem);
          },
          (error) => {
            this.isLoading = false;
            console.log('error');
          }
        );
      }
    } else {
      this.isLoading = false;
      this.hasError = true;
    }
  }

  private createEmptyCatalog(): void {
    if (!Utils.isNullOrUndefined(this.catalogMenuVO.margin) && !Utils.isNullOrUndefined(this.catalogMenuVO.ecomId)) {
      this.catalogSidebarService
        .createEmptyCatalog(
          this.catalogMenuVO.ecomId,
          this.catalogMenuVO.margin,
          this.catalogMenuVO.excludeUpdateFields,
          false,
          this.catalogMenuVO.name
        )
        .subscribe(() => {
          this.isLoading = false;
          this.dialogRef.close(true);
        });
    }
  }

  private createCatalog(filterToCat, elem): void {
    if (elem) {
      if (
        (this.data.createEmptyCatalog ? true : !Utils.isNullOrUndefined(this.catalogMenuVO.name)) &&
        !Utils.isNullOrUndefined(this.catalogMenuVO.ecomId) &&
        !Utils.isNullOrUndefined(this.catalogMenuVO.margin)
      ) {
        this.catalogSidebarService
          .createCatalog(
            this.data.createEmptyCatalog ? 'Default Import List' : this.catalogMenuVO.name,
            this.catalogMenuVO.ecomId,
            this.catalogMenuVO.margin,
            filterToCat,
            this.catalogMenuVO.excludeUpdateFields
          )
          .subscribe(
            (response) => {
              this.catalogSidebarService.newProductAdded.emit({
                productId: this.data.id,
                catalogId: response.id,
                catalogName: response.name,
              });

              this.setupGuideService.setCompletedStep(SnippetEnum.RETAILER_ADD_IMPORT_LIST);

              this.isLoading = false;
              this.dialogRef.close(true);
            },
            (error) => {
              this.isLoading = false;
              console.log(error);
            }
          );
      } else {
        this.isLoading = false;
        this.hasError = true;
      }
    } else {
      console.log('error');
    }
  }

  private loadCatalogOptions(): void {
    this.httpParams = this.httpParams.set('ecomId', this.catalogMenuVO.ecomId.toString());
    if (this.data.isAlibaba) {
      this.httpParams = this.httpParams.set('alibabaCatalog', 'true');
    }

    this.restService.get('RetailerCatalogService/getRetailersCatalogs', this.httpParams).subscribe((res) => {
      this.catalogOptions = res.getData().filter((catalog) => {
        if (this.data.isAlibaba) {
          return catalog.filter.filter((f) => f.key === 'TASK_ID').length === 0 && catalog.alibabaCatalog;
        } else {
          return catalog.filter.filter((f) => f.key === 'TASK_ID').length === 0 && !catalog.alibabaCatalog;
        }
      });
      this.catalogMarketplace = res
        .getData()
        .filter((catalog) => catalog.filter.filter((f) => f.key === 'TASK_ID').length === 0);
      this.catalogFindSupplier = res
        .getData()
        .filter((catalog) => catalog.filter.filter((f) => f.key === 'TASK_ID').length > 0);
      if (this.catalogOptions.length === 0) {
        this.checkPermissionToCreateCatalog();
      }
    });
  }

  private createCatalogPermissionListener(): Observable<boolean> {
    return this.authorizationService
      .hasPermissionObs(Action.CREATE_CATALOG, {
        numberOfMarketplaceCatalogs: this.catalogMarketplace.length,
        numberOfFindSupplierCatalogs: this.catalogFindSupplier.length,
        catalogType: RetailerCatalogType.SELECTION,
      })
      .pipe(
        tap((hasPermission) => {
          this.isNewCatalogPanelVisible = hasPermission;
        })
      );
  }

  private checkPermissionToCreateCatalog(): void {
    this.createCatalogPermissionListener()
      .pipe(filter((hasPermission) => !hasPermission))
      .subscribe(() => {
        this.confirmAllSelectDialog
          .confirm(
            this.translate.instant('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.LIMIT_REACHED.TITLE_STARTER_CATALOG'),
            this.translate.instant('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.LIMIT_REACHED.MESSAGE_STARTER_CATALOG'),
            this.translate.instant('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.LIMIT_REACHED.CONFIRM_STARTER'),
            this.translate.instant('EXPLORE_PRODUCTS.EXPLORE_PRODUCTS_ITEM.LIMIT_REACHED.CANCEL')
          )
          .subscribe((result) => {
            if (result) {
              this.dialogRef.close(false);
              this.router.navigate(['/billing/subscription']);
            }
          });
      });
  }

  public showPanel(): void {
    this.checkPermissionToCreateCatalog();
  }

  public hidePanel(): void {
    this.isNewCatalogPanelVisible = false;
  }

  public getDialogTitle(): string {
    return !this.isNewCatalogPanelVisible
      ? this.translate.instant('MARKETPLACE.EXPLORE_PRODUCTS.ADD_TO_IMPORT_LIST_MODAL.ADD_EXIST')
      : this.translate.instant(
          'IMPORT_LIST.IMPORT_LIST_ITEM_PAGE.TABS.PRODUCTS.SUPPLIER_FILTER.BULK_ACTIONS.NEW_CATALOG_DIALOG.TITLE'
        );
  }
}

export interface AddToCatalogMenuComponentData {
  id?: string;
  isAlibaba?: boolean;
  supplierCatalogID?: number;
  supplierUserId?: number;
  createEmptyCatalog?: boolean;
}
