import { Utils } from 'app/utils/utils';
import { isArray } from 'lodash';
import { Omit } from '../main/droppable-input/droppable-input.component';
import { Shipping } from './shipping/shipping';

export class SearchProductVO {
  // ELASTIC_ID: string;
  TASK_ID: number;
  USER_ID: number;
  ID: string;
  DESCRIPTION: any;
  IMAGES: string[];
  // SYNCEE_IMAGES: string[];
  TAGS: string[];
  SHOPIFY_OPTION_NAME_2: string;
  SHOPIFY_OPTION_NAME_1: string;
  SHOPIFY_OPTION_NAME_3: string;
  BRAND: string;
  CATEGORY: string;
  TITLE: string;
  IMAGES_ALTS: string[];
  VARIANTS: VariantVO[];
  HASH: string;
  LAST_MODIFIED: string;
  CREATED: string;
  PUBLISHED: string[];
  // VERSION: number;
  SUPPLIER: SupplierVo;
  SETTINGS: SettingsVo;
  SHOP_ID: string;
  // WEIGHT_UNIT: string;
  SEO_URL: string;
  BADGES?: BadgesVO[];
  IS_HIDDEN: boolean;
  SHIPPING?: Shipping[];

  // Alibaba fields
  // DETAIL_URL: string;
  // CAN_PLACE_ORDER: boolean;
  // LADDER_PERIOD: any;
  SYNCEE_CATEGORY: number[];
}

export class VariantVO {
  SHOPIFY_OPTION_VALUE_2: string;
  SHOPIFY_OPTION_VALUE_1: string;
  SHOPIFY_OPTION_VALUE_3: string;
  V_IMAGES: string[];
  // SYNCEE_V_IMAGES: string[];
  UPC: string;
  WEIGHT: number;
  SKU: string;
  QTY: number;
  PRICE: number;
  RETAIL_PRICE: number;
  DISCOUNTED_PRICE: number;
  ORIGINAL_PRICE: number;
  STORE_PRICE: number;
  STORE_CURRENCY: string;

  DEFAULT_CURRENCY_PRICE: number;

  VARIANT_ID: string;

  // Alibaba fields
  // LADDER_PRICE: any;

  constructor() {}
}

export class SupplierVo {
  // id: number;
  // userId: string;
  contactEmail: string;
  // userEmail?: string;
  website: string;
  // description: string;
  // categories: string;
  // worldwideShipping: boolean;
  /*
  europeanCountries: string;
  europeanShippingTime: string;
  asianCountries: string;
  asianShippingTime: string;
  americanCountries: string;
  americanShippingTime: string;
  africanCountries: string;
  africanShippingTime: string;
  australianCountries: string;
  australianShippingTime: string;
  */
  companyName: string;
  handle: string;
  // shippingDetails: ShippingDetails[];
  /*settings: {
    possibleMargin: string;
    language: string;
    shipping_type: string;
    approveNeeded: boolean;
    showPrices: boolean;
    sendEmail: string;
    warehouseLocation: string;
    mainCategories: string[];
    feedSettings: {
      backorder: boolean;
      shipping: boolean;
    };
  };*/
  shipsTo: string[];
  // logoLink: string;
  // isAutomate: any;
  isAutomated: boolean;
  // seoUrl: string;
  // publicPhoneCode: string;
  // publicPhoneNumber: string;
  paymentCurrency: string;
}

export class ShippingDetails {
  countries: string[];
  cost: number;
  additionalCost: number;
  minShippingTime: number;
  maxShippingTime: number;
  shippingZoneRates: ShippingZoneRate[];
}

export class ShippingZoneRate {
  id: number;
  maxAmount: number;
  minAmount: number;
  price: number;
  rateName: string;
  shippingDetails: number;
  type: string;
}

export class SettingsVo {
  possibleMargin: string;
  language: string;
  shipping_type: string;
  approveNeeded: boolean;
  approveDescription: string;
  needResponse: boolean;
  showPrices: boolean;
  sendEmail: string;
  warehouseLocation: string;
  mainCategories: string[];
  feedSettings: FeedSettingsVo;
  premium: boolean;
}

export class FeedSettingsVo {
  backorder: boolean;
  shipping: boolean;
}

export class ProductCatalogVo {
  CATALOG_ID: number;
  CATALOG_NAME: string;
}

export enum SearchProductType {
  FULL_TEXT = 'FULL_TEXT',
  VECTOR = 'VECTOR',
  HYBRID = 'HYBRID',
}

export class FullMarketplaceFilterVO {
  lang: string;
  ecomId: number;
  category: number;
  search: string;
  approveType: string;
  shippingType: string | string[];
  shipsFrom: string | string[];
  shipsTo: string | string[];
  supplier: string | string[];
  size: number;
  from: number;
  supplierId: number;
  stock: string;
  taskId: number | number[];
  autoOrder: string;
  minPrice: number;
  maxPrice: number;
  minShippingTime: number;
  maxShippingTime: number;
  premium: string;
  sortField: ProductSearchSortField;
  sortOrder: ProductSearchSortOrder;
  brand: string | string[];
  sku: string;
  userId: number[];
  synceeCategory: string[];
}

export interface MarketplaceFilter extends Partial<Omit<FullMarketplaceFilterVO, 'from' | 'size'>> {}

export interface MarketplaceFilterPagination extends Partial<Pick<FullMarketplaceFilterVO, 'from' | 'size'>> {}

export interface MarketplaceFilterSort extends Partial<Pick<MarketplaceFilter, 'sortField' | 'sortOrder'>> {}

// For filter of Search Products and Find Suppliers
export class FilterItemsVO extends FullMarketplaceFilterVO {
  sizeByScreenSize;
  constructor(filters?: Partial<FullMarketplaceFilterVO>) {
    super();
    const screen = window.screen.width;
    if (screen >= 1814 || screen < 1544) {
      this.sizeByScreenSize = 48;
    } else {
      this.sizeByScreenSize = 50;
    }
    this.initToDefaultFilter(filters);
  }

  initToDefaultFilter(filters?: Partial<FullMarketplaceFilterVO>): void {
    this.lang = this.filterHasProp(filters, 'lang') ? filters.lang : 'any';
    this.ecomId = this.filterHasProp(filters, 'ecomId') ? filters.ecomId : null;
    this.category = this.filterHasProp(filters, 'category') ? filters.category : 1;
    this.search = this.filterHasProp(filters, 'search') ? filters.search : '';
    this.approveType = this.filterHasProp(filters, 'approveType') ? filters.approveType : 'any';
    this.shippingType = this.filterHasProp(filters, 'shippingType') ? filters.shippingType : 'any';
    this.shipsFrom = this.filterHasProp(filters, 'shipsFrom') ? filters.shipsFrom : null;
    this.shipsTo = this.filterHasProp(filters, 'shipsTo') ? filters.shipsTo : null;
    this.size = this.filterHasProp(filters, 'size') ? filters.size : this.sizeByScreenSize;
    this.supplier = this.filterHasProp(filters, 'supplier') ? filters.supplier : 'any';
    this.from = this.filterHasProp(filters, 'from') ? filters.from : 0;
    this.supplierId = this.filterHasProp(filters, 'supplierId') ? filters.supplierId : null;
    this.stock = this.filterHasProp(filters, 'stock') ? filters.stock : 'true';
    this.taskId = this.filterHasProp(filters, 'taskId') ? filters.taskId : null;
    this.autoOrder = this.filterHasProp(filters, 'autoOrder') ? filters.autoOrder : 'any';
    this.minPrice = this.filterHasProp(filters, 'minPrice') ? filters.minPrice : null;
    this.maxPrice = this.filterHasProp(filters, 'maxPrice') ? filters.maxPrice : null;
    this.minShippingTime = this.filterHasProp(filters, 'minShippingTime') ? filters.minShippingTime : null;
    this.maxShippingTime = this.filterHasProp(filters, 'maxShippingTime') ? filters.maxShippingTime : null;
    this.premium = this.filterHasProp(filters, 'premium') ? filters.premium : null;
    this.sortField = this.filterHasProp(filters, 'sortField') ? filters.sortField : null;
    this.sortOrder = this.filterHasProp(filters, 'sortOrder') ? filters.sortOrder : null;
    this.brand = this.filterHasProp(filters, 'brand') ? filters.brand : null;
    this.sku = this.filterHasProp(filters, 'sku') ? filters.sku : null;
    this.userId = this.filterHasProp(filters, 'userId') ? filters.userId : null;
    this.synceeCategory = this.filterHasProp(filters, 'synceeCategory') ? filters.synceeCategory : null;
  }

  private filterHasProp(filters: Partial<FullMarketplaceFilterVO>, key: keyof FullMarketplaceFilterVO): boolean {
    return !Utils.isNullOrUndefined(filters) && !Utils.isNullOrUndefined(filters[key]);
  }

  getParams(): any[] {
    return [
      {
        name: 'lang',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.LANGUAGE',
        elasticField: 'SETTINGS.language',
        value: this.lang,
        isQueryFilter: true,
      },
      {
        name: 'ecomId',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.ECOMID',
        elasticField: 'ecomId',
        value: this.ecomId,
        isQueryFilter: false,
      },
      {
        name: 'category',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.CATEGORY',
        elasticField: 'category',
        value: this.category,
        isQueryFilter: false,
      },
      {
        name: 'search',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.SEARCH_TERM',
        elasticField: 'search',
        value: this.search === '' ? null : this.search,
        isQueryFilter: false,
      },
      {
        name: 'approveType',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.APPROVAL_NEEDED',
        elasticField: 'SETTINGS.approveNeeded',
        value: this.approveType,
        isQueryFilter: true,
      },
      {
        name: 'shippingType',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.SHIPPING_TYPE',
        elasticField: 'SETTINGS.shipping_type',
        value: this.shippingType,
        isQueryFilter: true,
      },
      {
        name: 'shipsFrom',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.SHIPS_FROM',
        elasticField: 'SETTINGS.warehouseLocation.keyword',
        value: this.shipsFrom,
        isQueryFilter: true,
      },
      {
        name: 'shipsTo',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.SHIPS_TO',
        elasticField: 'SUPPLIER.shipsTo.keyword',
        value: this.shipsTo,
        isQueryFilter: true,
      },
      {
        name: 'size',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.SIZE',
        elasticField: 'size',
        value: this.size,
        isQueryFilter: false,
      },
      {
        name: 'from',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.FROM',
        elasticField: 'from',
        value: this.from,
        isQueryFilter: false,
      },
      {
        name: 'supplier',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.SUPPLIER',
        elasticField: 'USER_ID',
        value: this.supplier,
        isQueryFilter: true,
        findValue: (supplierList, value) => {
          const supplierItem = supplierList.find((supplier) => supplier.id === value);
          return !Utils.isNullOrUndefined(supplierItem) ? supplierItem.companyName : null;
        },
      },
      {
        name: 'stock',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.STOCK',
        elasticField: 'VARIANTS.QTY',
        value: this.stock,
        isQueryFilter: true,
      },
      {
        name: 'supplierId',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.SUPPLIER_ID',
        elasticField: 'supplierId',
        value: this.supplierId,
        isQueryFilter: false,
      },
      {
        name: 'taskId',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.TASK_ID',
        elasticField: 'TASK_ID',
        value: this.taskId,
        isQueryFilter: true,
      },
      {
        name: 'autoOrder',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.AUTO_ORDER',
        elasticField: 'SUPPLIER.isAutomated',
        value: this.autoOrder,
        isQueryFilter: true,
      },
      {
        name: 'minPrice',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.MIN_PRICE',
        elasticField: 'VARIANTS.DEFAULT_CURRENCY_PRICE',
        value: this.minPrice,
        isQueryFilter: true,
      },
      {
        name: 'maxPrice',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.MAX_PRICE',
        elasticField: 'VARIANTS.DEFAULT_CURRENCY_PRICE',
        value: this.maxPrice,
        isQueryFilter: true,
      },
      {
        name: 'minShippingTime',
        title: 'Minimum shipping time',
        elasticField: 'SUPPLIER.shippingDetails.minShippingTime',
        value: this.minShippingTime,
        isQueryFilter: true,
      },
      {
        name: 'maxShippingTime',
        title: 'Maximum shipping time',
        elasticField: 'SUPPLIER.shippingDetails.maxShippingTime',
        value: this.maxShippingTime,
        isQueryFilter: true,
      },
      {
        name: 'premium',
        title: 'EXPLORE_PRODUCTS.TOP_SEARCH.FILTER_TITLES.PREMIUM',
        elasticField: 'SETTINGS.premium',
        value: this.premium,
        isQueryFilter: true,
      },
      {
        name: 'sortField',
        title: 'sortField',
        elasticField: 'sortField',
        value: this.sortField,
        isQueryFilter: false,
      },
      {
        name: 'sortOrder',
        title: 'sortOrder',
        elasticField: 'sortOrder',
        value: this.sortOrder,
        isQueryFilter: false,
      },
      {
        name: 'brand',
        title: 'brand',
        elasticField: 'BRAND',
        value: this.brand,
        isQueryFilter: true,
      },
      {
        name: 'sku',
        title: 'sku',
        elasticField: 'VARIANTS.SKU.keyword',
        value: this.sku,
        isQueryFilter: true,
      },
      {
        name: 'userId',
        title: 'userId',
        elasticField: 'USER_ID',
        value: this.userId,
        isQueryFilter: true,
      },
      {
        name: 'synceeCategory',
        title: 'synceeCategory',
        elasticField: 'SYNCEE_CATEGORY',
        value: this.synceeCategory,
        isQueryFilter: true,
      },
    ];
  }

  createQueryFilter(key, values, condition = 'equals'): QueryFilter {
    return createQueryFilter(key, values, (condition = 'equals'));
  }
}

export function createQueryFilter(key: string, values: any, condition = 'equals'): QueryFilter {
  let queryValues = [];
  if (!Utils.isNullOrUndefined(values)) {
    if (isArray(values)) {
      queryValues = [...values];
    } else {
      if (values === 'any') {
        queryValues.push('*');
      } else {
        queryValues.push(values);
      }
    }
  }

  return {
    key: key,
    condition: condition,
    values: queryValues,
  };
}

export interface QueryFilter {
  key: string;
  condition: string;
  values: any;
}

export class SearchQueryVO {
  search: string;
  category: number;
  ecomId: string;
  from: number;
  size: number;
  queryFilter: (QueryFilter | string | QueryFilter[])[];

  constructor() {
    this.search = null;
    this.category = null;
    this.ecomId = null;
    this.from = null;
    this.size = null;
    this.queryFilter = [];
  }
}

export class ShippingHandleVO {
  continent: string;
  shippingTime: string;
  shipsTo: string[];
}

export const ImageKeys = {
  synceeImageKey: 'SYNCEE_IMAGES',
  synceeVImageKey: 'SYNCEE_V_IMAGES',
  imageKey: 'IMAGES',
  vImageKey: 'V_IMAGES',
};

export class BadgesVO {
  ID: number;
  NAME: string;
  SOURCE: string;
  ORDER: number;
  POSITION: number;
  BADGE_TYPE: string;
}

export enum ProductSearchSortField {
  VARIANTS_PRICE = 'VARIANTS.PRICE',
  CREATED = 'CREATED',
}

export enum ProductSearchSortOrder {
  ASC = 'Asc',
  DESC = 'Desc',
}
