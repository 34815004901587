<div class="custom-thumbnail-container" [ngClass]="{ horizontal: isMobile }">
  <div
    [class.hidden]="prevDisabled || images.length < 2"
    (click)="handleNavigation('prev')"
    fxLayoutAlign="center center"
    class="navigation-button"
    [ngClass]="{
      'left-arrow': isMobile,
      'navigation-button-horizontal': isMobile,
      'top-arrow': !isMobile,
      'navigation-button-vertical': !isMobile
    }"
  >
    <mat-icon>{{ !isMobile ? 'keyboard_arrow_up' : 'arrow_back_ios' }}</mat-icon>
  </div>
  <div
    [class.hidden]="nextDisabled || images.length < 2"
    (click)="handleNavigation('next')"
    fxLayoutAlign="center center"
    class="navigation-button"
    [ngClass]="{
      'right-arrow': isMobile,
      'navigation-button-horizontal': isMobile,
      'bottom-arrow': !isMobile,
      'navigation-button-vertical': !isMobile
    }"
  >
    <mat-icon>{{ !isMobile ? 'keyboard_arrow_down' : 'arrow_forward_ios' }}</mat-icon>
  </div>
  <ngx-slick-carousel #slickModal="slick-carousel" [config]="config" (beforeChange)="handleImageChanged($event)">
    <div
      #slickItems
      ngxSlickItem
      *ngFor="let image of images; let i = index"
      [ngClass]="{ selected: currentImageIndex === i }"
      (click)="handleSlideClicked(i)"
      class="thumbnail-img-container"
    >
      <img
        class="thumbnail-img"
        defaultImage="assets/images/transparent-400x400.png"
        [lazyLoad]="image | productImageFullCdn"
        [errorImage]="image"
        [alt]="altForAllImages"
        productImageErrorHandler
        [checkedOriginal]="true"
        height="78"
        width="78"
        oncontextmenu="return false"
      />
    </div>
  </ngx-slick-carousel>
</div>
