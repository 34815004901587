<ng-container *ngIf="countriesToShow.length > 0">
  <div
    class="shipping-container"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="isOpen = !isOpen"
    fxLayout="column"
    fxLayoutGap="2px"
  >
    <div class="title typography-body-small-2">{{ 'PRODUCT_DETAILS.SHIPPING' | translate }}</div>
    <div fxLayoutAlign="space-between center" fxLayoutGap="2px" class="m-w-0">
      <div fxLayoutAlign="center center" fxLayoutGap="4px" fxFlex="0 0 auto">
        <ng-container *ngIf="product.SUPPLIER.isAutomated; else specific">
          <div class="price" *ngIf="countriesToShow[0].minCost !== 0; else free">
            {{
              countriesToShow[0].minCost
                | currencyExchangeTo : product.SUPPLIER.paymentCurrency : ecomCurrency
                | async
                | currencyTransform : ecomCurrency
                | async
            }}
          </div>
        </ng-container>
        <img class="country-flag" [alt]="countriesToShow[0].code" [src]="countriesToShow[0].code | countryFlag" />
      </div>
      <div fxLayoutAlign="center center" fxLayoutGap="4px" class="m-w-0">
        <div class="days">
          {{ countriesToShow[0].minShippingDays }} - {{ countriesToShow[0].maxShippingDays }}
          {{ 'PRODUCT_DETAILS.DAYS' | translate }}
        </div>
        <div class="icon-container syncee-grey-200-bg" [class.is-open]="isOpen">
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayWidth]="el.nativeElement.offsetWidth + 80"
    [cdkConnectedOverlayOffsetX]="-40"
    [cdkConnectedOverlayOffsetY]="12"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    (backdropClick)="isOpen = false"
  >
    <app-product-details-supplier-shipping-overlay
      *ngIf="!!product.SETTINGS"
      [countriesToShow]="countriesToShow"
      [warehouseLocation]="product.SETTINGS.warehouseLocation"
      [isAutoOrder]="product.SUPPLIER.isAutomated"
      [shippingType]="product.SETTINGS.shipping_type"
      [supplierUserId]="product.USER_ID"
      [companyName]="product.SUPPLIER.companyName"
      [taskId]="product.TASK_ID"
      [supplierCurrency]="product.SUPPLIER.paymentCurrency"
      [hasPreferredCountry]="hasPreferredCountry"
      class="overlay-container"
    >
    </app-product-details-supplier-shipping-overlay>
  </ng-template>
</ng-container>
<ng-template #free
  ><div>{{ 'PRODUCT_DETAILS.FREE' | translate }}</div></ng-template
>
<ng-template #specific>
  <div>{{ 'PRODUCT_DETAILS.SPECIFIC_PRICING' | translate }}</div>
</ng-template>
