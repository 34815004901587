import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { RandomImageVo, SupplierGatewayService } from '../../../service/suppliers/supplier-gateway.service';
import { transformToCdnUrl } from '../../../utils/transform-to-cdn-url';
import { Subject } from 'rxjs';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';

@UntilDestroy()
@Component({
  selector: 'app-supplier-card',
  templateUrl: './supplier-card.component.html',
  styleUrls: ['./supplier-card.component.scss'],
})
export class SupplierCardComponent implements OnInit, OnDestroy {
  @Input() supplier: SupplierCard;
  @Input() supplierImages: string[];
  @Input() categoryId: number;
  productImageUrls: string[];
  noImageUrl = transformToCdnUrl(`placeholder-images/product_card_placeholder.webp`);
  ltMd: boolean;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private suppliersService: SupplierGatewayService,
    private navigationService: NavigationService,
    private screenManagerService: ScreenManagerService
  ) {}

  ngOnInit(): void {
    this.subscribeToLtMd();
  }

  private subscribeToLtMd(): void {
    this.screenManagerService
      .observeIfBreakpointMatches(BreakPoint.md)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((matches) => (this.ltMd = matches));
  }

  handleImageError(): void {
    if (!!this.supplierImages) {
      this.productImageUrls = this.supplierImages;
    } else {
      this.suppliersService
        .getRandomSupplierProductImages([this.supplier.userId], this.categoryId)
        .pipe(untilDestroyed(this), take(1))
        .subscribe({
          next: (imageVo) => {
            this.setProductImages(imageVo[0]);
          },
          error: () => {
            this.productImageUrls = [this.noImageUrl];
          },
        });
    }
  }

  handleFallbackImageError(): void {
    this.productImageUrls = [this.noImageUrl];
  }

  navigateToStorefront(): void {
    this.navigationService.navigateToStorefront(this.supplier.userId, this.supplier.handle, !this.ltMd);
  }

  private setProductImages(randomImageVo: RandomImageVo): void {
    if (!!randomImageVo.images.length) {
      this.productImageUrls = randomImageVo.images;
    } else {
      this.productImageUrls = [this.noImageUrl];
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

export interface SupplierCard {
  userId: number;
  companyName: string;
  mainCategoryIds: number[];
  mainWarehouseCountry: string;
  handle: string;
}
