// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file requests/v1/product_search_request.proto (package requests.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../buf/validate/validate_pb";
import { file_requests_v1_email_send_request } from "./email_send_request_pb";
import type { Ecom } from "../../ecom_pb";
import { file_ecom } from "../../ecom_pb";
import type { Value } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_struct } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file requests/v1/product_search_request.proto.
 */
export const file_requests_v1_product_search_request: GenFile = /*@__PURE__*/
  fileDesc("CihyZXF1ZXN0cy92MS9wcm9kdWN0X3NlYXJjaF9yZXF1ZXN0LnByb3RvEgtyZXF1ZXN0cy52MSLXBQoUUHJvZHVjdFNlYXJjaFJlcXVlc3QSEwoGc2VhcmNoGAEgASgJSACIAQESDwoHdXNlcl9pZBgCIAEoBBIVCghjYXRlZ29yeRgDIAEoDUgBiAEBEhAKCHNoaXBzX3RvGAQgAygJEh8KEndhcmVob3VzZV9sb2NhdGlvbhgFIAEoCUgCiAEBEhoKDXN1cHBsaWVyX25hbWUYBiABKAlIA4gBARIYCgRlY29tGAcgASgLMgUuRWNvbUgEiAEBEjEKDHF1ZXJ5X2ZpbHRlchgIIAEoCzIWLmdvb2dsZS5wcm90b2J1Zi5WYWx1ZUgFiAEBEhEKBGZyb20YCSABKA1IBogBARIRCgRzaXplGAogASgNSAeIAQESFwoKc29ydF9maWVsZBgLIAEoCUgIiAEBEi8KCnNvcnRfb3JkZXIYDCABKA4yFi5yZXF1ZXN0cy52MS5Tb3J0T3JkZXJICYgBARIWCglpc19oaWRkZW4YDSABKAhICogBARIXCgp2aXNpYmlsaXR5GA4gASgISAuIAQESGQoMY291bnRyeV9jb2RlGA8gASgJSAyIAQESMQoLc2VhcmNoX3R5cGUYECABKA4yFy5yZXF1ZXN0cy52MS5TZWFyY2hUeXBlSA2IAQESFgoOZXhjbHVkZV9maWVsZHMYESADKAkSFgoOaW5jbHVkZV9maWVsZHMYEiADKAlCCQoHX3NlYXJjaEILCglfY2F0ZWdvcnlCFQoTX3dhcmVob3VzZV9sb2NhdGlvbkIQCg5fc3VwcGxpZXJfbmFtZUIHCgVfZWNvbUIPCg1fcXVlcnlfZmlsdGVyQgcKBV9mcm9tQgcKBV9zaXplQg0KC19zb3J0X2ZpZWxkQg0KC19zb3J0X29yZGVyQgwKCl9pc19oaWRkZW5CDQoLX3Zpc2liaWxpdHlCDwoNX2NvdW50cnlfY29kZUIOCgxfc2VhcmNoX3R5cGUqTAoKU2VhcmNoVHlwZRIXChNTRUFSQ0hfVFlQRV9VTktOT1dOEAASDQoJRlVMTF9URVhUEAESCgoGVkVDVE9SEAISCgoGSFlCUklEEAMqNgoJU29ydE9yZGVyEhYKElNPUlRfT1JERVJfVU5LTk9XThAAEggKBERFU0MQARIHCgNBU0MQAkKIAQoPY29tLnJlcXVlc3RzLnYxQhlQcm9kdWN0U2VhcmNoUmVxdWVzdFByb3RvUAFaDS4vcGIvcmVxdWVzdHOiAgNSWFiqAgtSZXF1ZXN0cy5WMcoCC1JlcXVlc3RzXFYx4gIXUmVxdWVzdHNcVjFcR1BCTWV0YWRhdGHqAgxSZXF1ZXN0czo6VjFiBnByb3RvMw", [file_buf_validate_validate, file_requests_v1_email_send_request, file_ecom, file_google_protobuf_struct]);

/**
 * @generated from message requests.v1.ProductSearchRequest
 */
export type ProductSearchRequest = Message<"requests.v1.ProductSearchRequest"> & {
  /**
   * @generated from field: optional string search = 1;
   */
  search?: string;

  /**
   * @generated from field: uint64 user_id = 2;
   */
  userId: bigint;

  /**
   * @generated from field: optional uint32 category = 3;
   */
  category?: number;

  /**
   * @generated from field: repeated string ships_to = 4;
   */
  shipsTo: string[];

  /**
   * @generated from field: optional string warehouse_location = 5;
   */
  warehouseLocation?: string;

  /**
   * @generated from field: optional string supplier_name = 6;
   */
  supplierName?: string;

  /**
   * @generated from field: optional Ecom ecom = 7;
   */
  ecom?: Ecom;

  /**
   * @generated from field: optional google.protobuf.Value query_filter = 8;
   */
  queryFilter?: Value;

  /**
   * @generated from field: optional uint32 from = 9;
   */
  from?: number;

  /**
   * @generated from field: optional uint32 size = 10;
   */
  size?: number;

  /**
   * @generated from field: optional string sort_field = 11;
   */
  sortField?: string;

  /**
   * @generated from field: optional requests.v1.SortOrder sort_order = 12;
   */
  sortOrder?: SortOrder;

  /**
   * @generated from field: optional bool is_hidden = 13;
   */
  isHidden?: boolean;

  /**
   * @generated from field: optional bool visibility = 14;
   */
  visibility?: boolean;

  /**
   * @generated from field: optional string country_code = 15;
   */
  countryCode?: string;

  /**
   * @generated from field: optional requests.v1.SearchType search_type = 16;
   */
  searchType?: SearchType;

  /**
   * @generated from field: repeated string exclude_fields = 17;
   */
  excludeFields: string[];

  /**
   * @generated from field: repeated string include_fields = 18;
   */
  includeFields: string[];
};

/**
 * Describes the message requests.v1.ProductSearchRequest.
 * Use `create(ProductSearchRequestSchema)` to create a new message.
 */
export const ProductSearchRequestSchema: GenMessage<ProductSearchRequest> = /*@__PURE__*/
  messageDesc(file_requests_v1_product_search_request, 0);

/**
 * @generated from enum requests.v1.SearchType
 */
export enum SearchType {
  /**
   * @generated from enum value: SEARCH_TYPE_UNKNOWN = 0;
   */
  SEARCH_TYPE_UNKNOWN = 0,

  /**
   * @generated from enum value: FULL_TEXT = 1;
   */
  FULL_TEXT = 1,

  /**
   * @generated from enum value: VECTOR = 2;
   */
  VECTOR = 2,

  /**
   * @generated from enum value: HYBRID = 3;
   */
  HYBRID = 3,
}

/**
 * Describes the enum requests.v1.SearchType.
 */
export const SearchTypeSchema: GenEnum<SearchType> = /*@__PURE__*/
  enumDesc(file_requests_v1_product_search_request, 0);

/**
 * @generated from enum requests.v1.SortOrder
 */
export enum SortOrder {
  /**
   * @generated from enum value: SORT_ORDER_UNKNOWN = 0;
   */
  SORT_ORDER_UNKNOWN = 0,

  /**
   * @generated from enum value: DESC = 1;
   */
  DESC = 1,

  /**
   * @generated from enum value: ASC = 2;
   */
  ASC = 2,
}

/**
 * Describes the enum requests.v1.SortOrder.
 */
export const SortOrderSchema: GenEnum<SortOrder> = /*@__PURE__*/
  enumDesc(file_requests_v1_product_search_request, 1);

