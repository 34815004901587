<div [ngStyle]="{ width: size, height: size }" class="icon-container">
  <img
    oncontextmenu="return false"
    src="{{ errorPath ?? (supplierUserId | supplierLogoFromUserid) }}"
    [alt]="(supplierCompanyName ?? 'Supplier') + ' logo'"
    (error)="handleError()"
    width="70"
    height="70"
  />
</div>
