import { SupplierCatalog } from '../../vo/supplier-catalog/supplier-catalog';
import { GetTasksSuccessAction, TaskActionType, TasksAction, UpdateTaskSettingsSuccessAction } from './tasks.actions';

export interface TasksState {
  tasks: SupplierCatalog[];
}

const initialState: TasksState = {
  tasks: null,
};

export function tasksReducer(state = initialState, action: TasksAction): TasksState {
  switch (action.type) {
    case TaskActionType.GET_TASKS_SUCCESS: {
      return setTasks(state, action as GetTasksSuccessAction);
    }
    case TaskActionType.UPDATE_TASK_SETTINGS_SUCCESS: {
      return updateTask(state, action as UpdateTaskSettingsSuccessAction);
    }
    default: {
      return state;
    }
  }
}

function setTasks(state: TasksState, action: GetTasksSuccessAction): TasksState {
  return {
    ...state,
    tasks: action.payload,
  };
}

function updateTask(state: TasksState, action: UpdateTaskSettingsSuccessAction): TasksState {
  return {
    ...state,
    tasks: state.tasks.map((task) => (task.id == action.payload.id ? { ...task, ...action.payload } : task)),
  };
}
