import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { takeUntil } from 'rxjs/operators';
import { DoubleBorderIconComponent } from '../double-border-icon/double-border-icon.component';
import { ColorPaletteService } from '../../../service/color-palette/color-palette.service';
import { SectionTitleComponent } from '../../../static/components/section-title/section-title.component';

@Component({
  selector: 'app-stepflow-row',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    TranslateModule,
    DoubleBorderIconComponent,
    SectionTitleComponent,
  ],
  templateUrl: './stepflow-row.component.html',
  styleUrls: ['./stepflow-row.component.scss'],
})
export class StepflowRowComponent implements OnInit {
  @Input() titleKey: string;
  @Input() descriptionKey: string;
  @Input() descriptionInterpolation: { [key: string]: unknown } = {};
  @Input() steps: StepflowItem[];
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;

  ltMd: boolean;

  private _unsubscribeAll: Subject<void>;

  constructor(private screenManagerService: ScreenManagerService, public colorPaletteService: ColorPaletteService) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this._unsubscribeAll), this.screenManagerService.stateMatchesOperator())
      .subscribe((matched) => {
        this.ltMd = matched;
      });
  }
}

export interface StepflowItem {
  icon: string;
  titleKey: string;
  descriptionKey: string;
}
