import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RetailerNavigation } from '../../../../navigation/navigation-routes/retailer/retailer.navigation';
import { EcomVO } from '../../../../service/ecom/ecom.service';
import { User } from '../../../../service/user/user';
import {
  DATAFEED_STARTER_PLAN_ID,
  ECOMTYPES_WITHOUT_FREE_TRIAL,
  MARKETPLACE_STARTER_PLAN_ID,
} from '../../../../utils/Constants';
import { getPlatformByType } from '../../../../utils/platforms/platform-config';
import { Utils } from '../../../../utils/utils';
import { EcomTypeEnum } from '../../../../vo/enums/ecom-type-enum';
import { SubscriptionDF, SubscriptionRMP, Subscriptions } from '../../../../vo/subscription-vo';
import { PaymentProvider } from '../../model/vo/provider-props';
import { SubscriptionDataVO } from '../../model/vo/subscription-data';
import { CouponResponseData } from '../model/coupon-response';
import { CycleType } from '../model/cycle-type';
import { DataFeedPlanData, MarketplacePlanData, SubscriptionPlan, SubscriptionPlans } from '../model/subscirption-plan';
import { SubscriptionType } from '../model/subscription-type';

@Injectable({ providedIn: 'root' })
export class SubscriptionUtilityService {
  constructor(private router: Router) {}

  isStarterPlan(planId: number): boolean {
    return planId === MARKETPLACE_STARTER_PLAN_ID || planId === DATAFEED_STARTER_PLAN_ID;
  }

  isFreeTrialAvailable(ecomType: EcomTypeEnum): boolean {
    return !ECOMTYPES_WITHOUT_FREE_TRIAL.includes(ecomType);
  }

  getOriginalPrice(plan: SubscriptionPlan<DataFeedPlanData | MarketplacePlanData>, cycle: CycleType): number {
    if (!plan) {
      return null;
    }
    if (cycle === 'annual') {
      if (plan.planData.hasAnnual) {
        return plan.productYearlyPrice;
      } else {
        return null;
      }
    } else {
      return plan.productMonthlyPrice;
    }
  }

  getCouponFromEcom(ecom: EcomVO, hasSubChanged: boolean, type: SubscriptionType): Partial<CouponResponseData> {
    if (!!ecom && !hasSubChanged) {
      return ecom.subscriptions[type === 'marketplace' ? 'rmp' : 'df']?.coupon;
    } else {
      return null;
    }
  }

  getDiscountedPrice(coupon: Partial<CouponResponseData>, originalPrice: number, planId: number): number {
    if (!!coupon && coupon.plans.includes(planId)) {
      if (coupon.type === 'fixed') {
        return originalPrice - coupon.amount;
      } else {
        return Utils.truncateByDecimalPlace(originalPrice - (originalPrice * coupon.amount) / 100, 2);
      }
    } else {
      return null;
    }
  }

  getDiscountValue(coupon: Partial<CouponResponseData>, originalPrice: number, planId: number): number | undefined {
    if (!coupon || !coupon.plans.includes(planId)) {
      return undefined;
    }
    if (coupon.type === 'fixed') {
      return originalPrice - coupon.amount > 0 ? coupon.amount : originalPrice;
    } else {
      return Utils.truncateByDecimalPlace((originalPrice * coupon.amount) / 100, 2);
    }
  }

  needsUpgrade(subscription: SubscriptionRMP | SubscriptionDF): boolean {
    return (Number(subscription.usedProductNumber) / Number(subscription.maxProductsNumber)) * 100 >= 60;
  }

  getAvailableSubscriptionPlans = (plans: SubscriptionPlans, ecom: EcomVO): SubscriptionPlans => {
    const marketplace = plans.marketplace.filter(
      (plan) => !!plan.planStatus || plan.productId === ecom?.subscriptions?.rmp?.planId
    );
    const dataFeed = plans.dataFeed.filter(
      (plan) => !!plan.planStatus || plan.productId === ecom?.subscriptions?.df?.planId
    );
    return { marketplace, dataFeed };
  };

  getNextAvailablePlan = (
    allPlans: SubscriptionPlans,
    currentPlanId: number,
    cycle: CycleType,
    type: SubscriptionType
  ): SubscriptionPlan<DataFeedPlanData | MarketplacePlanData> => {
    let nextAvailablePlan: SubscriptionPlan<DataFeedPlanData | MarketplacePlanData>;
    if (type === 'dataFeed') {
      const upgradeFromArray =
        cycle === 'monthly' ? allPlans.dataFeed : allPlans.dataFeed.filter((plan) => plan.planData.hasAnnual);
      nextAvailablePlan = upgradeFromArray.find((plan) => plan.productId > currentPlanId);
    } else {
      const upgradeFromArray =
        cycle === 'monthly' ? allPlans.marketplace : allPlans.marketplace.filter((plan) => plan.planData.hasAnnual);
      nextAvailablePlan = upgradeFromArray.find((plan) => plan.productId > currentPlanId);
    }
    return nextAvailablePlan;
  };

  getNextAvailablePlanByProductNumber = (
    allPlans: SubscriptionPlans,
    currentPlanProductNumber: number,
    cycle: CycleType,
    type: SubscriptionType
  ): SubscriptionPlan<DataFeedPlanData | MarketplacePlanData> => {
    let nextAvailablePlan: SubscriptionPlan<DataFeedPlanData | MarketplacePlanData>;
    if (type === 'dataFeed') {
      const upgradeFromArray =
        cycle === 'monthly' ? allPlans.dataFeed : allPlans.dataFeed.filter((plan) => plan.planData.hasAnnual);
      nextAvailablePlan = upgradeFromArray.find(
        (plan) => plan.planData.managedProductsLimit > currentPlanProductNumber
      );
    } else {
      const upgradeFromArray =
        cycle === 'monthly' ? allPlans.marketplace : allPlans.marketplace.filter((plan) => plan.planData.hasAnnual);
      nextAvailablePlan = upgradeFromArray.find(
        (plan) => plan.planData.managedProductsLimit > currentPlanProductNumber
      );
    }
    return nextAvailablePlan;
  };

  getMatchingPlan = (
    planId: number,
    plans: SubscriptionPlans,
    type: SubscriptionType
  ): SubscriptionPlan<DataFeedPlanData | MarketplacePlanData> => {
    if (type === 'marketplace') {
      return plans.marketplace.find((plan) => plan.productId === Number(planId));
    } else {
      return plans.dataFeed.find((plan) => plan.productId === Number(planId));
    }
  };

  createShopifyPaymentProvider = (ecom: EcomVO, user: User): PaymentProvider => {
    return {
      paymentProviderName: 'shopify',
      ecom: ecom,
      cardDetails: null,
      hasAccount: null,
      savePaymentProviderRest: null,
      changeCardRest: null,
      userDetails: user,
    };
  };

  hasSubChanged = (
    ecom: EcomVO,
    cycle: CycleType,
    selectedMarketplacePlanId: number,
    selectedDataFeedPlanId: number
  ): boolean => {
    return ecom
      ? (!Utils.isNullOrUndefined(ecom.subscribed) && new Date(ecom.subscribed) < new Date()) ||
          (cycle !== ecom.cycle &&
            ((ecom.subscriptions.rmp.planId != MARKETPLACE_STARTER_PLAN_ID &&
              selectedMarketplacePlanId != MARKETPLACE_STARTER_PLAN_ID) ||
              (ecom.subscriptions.df.planId != DATAFEED_STARTER_PLAN_ID &&
                selectedDataFeedPlanId != DATAFEED_STARTER_PLAN_ID))) ||
          selectedMarketplacePlanId !== ecom.subscriptions.rmp.planId ||
          selectedDataFeedPlanId !== ecom.subscriptions.df.planId ||
          !!ecom.subscriptions.df.churnDate ||
          !!ecom.subscriptions.rmp.churnDate
      : false;
  };

  isOnlyMarketplacePlan(ecomType: string | EcomTypeEnum): boolean {
    return !getPlatformByType(ecomType as EcomTypeEnum).options.hasDataFeed;
  }

  isPlanAvailAble(plan: SubscriptionPlan<MarketplacePlanData | DataFeedPlanData>, cycle: CycleType): boolean {
    if (cycle === 'annual') {
      return plan.planData.hasAnnual;
    } else {
      return true;
    }
  }

  currentPlansAreAlreadyCancelled(ecom: EcomVO, selectedMpPlanId: number, selectedDfPlanId: number): boolean {
    return this.isOnlyMarketplacePlan(ecom.ecomType)
      ? ecom.subscriptions.rmp.churnDate && this.isStarterPlan(selectedMpPlanId)
      : ecom.subscriptions.rmp.churnDate &&
          this.isStarterPlan(selectedMpPlanId) &&
          ecom.subscriptions.df.churnDate &&
          this.isStarterPlan(selectedDfPlanId);
  }

  getSubscriptionData(
    ecom: EcomVO,
    user: User,
    cycle: CycleType,
    marketplacePlan: SubscriptionPlan<MarketplacePlanData>,
    dataFeedPlan: SubscriptionPlan<DataFeedPlanData>,
    subChanged: boolean,
    couponData: CouponResponseData
  ): SubscriptionDataVO {
    const rmpPrice = this.getOriginalPrice(marketplacePlan, cycle);
    const dfPrice = this.getOriginalPrice(dataFeedPlan, cycle);
    const rmpCoupon =
      this.getCouponFromEcom(ecom, subChanged, 'marketplace') ??
      (couponData?.plans?.includes(marketplacePlan.productId) ? couponData : null);
    const dataFeedCoupon =
      this.getCouponFromEcom(ecom, subChanged, 'dataFeed') ??
      (couponData?.plans?.includes(dataFeedPlan.productId) ? couponData : null);
    return {
      ecomId: ecom.id,
      userDetails: user,
      cycle: cycle,
      subscribeData: {
        marketplacePlanId: { planId: marketplacePlan.productId, couponId: rmpCoupon?.id ? String(rmpCoupon.id) : null },
        dataFeedPlanId: {
          planId: dataFeedPlan.productId,
          couponId: dataFeedCoupon?.id ? String(dataFeedCoupon.id) : null,
        },
        amount: rmpPrice + dfPrice,
      },
    };
  }

  redirectToCheckoutWithUpgrade(): void {
    this.router.navigate([RetailerNavigation.BILLING.CHECKOUT], {
      queryParams: {
        upgrade: true,
      },
    });
  }

  getMaximumAnnualSaving(plans: SubscriptionPlans): number {
    const savings = plans.marketplace
      .filter((plan) => plan.planData.hasAnnual && plan.productId !== MARKETPLACE_STARTER_PLAN_ID)
      .map(
        (plan) => ((plan.productMonthlyPrice * 12 - plan.productYearlyPrice) / (plan.productMonthlyPrice * 12)) * 100
      );
    const highestSaving = Math.max(...savings);

    return Math.round(highestSaving);
  }

  public hasFreeTrialExpired(subscriptions: Subscriptions): boolean {
    return Date.parse(this.getValidTrialExpiry(subscriptions)) < Date.now();
  }

  public getValidTrialExpiry(subscriptions: Subscriptions): string {
    return subscriptions.rmp.freeTrialUntil ?? subscriptions.df.freeTrialUntil;
  }
}
