import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { RcatalogService } from 'app/service/rcatalog/rcatalog.service';
import { Catalog } from 'app/vo/catalog';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { switchMapWith } from '../../utils/operator/switch-map-with';
import {
  ClearSelectedCatalogAction,
  DeleteCatalogAction,
  GetCatalogsSuccessAction,
  GetSelectedCatalogProductNumbersStartAction,
  GetSelectedCatalogProductNumbersSuccessAction,
  GetUsedSupplierUserIdsStartAction,
  GetUsedSupplierUserIdsSuccessAction,
  RCatalogsActionTypes,
  SetSelectedCatalogAction,
} from './rcatalogs.action';
import { selectedCatalogSelector } from './rcatalogs.selector';
import { RetailerProductsService } from '../../main/marketplace/retailer-catalogs/retailer-catalog-details/retailer-products/retailer-products.service';
import { omitNullOrUndefined } from '../../utils/operator/omit-null-or-undefined';

@Injectable()
export class RCatalogsEffects {
  constructor(
    private actions$: Actions,
    private rcatalogService: RcatalogService,
    private store: Store<AppState>,
    private retailerProductsService: RetailerProductsService
  ) {}

  UserPreferencesStart: Observable<GetCatalogsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(RCatalogsActionTypes.GET_RCATALOGS_START),
      switchMap(() => this.rcatalogService.fetchCatalogs().pipe(catchError(() => of(null)))),
      map((catalogs: Catalog[]) => new GetCatalogsSuccessAction(catalogs))
    )
  );

  SetSelectedImportList: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RCatalogsActionTypes.setSelectedCatalog),
      tap((action: SetSelectedCatalogAction) => {
        localStorage.setItem('selectedImportList', JSON.stringify(action.payload));
      }),
      map((action) => new GetSelectedCatalogProductNumbersStartAction(Number(action.payload.id)))
    )
  );

  ClearSelectedCatalog: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RCatalogsActionTypes.clearSelectedCatalog),
        tap(() => localStorage.removeItem('selectedImportList'))
      ),
    { dispatch: false }
  );

  HandleDeleteImportList: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RCatalogsActionTypes.deleteCatalog),
      map((action: DeleteCatalogAction) => action.payload),
      switchMapWith(() => this.store.select(selectedCatalogSelector).pipe(take(1))),
      filter(
        ([importListToDelete, selectedImportList]) => Number(importListToDelete) === Number(selectedImportList.id)
      ),
      map(() => new ClearSelectedCatalogAction())
    )
  );

  UsedSupplierUserIdsStart: Observable<GetUsedSupplierUserIdsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(RCatalogsActionTypes.GET_USED_SUPPLIER_USER_IDS_START),
      map((action: GetUsedSupplierUserIdsStartAction) => action.payload),
      switchMapWith((ecomId) => this.rcatalogService.fetchUsedSuppliers(ecomId).pipe(catchError(() => of(null)))),
      map(([ecomId, userIds]) => new GetUsedSupplierUserIdsSuccessAction({ [ecomId]: userIds }))
    )
  );

  RcatalogSuccess: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RCatalogsActionTypes.GET_RCATALOGS_SUCCESS),
      map((action: GetCatalogsSuccessAction) => action.payload),
      switchMap((catalogs) =>
        this.store.select(selectedCatalogSelector).pipe(
          omitNullOrUndefined(),
          take(1),
          map((selectedCatalog) => catalogs.find((catalog) => catalog.id === selectedCatalog.id))
        )
      ),
      omitNullOrUndefined(),
      map((selectedCatalog) => Number(selectedCatalog.id)),
      switchMap((catalogId) =>
        this.retailerProductsService.getTotalImportListByCatalogId(catalogId).pipe(catchError(() => of(null)))
      ),
      map((productNumber: number) => new GetSelectedCatalogProductNumbersSuccessAction(productNumber))
    )
  );

  GetSelectedCatalogProductNumberStart: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RCatalogsActionTypes.GET_SELECTED_CATALOG_PRODUCT_NUMBERS_START),
      map((action: GetSelectedCatalogProductNumbersStartAction) => action.payload),
      switchMap((catalogId) =>
        this.retailerProductsService.getTotalImportListByCatalogId(catalogId).pipe(catchError(() => of(null)))
      ),
      map((productNumber: number) => new GetSelectedCatalogProductNumbersSuccessAction(productNumber))
    )
  );
}
