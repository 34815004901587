// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file ecom.proto (syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { AppType } from "./app_type_pb";
import { file_app_type } from "./app_type_pb";
import type { EcomType } from "./ecom_type_pb";
import { file_ecom_type } from "./ecom_type_pb";
import type { UserAccount } from "./user_account_pb";
import { file_user_account } from "./user_account_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file ecom.proto.
 */
export const file_ecom: GenFile = /*@__PURE__*/
  fileDesc("CgplY29tLnByb3RvIqQBCgRFY29tEgoKAmlkGAEgASgEEg4KBmRvbWFpbhgCIAEoCRIaCghhcHBfdHlwZRgDIAEoDjIILkFwcFR5cGUSHAoJZWNvbV90eXBlGAQgASgOMgkuRWNvbVR5cGUSGgoEdXNlchgFIAEoCzIMLlVzZXJBY2NvdW50EhkKDGFjY2Vzc190b2tlbhgGIAEoCUgAiAEBQg8KDV9hY2Nlc3NfdG9rZW5CLgoMY28uc3luY2VlLnBiQglFY29tUHJvdG9QAVoELi9wYsoCClBwbXNcUHJvdG9iBnByb3RvMw", [file_app_type, file_ecom_type, file_user_account]);

/**
 * @generated from message Ecom
 */
export type Ecom = Message<"Ecom"> & {
  /**
   * @generated from field: uint64 id = 1;
   */
  id: bigint;

  /**
   * @generated from field: string domain = 2;
   */
  domain: string;

  /**
   * @generated from field: AppType app_type = 3;
   */
  appType: AppType;

  /**
   * @generated from field: EcomType ecom_type = 4;
   */
  ecomType: EcomType;

  /**
   * @generated from field: UserAccount user = 5;
   */
  user?: UserAccount;

  /**
   * @generated from field: optional string access_token = 6;
   */
  accessToken?: string;
};

/**
 * Describes the message Ecom.
 * Use `create(EcomSchema)` to create a new message.
 */
export const EcomSchema: GenMessage<Ecom> = /*@__PURE__*/
  messageDesc(file_ecom, 0);

