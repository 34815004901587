// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file requests/v1/email_send_request.proto (package requests.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../buf/validate/validate_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file requests/v1/email_send_request.proto.
 */
export const file_requests_v1_email_send_request: GenFile = /*@__PURE__*/
  fileDesc("CiRyZXF1ZXN0cy92MS9lbWFpbF9zZW5kX3JlcXVlc3QucHJvdG8SC3JlcXVlc3RzLnYxIn4KEEVtYWlsU2VuZFJlcXVlc3QSHgoIcmVwbHlfdG8YASABKAlCB7pIBHICYAFIAIgBARIeCgdzdWJqZWN0GAIgASgJQgi6SAVyAxiAAUgBiAEBEhEKCW1haWxfYm9keRgDIAEoCUILCglfcmVwbHlfdG9CCgoIX3N1YmplY3RChAEKD2NvbS5yZXF1ZXN0cy52MUIVRW1haWxTZW5kUmVxdWVzdFByb3RvUAFaDS4vcGIvcmVxdWVzdHOiAgNSWFiqAgtSZXF1ZXN0cy5WMcoCC1JlcXVlc3RzXFYx4gIXUmVxdWVzdHNcVjFcR1BCTWV0YWRhdGHqAgxSZXF1ZXN0czo6VjFiBnByb3RvMw", [file_buf_validate_validate]);

/**
 * @generated from message requests.v1.EmailSendRequest
 */
export type EmailSendRequest = Message<"requests.v1.EmailSendRequest"> & {
  /**
   * @generated from field: optional string reply_to = 1;
   */
  replyTo?: string;

  /**
   * @generated from field: optional string subject = 2;
   */
  subject?: string;

  /**
   * @generated from field: string mail_body = 3;
   */
  mailBody: string;
};

/**
 * Describes the message requests.v1.EmailSendRequest.
 * Use `create(EmailSendRequestSchema)` to create a new message.
 */
export const EmailSendRequestSchema: GenMessage<EmailSendRequest> = /*@__PURE__*/
  messageDesc(file_requests_v1_email_send_request, 0);

