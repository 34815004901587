import { select, Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Observable } from 'rxjs';
import { RcatalogsState } from '../../store/rcatalogs/rcatalogs.reducer';
import { Catalog } from '../../vo/catalog';
import { map, take } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';
import { Injectable } from '@angular/core';
import { marketplaceCatalogsSelector } from '../../store/rcatalogs/rcatalogs.selector';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';

@Injectable({
  providedIn: 'root',
})
export class RcatalogService {
  private readonly rcatalogsStore$: Observable<RcatalogsState>;

  constructor(
    private restService: RestService,
    private springRestService: SpringRestService,
    private store: Store<AppState>
  ) {
    this.rcatalogsStore$ = this.store.pipe(select((state) => state.rcatalogs));
  }

  fetchCatalogs(ecomId?: number): Observable<Catalog[]> {
    const restData = ecomId ? { ecomId } : undefined;
    return this.restService.get(`RetailerCatalogService/getRetailersCatalogs`, restData).pipe(
      map((response) => {
        return response.getData();
      })
    );
  }

  getCatalogs(): Catalog[] {
    let catalogs: Catalog[];
    this.rcatalogsStore$
      .pipe(
        select((state) => state.catalogs),
        take(1)
      )
      .subscribe((cats) => (catalogs = cats));
    return catalogs;
  }

  getMarketplaceCatalogs(): Catalog[] {
    let catalogs: Catalog[];
    this.store
      .select(marketplaceCatalogsSelector)
      .pipe(take(1))
      .subscribe((cats) => (catalogs = cats));
    return catalogs;
  }

  getSelectedCatalog(): Catalog {
    let catalog: Catalog;
    this.rcatalogsStore$
      .pipe(
        select((state) => state.selectedCatalog),
        take(1)
      )
      .subscribe((cat) => (catalog = cat));
    return catalog;
  }

  fetchUsedSuppliers(ecomId: number): Observable<number[]> {
    return this.springRestService.get(
      MicroserviceNames.USER,
      `/Retailer/Catalog/Ecom/${ecomId}/UsedSupplierUserIds`,
      null,
      true
    );
  }
}
