import { Utils } from 'app/utils/utils';

export class CommonMapper {
  public static mapBigintIntoNumber(value: bigint): number {
    if (Utils.isNullOrUndefinedOrLengthZero(value)) {
      return null;
    }
    return Number(value);
  }

  public static mapStringIntoNumber(value: string): number {
    if (Utils.isNullOrUndefinedOrLengthZero(value)) {
      return null;
    }
    return Number(value);
  }

  public static mapBigintIntoString(value: bigint): string {
    if (Utils.isNullOrUndefinedOrLengthZero(value)) {
      return null;
    }
    return value.toString();
  }

  public static mapNumberIntoBigint(value: number): bigint {
    if (Utils.isNullOrUndefinedOrLengthZero(value)) {
      return null;
    }
    return BigInt(value);
  }
}
