import { Pipe, PipeTransform } from '@angular/core';
import { PlanBox } from '../components/plan-upsell-box/plan-upsell-box.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MarketplaceEcomService } from '../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { EcomVO } from '../../service/ecom/ecom.service';
import { Utils } from '../../utils/utils';

@Pipe({
  name: 'upsellBoxButtonText',
  standalone: true,
})
export class UpsellBoxButtonTextPipe implements PipeTransform {
  constructor(private translateService: TranslateService, private marketplaceEcomService: MarketplaceEcomService) {}

  transform(plan: PlanBox, ecom: EcomVO, annual: boolean): Observable<string> {
    if (Utils.isNullOrUndefined(ecom)) {
      return this.translateService.stream('PLAN_UPSELL_BOXES.CONNECT_STORE');
    }

    const currentPlanId = ecom?.subscriptions?.rmp?.planId;
    const currentPlanIsAnnual = ecom?.cycle !== 'monthly';

    const hasTrial = this.marketplaceEcomService.isFreeTrialAvailable(ecom);

    switch (true) {
      case currentPlanId === plan.id && currentPlanIsAnnual === annual:
        return this.translateService.stream('PLAN_UPSELL_BOXES.BUTTON.CURRENT_PLAN');
      case hasTrial && !annual:
        return this.translateService.stream('PLAN_UPSELL_BOXES.BUTTON.START_FOR_FREE');
      default:
        return this.translateService.stream('PLAN_UPSELL_BOXES.BUTTON.CHANGE_PLAN');
    }
  }
}
