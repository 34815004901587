import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import type { AppState } from '../../../app.state';
import { Catalog } from 'app/vo/catalog';
import { importListsByProductIdSelector } from 'app/store/rcatalogs/rcatalogs.selector';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { Utils } from 'app/utils/utils';
import { selectedRetailerEcomSelector } from 'app/store/ecom/ecom.selector';

@Component({
  selector: 'app-product-page-add-to-import-list',
  templateUrl: './product-page-add-to-import-list.component.html',
  styleUrls: ['./product-page-add-to-import-list.component.scss'],
})
export class ProductPageAddToImportListComponent implements OnInit, OnDestroy {
  @Input() productId: string;
  @Input() approveNeeded: boolean;
  @Input() catalogIsConnected: boolean;

  @Output() addToClick = new EventEmitter<void>();

  public currentEcom$ = this.store.select(selectedRetailerEcomSelector).pipe(omitNullOrUndefined());

  public addedToImportList: boolean;
  public htmlCatalogList: string[];

  private importLists: Catalog[];
  private unsubscribe = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  public ngOnInit(): void {
    this.initImportListObservable();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initImportListObservable(): void {
    this.store
      .select(importListsByProductIdSelector(this.productId))
      .pipe(takeUntil(this.unsubscribe), omitNullOrUndefined())
      .subscribe((importLists: Catalog[]): void => {
        this.importLists = importLists;
        this.addedToImportList = !Utils.isNullOrUndefinedOrLengthZero(importLists);
        this.htmlCatalogList = this.getHtmlCatalogList();
      });
  }

  public handleAddToClick(): void {
    if (!this.addedToImportList) {
      return this.addToClick.emit();
    }
  }

  private getHtmlCatalogList(): string[] {
    return (
      this.importLists?.map?.((catalog: Catalog) => {
        return `<a href="/retailer/import-list/${catalog.id}" target="_blank">${catalog.name}</a>`;
      }) ?? []
    );
  }
}
