import { FaqItem } from '../../vo/faq/faq-item';
import { FaqSection } from '../../vo/faq/faq-section';
import { compact, orderBy } from 'lodash';

class FaqSectionTranslationMapper {
  static map(translation: { [key: string]: object | string }, hideKeys: string[] = []): FaqSection {
    return {
      id: translation['ID'] as string,
      titleKey: translation['TITLE'] as string,
      items: {
        left: this.mapItem(translation['ITEMS']['LEFT'], hideKeys),
        right: this.mapItem(translation['ITEMS']['RIGHT'], hideKeys),
      },
    };
  }

  private static mapItem(items: { [key: string]: { TITLE: string; VALUE: string } }, hideKeys: string[]): FaqItem[] {
    return compact(
      orderBy(
        Object.entries(items).map(([key, item]) =>
          hideKeys.includes(key) ? null : { title: item.TITLE, text: item.VALUE, key }
        ),
        ['key'],
        ['asc']
      )
    );
  }
}

export default FaqSectionTranslationMapper;
