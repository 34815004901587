<ng-container *ngIf="!!(currentEcom$ | async); else noEcom">
  <button
    mat-button
    *ngIf="addedToImportList !== true; else isInCatalogAlready"
    class="syncee-blue-button"
    [style.cursor]="addedToImportList ? 'unset' : 'pointer'"
    [style.width]="'100%'"
    [disabled]="addedToImportList === undefined"
    (click)="handleAddToClick()"
  >
    {{
      approveNeeded && !catalogIsConnected
        ? ('PRODUCT_PAGE.SEND_REQUEST' | translate)
        : ('PRODUCT_PAGE.ADD_TO_IMPORT_LIST' | translate)
    }}
  </button>
</ng-container>

<ng-template #noEcom>
  <app-alert-multi
    type="info"
    [messages]="['PRODUCT_PAGE.CONNECT_STORE' | translate]"
    [readMoreLink]="
      '<a href=\'retailer/settings/my-shops\' target=\'_blank\'>' +
      ('PRODUCT_PAGE.CONNECT_YOUR_STORE_HERE' | translate) +
      '</a>'
    "
  ></app-alert-multi>
</ng-template>

<ng-template #isInCatalogAlready>
  <app-alert-multi
    type="info"
    [messages]="htmlCatalogList.concat(['PRODUCT_PAGE.ALREADY_ADDED_TO_IMPORT_LIST' | translate]).reverse()"
  ></app-alert-multi>
</ng-template>
