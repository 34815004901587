import { Injectable } from '@angular/core';
import { Action, AuthorizationEcomService, PermissionPayload } from '../authorization-ecom/authorization-ecom.service';
import { MarketplaceEcomService } from '../marketplace/marketplace-ecom/marketplace-ecom.service';
import { CatalogSidebarService } from '../catalog-sidebar/catalog-sidebar.service';
import { ApprovalDialogService } from '../../shared/components/approval-dialog/approval-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import {
  AddToCatalogMenuComponentData,
  AddToImportListMenuComponent,
} from '../../main/marketplace/explore-products/add-to-import-list-menu/add-to-import-list-menu.component';
import { SearchProductVO } from '../../vo/search-product-vo';
import { RcatalogService } from '../rcatalog/rcatalog.service';
import { AppState } from '../../app.state';
import { Store } from '@ngrx/store';
import {
  AddRecentlyAddedProductsAction,
  AddUsedSupplierUserIdAction,
  SelectedCatalogProductNumberIncreaseAction,
  UpdateCatalogFilterAction,
} from '../../store/rcatalogs/rcatalogs.action';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Utils } from 'app/utils/utils';
import { SetupGuideService } from '../../main/setup-guide/service/setup-guide.service';
import { SnippetEnum } from '../../main/setup-guide/enums/snippet-enums';
import { keys, omitBy } from 'lodash';
import {
  selectedCatalogProductNumberSelector,
  usedSupplierUserIdsByEcomIdSelector,
} from '../../store/rcatalogs/rcatalogs.selector';
import { selectedRetailerEcomSelector } from '../../store/ecom/ecom.selector';
import { RetailerCatalogType } from '../../vo/retailer-catalog/retailer-catalog-type';

@Injectable({
  providedIn: 'root',
})
export class AddProductToCatalogService {
  catalogId: number;

  constructor(
    private authorizationService: AuthorizationEcomService,
    private marketplaceEcomService: MarketplaceEcomService,
    private catalogSidebarService: CatalogSidebarService,
    private approvalDialogService: ApprovalDialogService,
    private dialog: MatDialog,
    private rcatalogService: RcatalogService,
    private store: Store<AppState>,
    private setupGuideService: SetupGuideService
  ) {}

  add(product: SearchProductVO, selectedCatalogId?: number): void {
    this.store
      .select(selectedRetailerEcomSelector)
      .pipe(
        switchMap((selectedEcom) =>
          forkJoin({
            usedSupplierUserIds: this.store.select(usedSupplierUserIdsByEcomIdSelector(selectedEcom.id)).pipe(take(1)),
            numberOfProductsInCatalog: this.store.select(selectedCatalogProductNumberSelector).pipe(take(1)),
          })
        ),
        switchMap(({ usedSupplierUserIds, numberOfProductsInCatalog }) =>
          this.hasPermissionToAddToCatalog({
            approveNeeded: product.SETTINGS.approveNeeded,
            premium: product.SETTINGS.premium,
            isHidden: product.IS_HIDDEN,
            usedSupplierUserIds,
            supplierToUse: product.USER_ID,
            catalogType: RetailerCatalogType.SELECTION,
            numberOfProductsInCatalog,
          })
        ),
        take(1)
      )
      .subscribe((hasPermission) => {
        if (!hasPermission.errors?.length) {
          this.handleHasPermissionToAddToCatalog(product);
        } else {
          this.handleNoPermission(hasPermission.errors);
        }
      });
  }

  private handleNoPermission(errors: Action[]): void {
    this.authorizationService.openSubscribeDialog(
      `PLAN_UPSELL.SPECIAL_DESCRIPTION.${errors[0]}`,
      this.marketplaceEcomService.selectedEcom
    );
  }

  private handleHasPermissionToAddToCatalog(product: SearchProductVO): void {
    const catalogIsConnected = !Utils.isNullOrUndefined(
      this.catalogSidebarService.usedCatalogList.find(
        (element) => element.catalogId.toString() === product.TASK_ID.toString()
      )
    );
    if (catalogIsConnected) {
      this.addProductToCatalog(product);
    } else {
      this.handleCatalogIsNotConnected(product);
    }
  }

  private handleCatalogIsNotConnected(product: SearchProductVO): void {
    if (product.SETTINGS.approveNeeded) {
      this.openApprovalDialog(product);
    } else {
      this.addRetailerToSupplierCatalog({ catalogId: product.TASK_ID }).subscribe(() => {
        this.addProductToCatalog(product);
      });
    }
  }

  addRetailerToSupplierCatalog(restData): Observable<boolean> {
    return this.catalogSidebarService.addRetailerToCatalogWithRest(restData).pipe(map(() => true));
  }

  private openApprovalDialog(product: SearchProductVO): void {
    this.approvalDialogService
      .open(
        product.SETTINGS.approveNeeded,
        {
          approveDescription: product.SETTINGS.approveDescription,
          needResponse: product.SETTINGS.needResponse,
          supplierName: product.SUPPLIER.companyName,
          supplierId: product.USER_ID,
          website: product.SUPPLIER.website,
          email: product.SUPPLIER.contactEmail,
          isAutoOrder: product.SUPPLIER.isAutomated,
        },
        product.TASK_ID
      )
      .subscribe((result) => {
        if (result) {
          this.addProductToCatalog(product);
        }
      });
  }

  private addProductToCatalog(product: SearchProductVO): void {
    if (!this.catalogId) {
      const selectedCatalogId = this.rcatalogService.getSelectedCatalog();
      if (!Utils.isNullOrUndefined(selectedCatalogId)) {
        this.addProductToSelectedCatalog(product);
      } else {
        this.authorizationService
          .openUpsellDialogWhenCatalogCreateNumReachedBySelectedEcom()
          .pipe(
            take(1),
            filter((hasPermission) => hasPermission)
          )
          .subscribe(() => {
            this.openCatalogDialog(product);
          });
      }
    } else {
      this.addProductToSelectedCatalog(product, this.catalogId);
    }
  }

  private addProductToSelectedCatalog(product: SearchProductVO, selectedCatalogId?: number): void {
    this.catalogSidebarService.addProductToCatalog(product.ID, selectedCatalogId).subscribe((response) => {
      this.handleProductAddedToCatalog(product, response.getFirstData());
    });
  }

  private handleUsedSuppliers(product: SearchProductVO, ecomId: number): void {
    this.store.dispatch(
      new AddUsedSupplierUserIdAction({
        supplierUserId: product.USER_ID,
        ecomId,
      })
    );
    this.store.dispatch(new SelectedCatalogProductNumberIncreaseAction());
  }

  private handleProductAddedToCatalog(product: SearchProductVO, response): void {
    // TODO: add type to param
    this.store.dispatch(new AddRecentlyAddedProductsAction([{ productId: product.ID, supplierId: product.USER_ID }]));
    this.store.dispatch(new UpdateCatalogFilterAction({ filter: response.filter, catalogId: response.id }));

    this.setupGuideService.setCompletedStep(SnippetEnum.RETAILER_ADD_IMPORT_LIST);

    this.catalogSidebarService.newProductAdded.emit({
      productId: product.ID,
      catalogId: response.id,
      catalogName: response.name,
    });

    this.handleUsedSuppliers(product, response.ecomId);
  }

  private openCatalogDialog(product: SearchProductVO): void {
    const dialogRef = this.dialog.open<AddToImportListMenuComponent, AddToCatalogMenuComponentData>(
      AddToImportListMenuComponent,
      {
        width: '350px',
        disableClose: true,
        data: {
          id: product.ID,
          supplierCatalogID: product.TASK_ID,
          createEmptyCatalog: this.isFirstCatalog(),
          supplierUserId: product.USER_ID,
        },
      }
    );

    dialogRef.afterClosed().pipe(take(1)).subscribe();
  }

  public hasPermissionToAddToCatalog(payload: Partial<PermissionPayload>): Observable<{ errors: Action[] }> {
    return forkJoin({
      [Action.PRODUCT_ADD_TO_CATALOG]: this.authorizationService
        .hasPermissionObs(Action.PRODUCT_ADD_TO_CATALOG, payload)
        .pipe(take(1)),
      [Action.USE_SUPPLIER]: this.authorizationService.hasPermissionObs(Action.USE_SUPPLIER, payload).pipe(take(1)),
    }).pipe(
      map((permissions) => keys(omitBy(permissions, (value) => value))),
      map((permissions) => ({ errors: permissions as Action[] }))
    );
  }

  private isFirstCatalog(): boolean {
    const catalogs = this.rcatalogService.getMarketplaceCatalogs();
    return catalogs === null || catalogs === undefined || catalogs.length === 0;
  }
}
