import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationManagerService } from 'app/main/authentication/authentication-manager.service';
import { AuthDialogType } from 'app/main/authentication/helpers/authentication-dialog-type';
import { CatalogApproveStatus } from 'app/service/catalog-sidebar/catalog-sidebar.service';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { SearchProductVO } from '../../../vo/search-product-vo';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  @Input() product: SearchProductVO;
  @Input() ecomCurrency: string;
  @Input() catalogStatus: CatalogApproveStatus;
  ltMd: boolean;

  private unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private navigationService: NavigationService,
    private authManagerService: AuthenticationManagerService,
    private screenManagerService: ScreenManagerService
  ) {}

  ngOnInit(): void {
    this.subscribeToLtMd();
  }

  navigateToStorefront(): void {
    this.navigationService.navigateToStorefront(this.product.USER_ID, this.product.SUPPLIER?.handle, !this.ltMd);
  }

  navigateToProduct(event: Event): void {
    event.preventDefault();
    this.navigationService.navigateToProduct(this.product.ID, this.product.TITLE, !this.ltMd);
  }

  private subscribeToLtMd(): void {
    this.screenManagerService
      .observeIfBreakpointMatches(BreakPoint.md)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((matches) => (this.ltMd = matches));
  }

  openRegistration(): void {
    this.authManagerService.emitOpenRegistrationDialog(
      AuthDialogType.RETAILER,
      undefined,
      'PRODUCT_CARD_ACTION_DEFAULT.REGISTRATION_REDIRECT.TITLE',
      'PRODUCT_CARD_ACTION_DEFAULT.REGISTRATION_REDIRECT.DESCRIPTION'
    );
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
