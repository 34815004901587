import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { EcomType, Value } from '../model/variables/general.variables';
import {
  PlanType,
  SubscriptionChangeType,
  SubscriptionCycle,
  SubscriptionTrialPeriod,
} from '../model/variables/subscription.variables';

export class SubscriptionChangeGtmAction implements GtmAction {
  event = GtmEvent.subscription_change;

  constructor(public payload: SubscriptionChangeGTMACtionPayload) {}
}

export interface SubscriptionChangeGTMACtionPayload
  extends SubscriptionTrialPeriod,
    SubscriptionCycle,
    PlanType,
    SubscriptionChangeType,
    EcomType,
    Value {}
