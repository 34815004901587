// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file ecom_type.proto (syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file ecom_type.proto.
 */
export const file_ecom_type: GenFile = /*@__PURE__*/
  fileDesc("Cg9lY29tX3R5cGUucHJvdG8qngEKCEVjb21UeXBlEgsKB1VOS05PV04QABILCgdTSE9QSUZZEAESBwoDV0lYEAISDwoLV09PQ09NTUVSQ0UQAxIPCgtCSUdDT01NRVJDRRAEEg4KClNIT1BSRU5URVIQBRIICgRVTkFTEAYSDgoKSlVNUFNFTExFUhAHEg8KC1NRVUFSRVNQQUNFEAgSBwoDRUtNEAkSCQoFRUNXSUQQCkIyCgxjby5zeW5jZWUucGJCDUVjb21UeXBlUHJvdG9QAVoELi9wYsoCClBwbXNcUHJvdG9iBnByb3RvMw");

/**
 * @generated from enum EcomType
 */
export enum EcomType {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: SHOPIFY = 1;
   */
  SHOPIFY = 1,

  /**
   * @generated from enum value: WIX = 2;
   */
  WIX = 2,

  /**
   * @generated from enum value: WOOCOMMERCE = 3;
   */
  WOOCOMMERCE = 3,

  /**
   * @generated from enum value: BIGCOMMERCE = 4;
   */
  BIGCOMMERCE = 4,

  /**
   * @generated from enum value: SHOPRENTER = 5;
   */
  SHOPRENTER = 5,

  /**
   * @generated from enum value: UNAS = 6;
   */
  UNAS = 6,

  /**
   * @generated from enum value: JUMPSELLER = 7;
   */
  JUMPSELLER = 7,

  /**
   * @generated from enum value: SQUARESPACE = 8;
   */
  SQUARESPACE = 8,

  /**
   * @generated from enum value: EKM = 9;
   */
  EKM = 9,

  /**
   * @generated from enum value: ECWID = 10;
   */
  ECWID = 10,
}

/**
 * Describes the enum EcomType.
 */
export const EcomTypeSchema: GenEnum<EcomType> = /*@__PURE__*/
  enumDesc(file_ecom_type, 0);

