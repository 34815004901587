<div class="product-card-list-container" [@animateStagger]="{ value: '50' }">
  <ng-container *ngIf="products && products.length > 0">
    <ng-container *ngFor="let product of products; trackBy: trackByProduct; let last = last; let index = index">
      <app-product-card
        [ngStyle]="{ width: productCardWidth.xl }"
        [ngStyle.xl]="{ width: productCardWidth.xl }"
        [ngStyle.lg]="{ width: productCardWidth.lg }"
        [ngStyle.md]="{ width: productCardWidth.md }"
        [ngStyle.sm]="{ width: productCardWidth.sm }"
        [ngStyle.xs]="{ width: productCardWidth.xs }"
        [product]="product"
        [ecomCurrency]="ecomCurrency"
        [selectable]="selectable"
        [inCatalog]="product.ID | isProductInImportListByProductId | async"
        [catalogStatus]="product.TASK_ID | catalogStatusCheck"
        [selected]="selected | includes : product.ID"
        (selectedChange)="handleSelectionChange(product.ID, $event)"
        [hiddenActionBar]="hiddenActionBar"
        [generateMicrodata]="generateMicrodata"
      ></app-product-card>
      <ng-container *ngIf="hasAds && (adsPositions | includes : index) && isFreePlan">
        <app-explore-products-advertisement
          [ngStyle]="{}"
          [ngStyle.xl]="{ width: productCardWidth.xl }"
          [ngStyle.lg]="{ width: productCardWidth.lg }"
          [ngStyle.md]="{ width: productCardWidth.md }"
          [ngStyle.sm]="{ width: productCardWidth.sm }"
          [ngStyle.xs]="{ width: productCardWidth.xs }"
          class="advertisement"
        ></app-explore-products-advertisement>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="needsFillers">
      <div class="content-fill"></div>
      <div class="content-fill"></div>
      <div class="content-fill"></div>
      <div class="content-fill"></div>
      <div class="content-fill"></div>
    </ng-container>
  </ng-container>
</div>

<!--
<ng-container *ngIf="areProductsLoading && from > 0">
  <div fxLayout="column" fxLayoutAlign="center center" class="py-20">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>
-->
