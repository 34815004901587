// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file responses/v1/page_response.proto (package responses.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Any } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_any } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file responses/v1/page_response.proto.
 */
export const file_responses_v1_page_response: GenFile = /*@__PURE__*/
  fileDesc("CiByZXNwb25zZXMvdjEvcGFnZV9yZXNwb25zZS5wcm90bxIMcmVzcG9uc2VzLnYxImkKDFBhZ2VSZXNwb25zZRIlCgdjb250ZW50GAEgAygLMhQuZ29vZ2xlLnByb3RvYnVmLkFueRIaChJudW1iZXJfb2ZfZWxlbWVudHMYAiABKAQSFgoOdG90YWxfZWxlbWVudHMYAyABKARChgEKEGNvbS5yZXNwb25zZXMudjFCEVBhZ2VSZXNwb25zZVByb3RvUAFaDi4vcGIvcmVzcG9uc2VzogIDUlhYqgIMUmVzcG9uc2VzLlYxygIMUmVzcG9uc2VzXFYx4gIYUmVzcG9uc2VzXFYxXEdQQk1ldGFkYXRh6gINUmVzcG9uc2VzOjpWMWIGcHJvdG8z", [file_google_protobuf_any]);

/**
 * @generated from message responses.v1.PageResponse
 */
export type PageResponse = Message<"responses.v1.PageResponse"> & {
  /**
   * @generated from field: repeated google.protobuf.Any content = 1;
   */
  content: Any[];

  /**
   * @generated from field: uint64 number_of_elements = 2;
   */
  numberOfElements: bigint;

  /**
   * @generated from field: uint64 total_elements = 3;
   */
  totalElements: bigint;
};

/**
 * Describes the message responses.v1.PageResponse.
 * Use `create(PageResponseSchema)` to create a new message.
 */
export const PageResponseSchema: GenMessage<PageResponse> = /*@__PURE__*/
  messageDesc(file_responses_v1_page_response, 0);

