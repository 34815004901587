import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductCardAllStatus } from '../product-card.config';
import { SearchProductVO } from '../../../vo/search-product-vo';

@Component({
  selector: 'app-product-card-action',
  templateUrl: './product-card-action.component.html',
  styleUrls: ['./product-card-action.component.scss'],
})
export class ProductCardActionComponent implements OnInit {
  @Input() product: SearchProductVO;
  @Input() status: ProductCardAllStatus;
  @Input() selected = false;
  @Output() selectedChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}
