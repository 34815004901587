import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { SearchProductVO } from '../../vo/search-product-vo';
import { debounce } from 'lodash';
import { ProductCardAllStatus, productCardStatusConfigs } from './product-card.config';
import { fuseAnimations } from '../../../@fuse/animations';
import { UserService } from '../../service/user/user.service';
import { CatalogApproveStatus } from '../../service/catalog-sidebar/catalog-sidebar.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppState } from 'app/app.state';
import { Store } from '@ngrx/store';
import { getCurrentUserSelector } from 'app/store/user/user.selector';
import { User } from '../../service/user/user';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { MetaTagsService } from 'app/service/meta-tags/meta-tags.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  animations: [fuseAnimations],
})
export class ProductCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ecomCurrency = 'USD';
  @Input() catalogStatus: CatalogApproveStatus;
  @Input() product: SearchProductVO;
  @Input() selectable = false;
  @Input() selected = false;
  @Output() selectedChange = new EventEmitter<boolean>();
  @Input() hiddenActionBar = false;
  @Input() inCatalog = false;
  @Input() inStore = false;
  @Input() generateMicrodata = false;

  hovered = false;
  debouncedHovered = false;
  status: ProductCardAllStatus;
  isAdmin: boolean;
  user: User;
  isLoggedIn = false;
  ltMd = false;

  metaTagPrice: number;
  metaTagDescription: string;

  readonly config = productCardStatusConfigs;

  private readonly debouncedMouseLeave: () => void;
  private readonly debouncedMouseEnter: () => void;
  private unsubscribeAll: Subject<void>;

  constructor(
    private userService: UserService,
    private store: Store<AppState>,
    // private catalogSidebarService: CatalogSidebarService,
    private screenManagerService: ScreenManagerService,
    public metaTagsService: MetaTagsService
  ) {
    this.debouncedMouseLeave = debounce(() => {
      this.debouncedHovered = false;
      this.hovered = false;
    }, 150);
    this.debouncedMouseEnter = debounce(() => {
      this.hovered = true;
      this.debouncedHovered = true;
    }, 150);
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.initMetaTags();
    this.getIsAuthenticated();
    this.initByAuthenticatedUser();
    // this.subscribeToNewProductAdded();
    // this.subscribeToRemovedFromCatalog();
    this.subscribeToScreenChange();
  }

  initMetaTags(): void {
    this.metaTagPrice = this.metaTagsService.calculateProductRRP(this.product);
    this.metaTagDescription = this.metaTagsService.truncateDescription(this.product.DESCRIPTION);
  }

  initByAuthenticatedUser(): void {
    this.store
      .select(getCurrentUserSelector)
      .pipe(filter((user) => !!user))
      .subscribe(() => {
        this.isAdmin = this.userService.isAdmin();
      });
  }

  private getIsAuthenticated(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((res) => (this.isLoggedIn = res));
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(takeUntil(this.unsubscribeAll), this.screenManagerService.stateMatchesOperator())
      .subscribe((matched) => {
        this.ltMd = matched;
      });
  }

  ngOnChanges(): void {
    this.status = this.getStatus();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleMouseEnter(): void {
    this.debouncedMouseEnter();
  }

  handleMouseLeave(): void {
    this.hovered = false;
    this.debouncedMouseLeave();
  }

  private getStatus(): ProductCardAllStatus {
    if (this.selectable) {
      return this.selected ? 'selected' : 'not-selected';
    } else if (this.hiddenActionBar) {
      return 'hidden';
    } else if (this.inCatalog) {
      return 'in-catalog';
    } else if (this.inStore) {
      return 'in-store';
    } else {
      return 'none';
    }
  }

  /*private subscribeToNewProductAdded(): void {
    this.catalogSidebarService.newProductAdded.pipe(takeUntil(this.unsubscribeAll)).subscribe((res) => {
      if (res.productId === this.product.ID) {
        this.product['CATALOG'] = [{ CATALOG_ID: Number(res.catalogId), CATALOG_NAME: res.catalogName }];
        this.inCatalog = true;
        this.status = this.getStatus();
      }
    });
  }

  private subscribeToRemovedFromCatalog(): void {
    this.catalogSidebarService.removedFromCatalog.pipe(takeUntil(this.unsubscribeAll)).subscribe((res) => {
      if (res.productId === this.product.ID) {
        this.inCatalog = false;
        this.status = this.getStatus();
      }
    });
  }*/
}
