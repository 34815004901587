<div id="container">
  <ng-container *ngIf="isAuthenticated$ | async; else guest">
    <ng-container *ngTemplateOutlet="loggedIn"></ng-container>
  </ng-container>
</div>

<router-outlet></router-outlet>

<ng-template #recentlyViewed>
  <app-recently-viewed-products
    [wrapperClass]="'content content-row-padding-lt-xl content-row-max-width'"
  ></app-recently-viewed-products>
</ng-template>

<ng-template #bestsellers>
  <div class="content content-row-padding-lt-xl content-row-max-width" *ngIf="featuredProductsLoaded; else skeleton">
    <ng-container *ngFor="let productlist of featuredProducts">
      <ng-container *ngIf="productlist.products && productlist.products.length !== 0">
        <!-- [isProductUsed]="isProductUsed" -->
        <app-products-list-slider-box
          [productlist]="productlist.products"
          [title]="productlist.title"
          [ecomCurrency]="ecomCurrency"
          [generateMicrodata]="true"
        ></app-products-list-slider-box>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #newSuppliers>
  <div
    class="content content-row-padding-lt-xl content-row-max-width"
    *ngIf="!!newSuppliersBlock; else supplierSkeleton"
  >
    <app-supplier-slider-box
      [newSuppliers]="newSuppliersBlock"
      [title]="'EXPLORE_PRODUCTS.NEW_SUPPLIERS' | translate"
    ></app-supplier-slider-box>
  </div>
</ng-template>

<ng-template #trendingSuppliers>
  <div
    class="content content-row-padding-lt-xl content-row-max-width"
    *ngIf="!!trendingSuppliersBlock; else supplierSkeleton"
  >
    <app-supplier-slider-box
      [newSuppliers]="trendingSuppliersBlock"
      [title]="'EXPLORE_PRODUCTS.HOT_PICKS' | translate"
    ></app-supplier-slider-box>
  </div>
</ng-template>

<ng-template #loggedIn>
  <section>
    <app-home-featured-filter-cards></app-home-featured-filter-cards>
  </section>
  <section>
    <ng-container *ngTemplateOutlet="recentlyViewed"></ng-container>
  </section>
  <section>
    <app-recommended-products-slider [ecomCurrency]="ecomCurrency"></app-recommended-products-slider>
  </section>
  <section>
    <app-recommended-suppliers-slider [selectedEcom]="selectedEcom"></app-recommended-suppliers-slider>
  </section>
  <section>
    <app-home-recent-searches></app-home-recent-searches>
  </section>
  <section>
    <app-recent-search-products-box [ecomCurrency]="ecomCurrency"> </app-recent-search-products-box>
  </section>
  <section>
    <app-preferred-categories-slider [ecomCurrency]="ecomCurrency"></app-preferred-categories-slider>
  </section>
  <section>
    <app-suppliers-slider-by-filter
      innerClass="content-row-padding-lt-xl content-row-max-width"
      [selectedEcom]="selectedEcom"
      [filter]="maxShippingFilter"
      [title]="'EXPLORE_PRODUCTS.BRANDS_NEAR_YOU' | translate"
      [shuffle]="true"
    ></app-suppliers-slider-by-filter>
  </section>
  <section>
    <app-recommended-suppliers-by-location-slider></app-recommended-suppliers-by-location-slider>
  </section>
  <section *appHasPermissions="[scopes.HOMEPAGE_FULL_NEW_SUPPLIER_ARRIVALS]">
    <ng-container *ngTemplateOutlet="newSuppliers"></ng-container>
  </section>
  <section>
    <app-product-filter-widget-rows></app-product-filter-widget-rows>
  </section>
</ng-template>

<ng-template #guest>
  <app-homepage-guest></app-homepage-guest>
  <!--  <section>-->
  <!--    <app-guest-brands-slider></app-guest-brands-slider>-->
  <!--  </section>-->
  <!--  <section>-->
  <!--    <app-guest-selling-point-cards></app-guest-selling-point-cards>-->
  <!--  </section>-->
  <!--  <section>-->
  <!--    <app-guest-explore-category-cards></app-guest-explore-category-cards>-->
  <!--  </section>-->
  <!--  <section>-->
  <!--    <app-guest-reviews-slider></app-guest-reviews-slider>-->
  <!--  </section>-->
  <!--  <section>-->
  <!--    <ng-container *ngTemplateOutlet="trendingSuppliers"></ng-container>-->
  <!--  </section>-->
  <!--  <section>-->
  <!--    <app-guest-stepflow></app-guest-stepflow>-->
  <!--  </section>-->
  <!--  <section>-->
  <!--    <app-guest-spotlight-cta></app-guest-spotlight-cta>-->
  <!--  </section>-->
  <!--  <section>-->
  <!--    <ng-container *ngTemplateOutlet="bestsellers"></ng-container>-->
  <!--  </section>-->
  <!--  <section>-->
  <!--    <app-guest-integrations-grid></app-guest-integrations-grid>-->
  <!--  </section>-->
  <!--  <section>-->
  <!--    <app-guest-faq></app-guest-faq>-->
  <!--  </section>-->
  <!--  <section>-->
  <!--    <app-guest-syncee-landing-cta></app-guest-syncee-landing-cta>-->
  <!--  </section>-->
</ng-template>

<ngx-json-ld *ngIf="!!organisationJsonLD" [json]="organisationJsonLD"></ngx-json-ld>
<ngx-json-ld *ngIf="!!searchboxJsonLD" [json]="searchboxJsonLD"></ngx-json-ld>

<ng-template #skeleton>
  <div class="content content-row-padding-lt-xl content-row-max-width">
    <app-skeleton-products-list-slider-box></app-skeleton-products-list-slider-box>
  </div>
</ng-template>

<ng-template #supplierSkeleton>
  <div class="content content-row-padding-lt-xl content-row-max-width">
    <app-skeleton-supplier-slider-box></app-skeleton-supplier-slider-box>
  </div>
</ng-template>
