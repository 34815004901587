import { Injectable } from '@angular/core';
import { SubscriptionType } from 'app/main/billing-new/subscription/model/subscription-type';
import { SubscriptionUtilityService } from 'app/main/billing-new/subscription/service/subscription-utility.service';
import { DATAFEED_STARTER_PLAN_ID, MARKETPLACE_STARTER_PLAN_ID } from 'app/utils/Constants';
import { getPlatformByType } from 'app/utils/platforms/platform-config';
import { EcomTypeEnum } from 'app/vo/enums/ecom-type-enum';
import { SubscriptionDF, SubscriptionRMP } from 'app/vo/subscription-vo';
import { EcomVO } from '../ecom/ecom.service';
import { SubscriptionChangeGtmAction } from './actions/subscription_change';
import { GtmManagerService } from './gtm-manager.service';
import {
  PlanTypeType,
  SubscriptionChangeTypeType,
  SubscriptionTrialPeriodType,
} from './model/variables/subscription.variables';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionChangeService {
  constructor(
    private gtmManagerService: GtmManagerService,
    private subscriptionUtilityService: SubscriptionUtilityService
  ) {}

  public async sendSubscriptionChangeGtmActions(ecom: EcomVO, forceCancellation: boolean = false): Promise<void> {
    await this.sendSubscriptionChangeGtmAction(ecom, 'marketplace', forceCancellation);

    if (getPlatformByType(ecom.ecomType as EcomTypeEnum)?.options?.hasDataFeed) {
      await this.sendSubscriptionChangeGtmAction(ecom, 'dataFeed', forceCancellation);
    }
  }

  private async sendSubscriptionChangeGtmAction(
    ecom: EcomVO,
    planType: SubscriptionType,
    forceCancellation: boolean = false
  ): Promise<void> {
    const subscription_trial_period: SubscriptionTrialPeriodType = this.getSubscriptionTrialPeriod(ecom);

    const currentSubscription = ecom.subscriptions[planType === 'dataFeed' ? 'df' : 'rmp'];

    const subscriptionChangeAction = new SubscriptionChangeGtmAction({
      subscription_cycle: forceCancellation || ecom.cycle === 'monthly' ? 'monthly' : 'annual',
      subscription_trial_period,
      subscription_change_type: this.getSubscriptionChangeType(currentSubscription, forceCancellation),
      value: forceCancellation ? 0 : currentSubscription.price,
      ecom_type: ecom.ecomType as EcomTypeEnum,
      plan_type: this.getPlanTypeType(planType),
    });

    return this.gtmManagerService.pushTag(subscriptionChangeAction);
  }

  private getSubscriptionTrialPeriod(subscription: EcomVO): SubscriptionTrialPeriodType {
    if (subscription.cycle !== 'monthly') {
      return 'no_free_trial';
    }

    if (
      !!this.subscriptionUtilityService.getValidTrialExpiry(subscription.subscriptions) &&
      this.subscriptionUtilityService.hasFreeTrialExpired(subscription.subscriptions)
    ) {
      return 'after_trial_period';
    }

    return 'during_trial_period';
  }

  private getPlanTypeType(planType: SubscriptionType): PlanTypeType {
    switch (planType) {
      case 'dataFeed':
        return 'datafeed';
      case 'marketplace':
        return 'marketplace';
      default:
        return null;
    }
  }

  private getSubscriptionChangeType(
    currentSubscription: SubscriptionDF | SubscriptionRMP,
    forceCancellation: boolean
  ): SubscriptionChangeTypeType {
    return forceCancellation ||
      [MARKETPLACE_STARTER_PLAN_ID, DATAFEED_STARTER_PLAN_ID].includes(currentSubscription.planId)
      ? 'cancellation'
      : 'subscription';
  }
}
