import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, finalize, takeUntil, tap } from 'rxjs/operators';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { AppState } from '../../../app.state';
import { favorites_page_navigation } from '../../../navigation/inner-navigation';
import { CatalogSidebarService } from '../../../service/catalog-sidebar/catalog-sidebar.service';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { FavoritesGatewayService } from '../../../service/favorites/favorites-gateway.service';
import { ProductSearchResponse } from '../../../service/product-search/product-search.service';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { selectedCurrencySelector } from '../../../store/currency/currency.selector';
import { selectedRetailerEcomSelector } from '../../../store/ecom/ecom.selector';
import { FavoritesActionTypes, RemoveFromFavoriteIdsSuccessAction } from '../../../store/favorites/favorites.actions';
import {
  FullMarketplaceFilterVO,
  MarketplaceFilter,
  MarketplaceFilterPagination,
  SearchProductVO,
} from '../../../vo/search-product-vo';
import { FavoritesPageService } from '../service/favorites-page.service';

@Component({
  selector: 'app-favorite-products',
  templateUrl: './favorite-products.component.html',
  styleUrls: ['./favorite-products.component.scss'],
})
export class FavoriteProductsComponent implements OnInit, OnDestroy {
  public products: SearchProductVO[];
  public numberOfProducts = 0;
  public selectedEcom: EcomVO;
  public ecomCurrency = 'USD';
  private readonly _unsubscribeAll: Subject<void>;
  hasError: boolean;
  areProductsLoading = true;
  currentPage = 0;
  prevFilter: MarketplaceFilter;
  noResultsForSearch = false;

  pagination: MarketplaceFilterPagination = {
    from: 0,
    size: 20,
  };

  constructor(
    private favoritesService: FavoritesGatewayService,
    // private marketplaceEcomService: MarketplaceEcomService,
    private store: Store<AppState>,
    private fuseConfigService: FuseConfigService,
    private screenManager: ScreenManagerService,
    private actions$: Actions,
    private favoritePageService: FavoritesPageService,
    // private exploreProductsService: ExploreProductsService,
    private catalogSidebarService: CatalogSidebarService
  ) {
    this.initTheme();
    this.favoritePageService.clearFilter();
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.catalogSidebarService.getRetailerToCatalogList();
    this.setPageSize();
    this.getCurrency();
    this.subscribeToProductSearchStateChange();
    this.subscribeToRemove();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getSelectedEcom(): Observable<EcomVO> {
    return this.store.select(selectedRetailerEcomSelector);
  }

  private initTheme(): void {
    this.fuseConfigService.config = {
      layout: {
        submenu: {
          hidden: false,
          group: {
            items: favorites_page_navigation,
            type: 'tab',
            title: 'FAVORITES.INNER_MENU_TITLE',
          },
        },
      },
    };
  }

  private getProducts(filter: Partial<FullMarketplaceFilterVO>, isNewSearch: boolean): void {
    if (isNewSearch) {
      this.pagination.from = 0;
    }
    this.areProductsLoading = true;
    this.favoritesService
      .searchFavorites(this.favoritePageService.userId, filter, this.pagination)
      .pipe(
        takeUntil(this._unsubscribeAll),
        finalize(() => (this.areProductsLoading = false))
      )
      .subscribe({
        next: (value) => {
          this.handleProductSearchResponse(value, isNewSearch);
          this.hasError = false;
        },
        error: () => {
          this.hasError = true;
        },
      });
  }

  private handleNewSearch(response: ProductSearchResponse, isNewSearch: boolean): void {
    switch (true) {
      case isNewSearch && isEmpty(response.result):
        this.noResultsForSearch = true;
        break;
      case isNewSearch && !isEmpty(response.result):
        this.noResultsForSearch = false;
        break;
      case !isNewSearch:
        this.noResultsForSearch = false;
        break;
      default:
        this.noResultsForSearch = false;
    }
  }

  private handleProductSearchResponse(response: ProductSearchResponse, isNewSearch: boolean): void {
    // this.getUsedProducts(response.result.map((product) => product.ID));
    if (isNewSearch || !this.products) {
      this.products = response.result;
    } else {
      this.products = this.products.concat(response.result);
    }
    this.numberOfProducts = response.total;
    this.hasError = false;
    this.handleNewSearch(response, isNewSearch);
  }

  /*private getUsedProducts(ids: string[]): void {
    if (!!this.selectedEcom && ids?.length > 0) {
      this.exploreProductsService.getIsProductUsed(this.selectedEcom.id, ids).subscribe((isProductUsedResp) => {
        this.exploreProductsService.modifyIsProductUsed(this.products, ids, isProductUsedResp);
      });
    }
  }*/

  private setPagination(newFrom: number, size?: number): void {
    this.currentPage = Math.floor(newFrom / this.pagination.size);
    this.pagination = { from: newFrom, size: size ?? 20 };
  }

  public handleLoadMore(): void {
    this.setPagination(this.pagination.from + this.pagination.size, this.pagination.size);
    this.getProducts(this.prevFilter, false);
  }

  private getCurrency(): void {
    this.store.select(selectedCurrencySelector).subscribe((currency) => {
      this.ecomCurrency = currency;
    });
  }

  private subscribeToProductSearchStateChange(): void {
    combineLatest([
      this.getSelectedEcom().pipe(tap((ecom) => this.handleEcomChanged(ecom))),
      this.favoritePageService.filterStore$,
    ])
      .pipe(
        distinctUntilChanged(),
        concatMap(([ecom, filter], index) => {
          return this.handleFilterStoreResponse(filter, index);
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((filter) => {
        this.prevFilter = filter;
      });
  }

  private handleFilterStoreResponse(
    filter: Partial<FullMarketplaceFilterVO>,
    emitIndex: number
  ): Observable<Partial<FullMarketplaceFilterVO>> {
    if (emitIndex === 0) {
      return of(filter).pipe(
        tap(() => {
          this.getProducts(filter, false);
        })
      );
    } else {
      return of(filter).pipe(
        tap(() => {
          this.getProducts(filter, true);
        })
      );
    }
  }

  private subscribeToRemove(): void {
    this.actions$
      .pipe(ofType(FavoritesActionTypes.REMOVE_FROM_FAVORITE_PRODUCT_IDS_SUCCESS), takeUntil(this._unsubscribeAll))
      .subscribe((action: RemoveFromFavoriteIdsSuccessAction) => {
        this.products = this.products.filter((product) => !action.payload.includes(product.ID));
        this.numberOfProducts -= action.payload.length;
        if (this.products.length === 0 && this.numberOfProducts > 0) {
          this.getProducts(this.prevFilter, false);
        }
      });
  }

  private setPageSize(): void {
    switch (true) {
      case this.screenManager.checkBreakpoint(BreakPoint.md):
        this.pagination.size = 20;
        break;
      case this.screenManager.checkBreakpoint(BreakPoint.lg):
        this.pagination.size = 18;
        break;
      case this.screenManager.checkBreakpoint(BreakPoint.xl):
        this.pagination.size = 20;
        break;
      default:
        this.pagination.size = 18;
    }
  }

  private handleEcomChanged(ecom: EcomVO): void {
    this.getCurrency();
    this.selectedEcom = ecom;
  }
}
