import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Store } from '@ngrx/store';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import { ProductCardModule } from 'app/main/product-card/product-card.module';
import { CatalogSidebarService } from 'app/service/catalog-sidebar/catalog-sidebar.service';
import { MarketplaceEcomService } from 'app/service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { MetaTagsService } from 'app/service/meta-tags/meta-tags.service';
import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { CreateProductPageFullUrlPipe } from 'app/shared/pipes/create-product-page-full-url.pipe';
import { CreateSupplierFullUrlPipe } from 'app/shared/pipes/create-supplier-url.pipe';
import { SupplierLogoFromUseridPipe } from 'app/shared/pipes/supplier-logo-from-userid.pipe';
import { selectedCurrencySelector } from 'app/store/currency/currency.selector';
import { AddFilterWithRedirectAction } from 'app/store/product-search/product-search.action';
import { MarketplaceFilter, SearchProductVO } from 'app/vo/search-product-vo';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SectionTitleSliderComponent } from '../../../static/components/section-title-slider/section-title-slider.component';
import { SliderArrowComponent } from '../slider-arrow/slider-arrow.component';
import { IsProductInImportListByProductIdPipe } from '../../pipes/is-product-in-import-list-by-product-id.pipe';
import { CatalogStatusCheckPipe } from 'app/shared/pipes/catalog-status-check.pipe';

@Component({
  selector: 'app-products-list-slider-box',
  templateUrl: './products-list-slider-box.component.html',
  styleUrls: ['./products-list-slider-box.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    TranslateModule,
    SlickCarouselModule,
    MatIconModule,
    ProductCardModule,
    NgxJsonLdModule,
    MatButtonModule,
    SliderArrowComponent,
    SectionTitleSliderComponent,
    IsProductInImportListByProductIdPipe,
    CatalogStatusCheckPipe,
  ],
  animations: [fuseAnimations],
  providers: [CreateProductPageFullUrlPipe, CreateSupplierFullUrlPipe, SupplierLogoFromUseridPipe],
})
export class ProductsListSliderBoxComponent implements OnInit, OnDestroy, OnChanges {
  @Input() productlist: SearchProductVO[];
  @Input() title: string;
  @Input() description: string;
  @Input() ecomCurrency = 'USD';
  // @Input() isProductUsed: IsProductUsedResponse;
  @Input() filter: MarketplaceFilter;
  @Input() generateMicrodata: boolean;
  alreadyInCatalog: string[] = [];
  alreadyInStore = [];
  jsonLD: SafeHtml;
  swiped = false;

  config: JQuerySlickOptions = {
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: BreakPoint.xl,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: BreakPoint.lg,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: BreakPoint.md,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: BreakPoint.sm,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    swipe: false,
    draggable: false,
  };
  loading = false;
  ltMd: boolean;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private catalogSidebarService: CatalogSidebarService,
    public marketplaceEcomService: MarketplaceEcomService,
    private router: Router,
    private screenManagerService: ScreenManagerService,
    private createSupplierFullUrlPipe: CreateSupplierFullUrlPipe,
    private createProductPageFullUrlPipe: CreateProductPageFullUrlPipe,
    private metaTagsService: MetaTagsService,
    private supplierLogoFromUserIdPipe: SupplierLogoFromUseridPipe,
    private _store: Store<AppState>
  ) {
    this._unsubscribeAll = new Subject();
    // this.subscribeToNewProductAdded();
    // this.subscribeToRemovedFromCatalog();
    this._getSelectedCurrency();
  }

  ngOnInit(): void {
    this.subscribeToScreenChange();
  }

  ngOnChanges(_: SimpleChanges): void {
    /*if (this.isProductUsed) {
      this.getUsedProductIds();
    }*/
    if (!!this.productlist) {
      this.addJsonLD();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _getSelectedCurrency(): void {
    this._store.select(selectedCurrencySelector).subscribe((currency) => {
      this.ecomCurrency = currency;
    });
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator(), takeUntil(this._unsubscribeAll))
      .subscribe((matches) => (this.ltMd = matches));
  }

  /*private getUsedProductIds(): void {
    this.productlist.forEach((product) => {
      const inCatalog = this.isProductUsed?.find((cat) => cat?.productIds?.includes(product?.ID));
      product['CATALOG'] = !!inCatalog
        ? [{ CATALOG_ID: inCatalog.catalogId, CATALOG_NAME: inCatalog.catalogName }]
        : [];
    });
  }*/

  /*public inCatalog(productID: string): boolean {
    return this.alreadyInCatalog && this.alreadyInCatalog.indexOf(productID) !== -1;
  }

  public inStore(productID: string): boolean {
    return this.alreadyInStore && this.alreadyInStore.indexOf(productID) !== -1;
  }

  public catalogStatusCheck(catalogID: number): any {
    return this.catalogSidebarService.catalogStatusCheck(catalogID);
  }*/

  handleSeeAllClicked(): void {
    if (!!this.filter) {
      this._store.dispatch(
        new AddFilterWithRedirectAction({
          needRedirect: true,
          skipLocationChange: true,
          filter: this.filter,
        })
      );
    }
  }

  /*private subscribeToNewProductAdded(): void {
    this.catalogSidebarService.newProductAdded.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.alreadyInCatalog.push(res.productId);
      this.productlist.map((product) => {
        if (product.ID === res.productId) {
          if (!product.hasOwnProperty('CATALOG')) {
            product.CATALOG = [];
          }
          product.CATALOG.push({
            CATALOG_ID: Number(res.catalogId),
            CATALOG_NAME: res.catalogName,
          });
        }
      });
    });
  }

  private subscribeToRemovedFromCatalog(): void {
    this.catalogSidebarService.removedFromCatalog.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.productlist.map((product) => {
        if (product.ID === res.productId) {
          const i = product.CATALOG.findIndex((elem) => elem.CATALOG_ID.toString() === res.catalogId);
          product.CATALOG.splice(i, 1);
          if (product.CATALOG.length === 0) {
            const index = this.alreadyInCatalog.indexOf(res.productId);
            if (index > -1) {
              this.alreadyInCatalog.splice(index, 1);
            }
          }
        }
      });
    });
  }*/

  trackByFn(_: number, item: SearchProductVO): string {
    return item.ID;
  }

  private addJsonLD(): void {
    this.jsonLD = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: [
        this.productlist.map((product, index) => {
          return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
              '@type': 'Product',
              name: product.TITLE,
              image: product.IMAGES[0],
              url: this.createProductPageFullUrlPipe.transform(product.ID, product.TITLE),
              description: this.metaTagsService.truncateDescription(product.DESCRIPTION),
              brand: {
                '@type': 'Brand',
                name: product.BRAND,
                url: this.createSupplierFullUrlPipe.transform(product.USER_ID, product.SUPPLIER?.handle),
                logo: this.supplierLogoFromUserIdPipe.transform(product.USER_ID),
              },
              offers: {
                '@type': 'Offer',
                sku: product.ID,
                url: this.createProductPageFullUrlPipe.transform(product.ID, product.TITLE),
                priceCurrency: 'USD',
                price: this.metaTagsService.calculateProductRRP(product),
              },
            },
          };
        }),
      ],
    };
  }
}
