<div class="supplier-card-container cursor-pointer disable" (click)="navigateToStorefront()">
  <div class="supplier-card-image-container">
    <ng-container *ngIf="!productImageUrls; else fallbackImage">
      <img
        lazyLoadImg
        class="supplier-card-image"
        [src]="supplier.userId | supplierBrandImage"
        [alt]="supplier.companyName + 'product image'"
        (error)="handleImageError()"
        oncontextmenu="return false"
      />
    </ng-container>
    <app-follow-supplier-button
      (click)="$event.stopPropagation()"
      [supplierUserId]="+supplier.userId"
    ></app-follow-supplier-button>
  </div>
  <div class="supplier-card-info-container w-100p">
    <div class="supplier-card-info">
      <h5 class="company-name no-wrap">
        {{ supplier.companyName }}
      </h5>
      <div class="supplier-main-categories typography-body-1 no-wrap">
        {{ supplier.mainCategoryIds | categoryNamesByIds | async }}
      </div>
      <div class="supplier-main-warehouse">
        <span class="typography-body-2">{{
          'COUNTRIES.' + (supplier.mainWarehouseCountry | countryNameToCodePipe) | translate
        }}</span>
        <img
          lazyLoadImg
          class="country-flag"
          src="{{ supplier.mainWarehouseCountry | countryNameToCodePipe | countryFlag }}"
          alt="{{ supplier.mainWarehouseCountry }}"
        />
      </div>
    </div>
    <app-supplier-icon
      class="supplier-logo"
      [size]="'70px'"
      [supplierUserId]="supplier.userId"
      [supplierCompanyName]="supplier.companyName"
    ></app-supplier-icon>
  </div>
</div>

<ng-template #fallbackImage>
  <img
    oncontextmenu="return false"
    defaultImage="assets/images/transparent-400x400.png"
    [lazyLoad]="!!productImageUrls?.[0] ? (productImageUrls[0] | productImageThumbnailCdn) : noImageUrl"
    [errorImage]="productImageUrls?.[0] || noImageUrl"
    [alt]="supplier?.companyName + ' logo'"
    productImageErrorHandler
    [checkedOriginal]="true"
  />
</ng-template>
