// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file responses/v1/product_search_response.proto (package responses.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_any } from "@bufbuild/protobuf/wkt";
import type { Product } from "../../product/v1/product_pb";
import { file_product_v1_product } from "../../product/v1/product_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file responses/v1/product_search_response.proto.
 */
export const file_responses_v1_product_search_response: GenFile = /*@__PURE__*/
  fileDesc("CipyZXNwb25zZXMvdjEvcHJvZHVjdF9zZWFyY2hfcmVzcG9uc2UucHJvdG8SDHJlc3BvbnNlcy52MSJZChVQcm9kdWN0U2VhcmNoUmVzcG9uc2USDAoEdG9vaxgBIAEoBBINCgV0b3RhbBgCIAEoBBIjCgZyZXN1bHQYAyADKAsyEy5wcm9kdWN0LnYxLlByb2R1Y3RCjwEKEGNvbS5yZXNwb25zZXMudjFCGlByb2R1Y3RTZWFyY2hSZXNwb25zZVByb3RvUAFaDi4vcGIvcmVzcG9uc2VzogIDUlhYqgIMUmVzcG9uc2VzLlYxygIMUmVzcG9uc2VzXFYx4gIYUmVzcG9uc2VzXFYxXEdQQk1ldGFkYXRh6gINUmVzcG9uc2VzOjpWMWIGcHJvdG8z", [file_google_protobuf_any, file_product_v1_product]);

/**
 * @generated from message responses.v1.ProductSearchResponse
 */
export type ProductSearchResponse = Message<"responses.v1.ProductSearchResponse"> & {
  /**
   * @generated from field: uint64 took = 1;
   */
  took: bigint;

  /**
   * @generated from field: uint64 total = 2;
   */
  total: bigint;

  /**
   * @generated from field: repeated product.v1.Product result = 3;
   */
  result: Product[];
};

/**
 * Describes the message responses.v1.ProductSearchResponse.
 * Use `create(ProductSearchResponseSchema)` to create a new message.
 */
export const ProductSearchResponseSchema: GenMessage<ProductSearchResponse> = /*@__PURE__*/
  messageDesc(file_responses_v1_product_search_response, 0);

