import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { firstImportListByProductIdSelector } from 'app/store/rcatalogs/rcatalogs.selector';
import { Catalog } from 'app/vo/catalog';
import { Observable } from 'rxjs';

@Pipe({
  name: 'firstImportListByProductId',
  standalone: true,
})
export class FirstImportListByProductIdPipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  public transform(synceeProductId: string): Observable<Catalog> {
    return this.store.select(firstImportListByProductIdSelector(synceeProductId));
  }
}
