import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { finalize, Observable } from 'rxjs';
import { ShippingProfileDto } from '../../../vo/shipping-zones/shipping-profile-dto';
import { MarketplaceBadgeDirective } from '../../directives/marketplace-badge.directive';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { ShippingDetailsNewFullService } from './service/shipping-details-new-full.service';
import { ShippingDetailsZonesComponent } from './shipping-details-zones/shipping-details-zones.component';

@UntilDestroy()
@Component({
  selector: 'app-shipping-details-new-full',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTabsModule,
    ShippingDetailsZonesComponent,
    MarketplaceBadgeDirective,
    LoadingSpinnerComponent,
  ],
  providers: [ShippingDetailsNewFullService],
  templateUrl: './shipping-details-new-full.component.html',
  styleUrls: ['./shipping-details-new-full.component.scss'],
})
export class ShippingDetailsNewFullComponent implements OnInit {
  @Input() userId: number;
  @Input() supplierCurrency: string;
  @Input() taskId: number;
  @Input() isAutoOrder: Observable<boolean>;
  @Input() taxIncludedToShipping: boolean;

  profiles: ShippingProfileDto[];
  appliedShippingZone: ShippingProfileDto;
  selectedIndex: number;
  allRatesNull: boolean;
  isLoading: boolean;

  constructor(private shippingDetailsNewFullService: ShippingDetailsNewFullService) {}

  ngOnInit(): void {
    this.shippingDetailsNewFullService.userId = this.userId;
    this.shippingDetailsNewFullService.taskId = this.taskId;
    this.shippingDetailsNewFullService.fetchProfiles$.next();
    this.getProfiles();
  }

  private getProfiles(): void {
    this.isLoading = true;

    this.shippingDetailsNewFullService.shippingProfiles$
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (profiles) => {
          if (profiles) {
            this.mapProfiles(profiles);
          }
        },
      });
  }

  private mapProfiles(profiles: ShippingProfileDto[]): void {
    let minCost: number;

    if (this.taskId) {
      this.appliedShippingZone = profiles.find((elem) => elem.tasks.find((task) => task.id == this.taskId));
      if (this.appliedShippingZone) {
        this.selectedIndex = profiles.findIndex((elem) => this.appliedShippingZone.id == elem.id);
      }
    }

    profiles.map((profile) => {
      if (profile.shippingZones && profile.shippingZones.length > 0) {
        profile.shippingZones.map((zone) => {
          if (zone.shippingRates && zone.shippingRates.length > 0) {
            const prices = zone.shippingRates.map((rate) => rate.cost).filter((cost) => cost);
            if (prices && prices.length > 0) {
              minCost = Math.min(...prices);
            }
          }
        });
      }
    });

    this.allRatesNull = !minCost;
    this.profiles = profiles;
    this.isLoading = false;
  }
}
